import React, { useState } from 'react'
import Link from 'next/link'
import SwiperCore, { Pagination } from 'swiper'
import useTrans from '@hooks/useTrans'
import { Logo, Close, Profile } from '@components/atoms/Icons'
import { Button } from '@components/atoms'
import { HomepageBoxCard } from '@components/molecules'
import styles from './NavMobile.module.sass'
import { useSelector } from 'react-redux'
import useUtente from '@hooks/useUtente'

SwiperCore.use([Pagination])

interface Props {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
}

const loggedMenuAccountItems = [
  {
    title: 'I tuoi dati',
    href: '/dashboard/informazioni-personali',
  },
  {
    title: 'Le tue preferenze',
    href: '/dashboard/preferenze',
  },
]

const bocCards = [
  {
    type: 'scrivici',
    href: '/contatti',
  },
  {
    type: 'chiamaci',
    href: '/chiamaci',
  },
  {
    type: 'iscrivitiAllaNewsletter',
    href: '/dashboard/preferenze',
  },
  {
    type: 'concorsiEPromozioni',
    href: 'https://www.concorsi.vividanone.it',
  },
]

const NavMobile = (props: Props) => {
  const { className = '', onClick = () => {} } = props

  const t = useTrans()
  const { utente } = useUtente()

  const cardBoxParameters = {
    minimal: true,
    className: styles.box,
    classNameIcon: styles.icon,
    classNameTitle: styles.title,
  }

  return (
    <nav className={`${styles.navMobile} navMobile ${className}`}>
      <div className={styles.header}>
        <div className={styles.leftContent}>
          <Link legacyBehavior href="/">
            <a className={styles.logoWrap}>
              <div className={styles.logo}>
                <Logo className={styles.logoDesktop} />
              </div>
            </a>
          </Link>
        </div>

        <div className={styles.rightContent}>
          <button type="button" className={styles.navMobileButton} onClick={onClick}>
            <Close />
          </button>
        </div>
      </div>

      <div className={styles.menuAccount}>
        {utente ? (
          <>
            <p className={styles.menuAccountTitle}>
              {t('Ciao')} {utente.nome}
            </p>
            {loggedMenuAccountItems?.map((item) => (
              <Link legacyBehavior key={item.title} href={item.href}>
                <a className={styles.item}>{item.title}</a>
              </Link>
            ))}
          </>
        ) : (
          <Button
            className={styles.loginButton}
            href="/login"
            variant="ghost"
            size="sm"
            icon={<Profile />}
            iconPos="right"
            label={t('Accedi o registrati')}
          />
        )}
      </div>

      <div className={styles.menuItems}>
        {bocCards.map((item) => (
          <Link legacyBehavior href={item.href} key={item.type}>
            <a className={styles.cardItem}>
              <HomepageBoxCard type={item.type} {...cardBoxParameters} />
            </a>
          </Link>
        ))}
      </div>
    </nav>
  )
}

export default NavMobile
