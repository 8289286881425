import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useUpdateUtenteOptinsMutation } from 'gql/graphql'
import useTrans from 'hooks/useTrans'
import { Button, FormLayout, FormToggle } from 'components/atoms'
import { FormCard } from 'components/molecules'
import { Image } from "react-bootstrap";
import styles from './FormOptin.module.sass'


const FormOptin = ({ initialValuesFromProps, edit, setEdit, setShowConfirmMessage }: any) => {
  const t = useTrans()

  let initialValues = {}
  initialValues[initialValuesFromProps?.optinName] = initialValuesFromProps?.value

  const [updateOptins, { loading }] = useUpdateUtenteOptinsMutation()
  return (
    <Formik
        isInitialValid={false}
        onSubmit={(values) => {
          updateOptins({
          variables: {
              optins: {
              ...values
            }
          },
        })
            .then((res) => {
            setShowConfirmMessage(true)
        })
            .catch((error) => console.log(error))
        }}
            initialValues={{
            ...initialValues
        }}
    >
        {(props) => (
            <FormCard
              editMode={edit}
              editButton={edit ? null : {onClick: () => setEdit(true)}}
            >
              <FormCard.Content>
                <FormLayout>
                  <div className={styles.optinBrandCard}>
                    <div className={styles.optinNameImageContainer}>
                      <Image
                        alt={initialValuesFromProps.name}
                        src={initialValuesFromProps.image}
                        id={initialValuesFromProps.name}
                      />
                      <strong className={styles.name}>{initialValuesFromProps.name}</strong>
                    </div>
                    <FormToggle id={initialValuesFromProps.optinName} checked={props.values?.[initialValuesFromProps.optinName]}
                        onChange={(e) => {
                        props.setFieldValue(e.target.id, e.target.checked)
                      }}
                    />
                  </div>
                </FormLayout>
              </FormCard.Content>
              {edit && (
              <FormCard.Buttons className={styles.buttons}>
                  <Button
                      className={styles.button}
                      loading={loading}
                      type="submit"
                      disabled={!props.isValid}
                      onClick={() => props.handleSubmit()}
                      label={t('Aggiorna le preferenze')}
                      variant="primary"
                  />
              </FormCard.Buttons>
              )}
            </FormCard>
        )}
    </Formik>
  )
}

export default FormOptin
