import React, { useState } from 'react'
import { useRouter } from 'next/router'
import useTrans from 'hooks/useTrans'
import { Button, FormField, FormSelect, HeaderBox, WContainer } from 'components/atoms'
import { StepProps } from '../FormContattaci.interfaces'
import styles from './AltreInformazioni.module.sass'
import { useStoresQuery } from '@gql/graphql'
import { ArrowRight } from '@components/atoms/Icons'
import { B2X_GIACENZA, B2X_MONITORAGGIO } from 'utils/settings'

const AltreInformazioni = ({ registered, setSteps, setRequestType }: StepProps) => {
  const t = useTrans()
  const router = useRouter()

  const [b2xReason, setB2xReason] = useState(null)
  const [b2xSite, setB2xSite] = useState(null)

  const { data } = useStoresQuery({ fetchPolicy: 'cache-first' })
  const stores = data?.stores.map((item) => ({
    value: b2xReason === B2X_MONITORAGGIO ? item.b2xMonitoringLink : item.b2xStockLink,
    label: item.name,
  }))

  const nextStep = () => setSteps((prev: number) => prev + 1)

  const case3 = () => {
    nextStep()
  }
  const items = [
    {
      title: t(B2X_MONITORAGGIO),
      onClick: (reason: string) => {
        setB2xReason(reason)
        setB2xSite(null)
      },
    },
    {
      title: t(B2X_GIACENZA),
      onClick: (reason: string) => {
        setB2xReason(reason)
        setB2xSite(null)
      },
    },
    { title: t('Ho un problema con il mio ordine'), onClick: case3 },
  ]

  return (
    <WContainer variant="md">
      <div className={styles.container}>
        <HeaderBox
          title={t('Come possiamo aiutarti per il tuo acquisto online?')}
          classNameHeader={styles.header}
          classNameTitle={styles.title}
        />
        <div className={styles.menu}>
          {items &&
            items.map((item) => (
              <Button
                label={item.title}
                onClick={() => item.onClick(item.title)}
                className={`${styles.item} ${
                  item.title === b2xReason ? styles['item--active'] : ''
                }`}
                key={item.title}
              />
            ))}
        </div>
        {b2xReason && (
          <div className={styles.b2xReason}>
            <FormField label={t('Scegli il sito*')} className={styles.b2xSite}>
              <FormSelect
                options={stores}
                placeholder={t('Scegli il sito')}
                value={b2xSite}
                onChange={(v) => setB2xSite(v.value)}
              />
            </FormField>
            {b2xSite && (
              <Button
                icon={<ArrowRight />}
                iconPos="right"
                label={t('Procedi')}
                href={b2xSite}
                newPage
                external
                className={styles.b2xCta}
              />
            )}
          </div>
        )}
      </div>
    </WContainer>
  )
}

export default AltreInformazioni
