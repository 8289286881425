import React from 'react'
import { ArrowRight } from 'components/atoms/Icons' 
import { Button } from 'components/atoms' 
import { Props as ButtonProps } from 'components/atoms/Button/Button' 
import styles from './DashboardCard.module.sass'

interface Props {
  icon?: any
  title?: string
  description?: any
  button?: ButtonProps
  className?: string
}

const DashboardCard = (props: Props) => {

  const { 
    icon = null,
    title = '',
    description = null,
    button = null,
    className = '',
  } = props

  return (
    <div className={`${styles.dashboardCard} ${className ? ` ${className}` : ''}`}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}
        {description && <div className={styles.description}>{description}</div>}
        {button && <Button {...{
          variant: 'ghost',
          size: 'md',
          icon: <ArrowRight />,
          iconPos: 'right',
          ...button,
          className: `${styles.button} ${button.className ? ` ${button.className}` : ''}`
        }} />}
      </div>
    </div>
  )
}

export default DashboardCard
