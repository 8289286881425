import React, { useState } from 'react'
import useTrans from 'hooks/useTrans'
import { isBorn, getAge, getWillBeBorn, formatDate } from 'utils/safe'
import {
  CiuccioF,
  CiuccioM,
  Domanda,
  Hourglass,
  ArrowRight,
  Bin,
  Edit,
} from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import { DeleteModal } from 'components/molecules'
import styles from './FiglioCard.module.sass'

interface Props {
  onDeleteFiglio: (id: number) => void
  dati: {
    firstName: string
    genderType: 'F' | 'M' | 'X'
    dataNascita: string
    pk?: number
  }
  className?: string
  isFiglioProfile?: boolean
  onEdit?: () => void
}

const FiglioCard = (props: Props) => {
  const {
    dati = null,
    className = '',
    isFiglioProfile = false,
    onEdit = () => {},
    onDeleteFiglio,
  } = props

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const t = useTrans()
  const icon =
    dati?.genderType === 'F' ? <CiuccioF /> : dati?.genderType === 'M' ? <CiuccioM /> : <Domanda />
  const localIsBorn = isBorn(dati?.dataNascita)
  const dateDiff = localIsBorn ? getAge(dati?.dataNascita) : getWillBeBorn(dati?.dataNascita)

  return (
    <div
      className={`${styles.figlioCard} ${styles[`figlioCard--type-${dati?.genderType}`]} ${
        localIsBorn ? styles['figlioCard--born'] : ''
      } ${className}`}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.content}>
        <div className={styles.main}>
          <p className={styles.title}>{dati?.firstName}</p>
          <div className={styles.data}>
            <span className={styles.birthdate}>
              {localIsBorn ? (
                <span className={styles.birthdateContent}>
                  {t(dati?.genderType === 'F' ? 'Nata il {0}' : 'Nato il {0}', [
                    formatDate(dati?.dataNascita),
                  ])}
                </span>
              ) : (
                <>
                  <span className={styles.birthdateIcon}>
                    <Hourglass />
                  </span>
                  <span className={styles.birthdateContent}>
                    {t('Nascerà il {0}', [formatDate(dati?.dataNascita)])}
                  </span>
                </>
              )}
            </span>
            {dati?.genderType !== 'x' && (
              <span className={styles.gender}>
                {localIsBorn
                  ? t(dati?.genderType === 'f' ? 'È una femminuccia' : 'È un maschietto')
                  : t(dati?.genderType === 'f' ? 'Sarà una femminuccia' : 'Sarà un maschietto')}
              </span>
            )}
            {localIsBorn && (
              <span className={styles.age}>
                {t(localIsBorn ? 'Ha già' : 'Nascerà tra')}&nbsp;
                <strong>{t(`{0} ${dateDiff[1]}`, [String(dateDiff[0])])}</strong>
              </span>
            )}
          </div>

          <div className={styles.data}>
            <span className={styles.age}>
              {dati?.isTwin
                ? t(localIsBorn ? 'Ha dei gemelli' : 'Avrà dei gemelli')
                : t(localIsBorn ? 'Non ha dei gemelli' : 'Non avrà dei gemelli')}
            </span>
          </div>
        </div>
        <div className={styles.ctaBox}>
          {isFiglioProfile ? (
            <>
              <Button
                label={t('Modifica i dati')}
                variant="ghost"
                size="sm"
                icon={<Edit />}
                iconPos="right"
                onClick={onEdit}
              />
              <Button
                label={t('Rimuovi profilo')}
                variant="ghost"
                size="sm"
                icon={<Bin />}
                iconPos="right"
                onClick={() => setShowDeleteModal(true)}
              />
            </>
          ) : (
            <Button
              label={t('Visualizza il diario')}
              variant="ghost"
              size="sm"
              icon={<ArrowRight />}
              iconPos="right"
              href={`/dashboard/figlio/${dati?.pk}`}
            />
          )}
        </div>
      </div>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onConfirm={() => onDeleteFiglio(dati?.pk)}
        title={`Vuoi rimuovere il profilo di ${dati?.firstName}`}
        description={`Cliccando su “elimina dati” cancellerai il profilo di ${dati?.firstName} definitivamente.`}
      />
    </div>
  )
}

export default FiglioCard
