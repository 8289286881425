import { Button } from '@components/atoms'
import DomandeIllustration from '@components/atoms/Icons/DomandeIllustration'
import React from 'react'
import styles from './LinkBox.module.sass'

interface Props {
  item: {
    titolo?: string | React.ReactNode // nel PageBuilder è un TextEditor
    descrizione?: string | React.ReactNode // nel PageBuilder è un TextEditor
    cta?: string
    link?: string
    blank?: boolean
  }
  variant?: 'icon' | 'light'
  editMode?: boolean
  className?: string
}

const LinkBox = (props: Props) => {
  const { item, variant = "icon", editMode = false, className = '' } = props

  const button = <Button label={item.cta} href={item.link} size="sm" variant="secondary" className={styles.cta} external={item.blank} newPage={item.blank} />

  return (
    <div className={`${styles.linkBox} ${styles[`linkBox--${variant}`]} ${className}`}>
      {variant === 'icon' && <span className={styles.icon}><DomandeIllustration /></span>}
      <div className={styles.content}>
        {!!item.titolo && (
          editMode || (typeof item.titolo !== 'string')
          ? <div className={styles.title}>{item.titolo}</div>
          : <p className={styles.title}>{item.titolo}</p>
        )}
        {!!item.descrizione && (
          editMode || (typeof item.descrizione !== 'string')
          ? <div className={styles.description}>{item.descrizione}</div>
          : <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.descrizione}} />
        )}
        {variant === 'icon' && button}
      </div>
      {variant === 'light' && button}
    </div>
  )
}

export default LinkBox
