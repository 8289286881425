import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import React from 'react'
import { Button, SettimanaGravidanza } from 'components/atoms'
import { ArrowLeft, ArrowRight } from 'components/atoms/Icons'
import styles from './Timeline.module.sass'
//import { PrevNextNumTrimestre } from 'utils'

interface Props {
  settimane: { numero: string; url: string }[]
  settimanaCorrente?: string
  next: any
  prev: any
  className?: string
}

const Timeline = (props: Props) => {
  const { settimane = [], settimanaCorrente, next = {}, prev = {}, className = '' } = props

  const NumeroTrimestre = (settimane: any) => {
    let NumTrimestre = ''
    settimane.map(function(settimana) {
     
      if (settimana['numero'] == '7')
      {
        NumTrimestre = '1'
      }
      if (settimana['numero'] == '13')
      {
        NumTrimestre = '2'
      }
      if (settimana['numero'] == '27')
      {
        NumTrimestre =  '3'
      }
    })
    return NumTrimestre 
  }

 
  const NumTrimestre = NumeroTrimestre(settimane)
  const t = useTrans()

  return (
    <div className={`${styles.timeline} ${className}`}>
      <p className={styles.title}>{t('Seleziona la settimana di gravidanza')}</p>
      <div className={styles.slider}>
        <Swiper
          spaceBetween={10}
          watchOverflow
          centerInsufficientSlides
          slidesPerView="auto"
        >
          {settimane.map((week) => (
            <SwiperSlide key={week.numero} className={styles.slide}>
              <SettimanaGravidanza
                numero={week.numero}
                url={week.url}
                selected={week.numero === settimanaCorrente && Boolean(settimanaCorrente)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.nav}>
        {prev && (
          <Button
            label={NumTrimestre === '1' ?t('Vai al terzo trimestre'): NumTrimestre === '2'? t('Vai al primo trimestre'): t('Vai al secondo trimestre') }
            href={prev.url}
            variant="ghost"
            iconPos="left"
            icon={<ArrowLeft />}
            size="sm"
            className={styles.button}
          />
        )}
        {next && (
          <Button
            label={NumTrimestre === '1' ?t('Vai al secondo trimestre'): NumTrimestre === '2'? t('Vai al terzo trimestre'): t('Vai al primo trimestre') }
            href={next.url}
            variant="ghost"
            iconPos="right"
            icon={<ArrowRight />}
            size="sm"
            className={styles.button}
          />
        )}
      </div>
    </div>
  )
}

export default Timeline
