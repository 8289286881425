import React, { useEffect } from 'react'
import { MetaSeo } from 'components/atoms'
import { Header, PreFooter, Footer, Toolbar } from 'components/organisms'
import { OrganizationJsonLd } from 'next-seo'
import { DOMAIN, BRAND, LOGO_URL } from 'utils/settings'
import styles from './Page.module.sass'

interface Props {
  pagina: any
  item?: any
  children: React.ReactNode
  className?: string
  contentClassName?: string
  iframe?: boolean
}

const Page = (props: Props) => {
  const { pagina = {}, item = {}, children = null, className = '', contentClassName = '', iframe= false } = props

  const handleScroll = () => {
    if (window.scrollY > 0) document.body.classList.add('w-scrolled')
    else document.body.classList.remove('w-scrolled')
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className={styles.pageWrap}>
      <Toolbar page={pagina} item={item} />
      {iframe ?(  <main className={`${styles.content} ${contentClassName}`}>{children}</main>):(
      <div className={`${styles.page} page ${className}`}>
        {pagina?.seo && <MetaSeo seo={pagina.seo} />}
        <Header />
        <main className={`${styles.content} ${contentClassName}`}>{children}</main>
        <Footer />
      </div>
      )}
      <OrganizationJsonLd 
        name={BRAND}
        url={DOMAIN}
        logo={LOGO_URL}
        contactPoints={[
          {
            telephone: "800 73 73 40",
            contactType: "customer service",
            areaServed: "IT"
          }
        ]}
      />
    </div>
  )
}

export default Page
