import React from 'react'
import useTrans from '@hooks/useTrans'
import { Button, Link, WContainer } from 'components/atoms'
import styles from './AskRegistrationCard.module.sass'

interface Props {
  className?: string
  showButton?: boolean
  onClick?: any
}

const AskRegistrationCard = (props: Props) => {
  const { className = '', showButton = true, onClick = () => {} } = props

  const t = useTrans()

  return (
    <WContainer variant="md-no-mobile">
      <div className={`${styles.container} ${className}`}>
        <div className={styles.header}>
          <div className={styles.title}>{t('Non hai un account Danone?')}</div>
          <div className={styles.description}>
            {t('Scrivici la tua domanda, il servizio clienti è a tua disposizione')}
          </div>
        </div>
        {showButton && (
          <Button
            className={styles.register}
            label={t('Registrati')}
            href="/registrazione?next=/contatti"
            variant="primary"
          />
        )}
          <Button
            className={styles.register}
            label={t('Prosegui senza account')}
            variant="secondary"
            onClick={onClick}
          />
      </div>
    </WContainer>
  )
}

export default AskRegistrationCard
