import React from 'react'
import { ProdottoType } from 'utils/types'
import useTrans from '@hooks/useTrans'
import { WImage, Button } from '@components/atoms'
import styles from './ProdottoBox.module.sass'


interface Props {
  prodotto: ProdottoType
  className?: string
}

const ProdottoBox = (props: Props) => {

  const { 
    prodotto = undefined,
    className = '',
  } = props

  const t = useTrans()

  return prodotto ? (
    <div className={`${styles.prodottoBox} ${className}`}>
      <div className={styles.imageBox}>
        <WImage 
          src={prodotto.mainImage}
          maxWidth={200}
          maxHeight={200}
          layout="intrinsic"
        />
      </div>
      <div className={styles.content}>
        {!!prodotto.titolo && <p className={styles.title}>{prodotto.titolo}</p>}
        {!!prodotto.descrizione && <div className={styles.description} dangerouslySetInnerHTML={{ __html: prodotto.descrizione}} />}
        {!!prodotto.link && <Button 
          label={prodotto.cta ? prodotto.cta : t("Acquista ora")} 
          href={prodotto.link}  
          color="white"
          className={styles.cta}
          newPage={prodotto.blank}
          external={prodotto.blank}
        />}
      </div>
    </div>
  ) : <></>
}

export default ProdottoBox
