import ActivityCard from 'components/molecules/ActivityCard/ActivityCard'
import ArticoloCard from 'components/molecules/ArticoloCard/ArticoloCard'
import AskRegistrationCard from 'components/molecules/AskRegistrationCard/AskRegistrationCard'
import BrandCard from 'components/molecules/BrandCard/BrandCard'
import ConsigliCard from 'components/molecules/ConsigliCard/ConsigliCard'
import DashboardCard from 'components/molecules/DashboardCard/DashboardCard'
import DatiFormCard from 'components/molecules/DatiFormCard/DatiFormCard'
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal'
import EmptyMessageBox from 'components/molecules/EmptyMessageBox/EmptyMessageBox'
import EventoCard from 'components/molecules/EventoCard/EventoCard'
import FeedbackCard from 'components/molecules/FeedbackCard/FeedbackCard'
import FiglioCard from 'components/molecules/FiglioCard/FiglioCard'
import FormCard from 'components/molecules/FormCard/FormCard'
import GenericCard from 'components/molecules/GenericCard/GenericCard'
import HomepageBoxCard from 'components/molecules/HomepageBoxCard/HomepageBoxCard'
import HubCard from 'components/molecules/HubCard/HubCard'
import LinkBox from 'components/molecules/LinkBox/LinkBox'
import MyAccountAccountView from 'components/molecules/MyAccountAccountView/MyAccountAccountView'
import MyAccountDatiView from 'components/molecules/MyAccountDatiView/MyAccountDatiView'
import MyAccountMenu from 'components/molecules/MyAccountMenu/MyAccountMenu'
import MyAccountSection from 'components/molecules/MyAccountSection/MyAccountSection'
import MyAccountViewItem from 'components/molecules/MyAccountViewItem/MyAccountViewItem'
import OptinCard from 'components/molecules/OptinCard/OptinCard'
import PlaceholderReact from 'components/molecules/PlaceholderReact/PlaceholderReact'
import ProdottoBox from 'components/molecules/ProdottoBox/ProdottoBox'
import ProdottoCard from 'components/molecules/ProdottoCard/ProdottoCard'
import ProdottoCorrelatoCard from 'components/molecules/ProdottoCorrelatoCard/ProdottoCorrelatoCard'
import QuoteBox from 'components/molecules/QuoteBox/QuoteBox'
import SearchResultItem from 'components/molecules/SearchResultItem/SearchResultItem'

export {
  ActivityCard,
  ArticoloCard,
  AskRegistrationCard,
  BrandCard,
  ConsigliCard,
  DashboardCard,
  DatiFormCard,
  DeleteModal,
  EmptyMessageBox,
  EventoCard,
  FeedbackCard,
  FiglioCard,
  FormCard,
  GenericCard,
  HomepageBoxCard,
  HubCard,
  LinkBox,
  MyAccountAccountView,
  MyAccountDatiView,
  MyAccountMenu,
  MyAccountSection,
  MyAccountViewItem,
  OptinCard,
  PlaceholderReact,
  ProdottoBox,
  ProdottoCard,
  ProdottoCorrelatoCard,
  QuoteBox,
  SearchResultItem,
}
