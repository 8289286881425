import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import styles from './BoxList.module.sass'

SwiperCore.use([Pagination])

interface Props {
  className?: string
  children?: React.ReactNode
}

const BoxList = (props: Props) => {

  const { 
    className = '',
    children = null,
  } = props

  return (
    <div className={`${styles.boxList} ${className}`}>
      <div className={`${styles.slider} ${React.Children.count(children) > 2 ? styles['slider--paginationDesktop'] : ''}`}>
        <Swiper 
          slidesPerView={1.2} 
          spaceBetween={30} 
          watchOverflow
          pagination={{ clickable: true }}
          breakpoints={{
            768: {
              slidesPerView: 2
            }
          }}
        >
          {React.Children.map(children, (child, index) => (
            <SwiperSlide key={index}>
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default BoxList
