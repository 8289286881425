import React from 'react'
import { useTrans } from 'hooks'
import styles from './ProdottoCard.module.sass'
import { WImage, Button } from 'components/atoms'
import { ProdottoType } from 'utils/types'
import ProductImage from 'images/Product.png' //Da eliminare: usato in fase di sviluppo
import Link from 'next/link'

interface Props {
  prodotto: ProdottoType
  size?: 'default' | 'sm'
  noAnimation?: boolean
  className?: string
  onClick?: () => void
}

const ProdottoCard = (props: Props) => {
  const {
    prodotto = undefined,
    className = '',
    size = 'default',
    noAnimation = false,
    onClick = undefined,
  } = props

  const t = useTrans()
  const classNames = `
    ${styles.prodottoCard}
    ${size ? styles[`prodottoCard--${size}`] : ''}
    ${className ? className : ''}`

  const content = (
    <>
      <div className={`${styles.imageBox} ${noAnimation && styles['imageBox--no-animation']}`}>
        <WImage
          src={prodotto.mainImage}
          maxWidth={size === 'sm' ? 164 : 176}
          maxHeight={size === 'sm' ? 164 : 176}
          layout="intrinsic"
        />
      </div>

      <div className={styles.content}>
        {!!prodotto.titolo && (
          <p className={styles.title} dangerouslySetInnerHTML={{ __html: prodotto.titolo }} />
        )}
      </div>
      <div className={styles.shape} />
    </>
  )

  return !!prodotto ? (
    prodotto.link ? (
      <Link legacyBehavior href={prodotto.link}>
        <a className={classNames} onClick={onClick}>
          {content}
        </a>
      </Link>
    ) : (
      <div className={classNames}>{content}</div>
    )
  ) : (
    <></>
  )
}

export default ProdottoCard
