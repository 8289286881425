import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getIcon } from 'utils/icons'
import { WContainer } from '@components/atoms'
import styles from './VerticalSliderBox.module.sass'


interface Props {
  className?: string
}

const VerticalSliderBox = (props: Props) => {

  const { 
    className = '',
  } = props

  // FAKE 
  const item = {
    nome: 'Mellin, come azienda del gruppo Danone, si schiera sempre dalla parte dei genitori',
    descrizione: '<p>Non solo perchè <b>da più di 100 anni supporta la corretta nutrizione dei bambini</b>, '+
    'ma anche attraverso un <b>percorso etico, iniziato oltre 11 anni fa, di scelte aziendali volte a supportare la genitorialità.</b>'+
    'Siamo convinti che non si debba aspettare il momento giusto per poter diventare genitori.</p>'+
    '<p>Come ci impegniamo:</p>',
    children: [
      {
        pk: 1,
        style: 'close',
        descrizione: '<p>Investiamo sulle nostre risorse che sono madri dopo la maternità, con un risultato di un <b>20% di promozioni negli ultimi 3 anni</b></p>',
      },
      {
        pk: 2,
        style: 'close',
        descrizione: '<p>Prevediamo un Welfare di 2.300€ netti annui per i genitori</p>',
      },
      {
        pk: 3,
        style: 'close',
        descrizione: '<p>La nostra <b>Parental policy</b> prevede che le madri ricevano il 100% di retribuzione in maternità obbligatoria e 60% in maternità facoltativa.</p>',
      },
      {
        pk: 4,
        style: 'close',
        descrizione: '<p>Attiviamo <b>politiche interne per evitare l\'abbandono lavorativo delle neomamme</b>: smartworking, flessibilità oraria, innalzato a 10 giorni di congedo consentito per malattia dei figli fino ai 13 anni di età.</p>',
      },
      {
        pk: 5,
        style: 'close',
        descrizione: '<p>Investiamo sulle nostre risorse che sono madri dopo la maternità, con un risultato di un <b>20% di promozioni negli ultimi 3 anni</b></p>',
      },
    ]
  }

  return (
    <div className={`${styles.verticalSliderBox} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.content}>
            {!!item.nome && <p className={styles.title}>{item.nome}</p>}
            {!!item.descrizione && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.descrizione }} />}
          </div>
          <div className={styles.slider}>
            <Swiper direction="vertical" slidesPerView={4} centeredSlides loop spaceBetween={10} autoplay={{ delay: 500 }}>
              {item.children?.map(x => (
                <SwiperSlide key={x.pk}>
                  <div className={styles.itemContainer}>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        {getIcon(x.style)}
                      </div>
                      <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: x.descrizione }} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </WContainer>
    </div>
  )
}

export default VerticalSliderBox
