import React, { useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { useTrans } from 'hooks'
import { formatDate } from 'utils/safe'
import { Expand, Reduce, Question } from 'components/atoms/Icons'
import { Button, Tooltip } from 'components/atoms'
import styles from './Graph.module.sass'

const calcolaLegendPos = (curva, yValue) => {
  return { top: `${((curva.yMax - yValue) * 100) / (curva.yMax - curva.yMin)}%` }
}

// Ha bisogno di un parent component o un div con un heigth definita
const Graph = ({ curva, data, xName, yName }: any) => {
  const t = useTrans()

  const [mobileExpanded, setMobileExpanded] = useState(false)
  const yLastValues = curva.data.map(
    (line) =>
      line.data.find((point) => point.x === curva.xTickValues[curva.xTickValues.length - 1]).y
  )

  return (
    <div className={`${styles.graph} ${mobileExpanded ? ' graph--mobile-expanded' : ''}`}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.legend}>
            <span className={styles.legendValue} style={calcolaLegendPos(curva, yLastValues[4])}>
              97%
            </span>
            <span className={styles.legendValue} style={calcolaLegendPos(curva, yLastValues[3])}>
              85%
            </span>
            <span className={styles.legendValue} style={calcolaLegendPos(curva, yLastValues[2])}>
              50%
            </span>
            <span className={styles.legendValue} style={calcolaLegendPos(curva, yLastValues[1])}>
              15%
            </span>
            <span className={styles.legendValue} style={calcolaLegendPos(curva, yLastValues[0])}>
              3%
            </span>
            <Tooltip
              id={`graph-help-${curva.id}`}
              pos="left"
              label={t(
                'Percentili: I percentuali sono diagrammi che servono a stabilire se il ritmo di crescita del bambino è conforme alla sua età ed escludere eventuali problemi legati allo sviluppo. Ovviamente sono solo degli indicatori: ogni bimbo segue il suo personale sviluppo, che solo il pediatra potrà valutare.'
              )}
            >
              <span className={styles.help} style={calcolaLegendPos(curva, yLastValues[0])}>
                <Question />
              </span>
            </Tooltip>
          </div>
          <div className={styles.background}>
            <ResponsiveLine
              curve="natural"
              theme={{
                background: '#FFFFFF',
                textColor: '#FFFFFF',
                fontSize: 14,
                axis: {
                  ticks: {
                    line: {
                      stroke: '#FFFFFF',
                      strokeWidth: 1,
                    },
                  },
                  domain: {
                    line: {
                      stroke: '#D3DFF0',
                      strokeWidth: 1,
                    },
                  },
                },
              }}
              axisBottom={{
                tickValues: curva.xTickValues,
              }}
              axisLeft={{
                tickValues: curva.yTickValues,
              }}
              colors="#D3DFF0"
              enableGridX={false}
              enableGridY={false}
              data={curva.data}
              margin={{ top: 10, right: 10, bottom: 50, left: 60 }}
              xScale={{ type: 'linear', min: curva.xMin, max: curva.xMax }}
              yScale={{ type: 'linear', min: curva.yMin, max: curva.yMax }}
              pointSize={0}
              pointLabelYOffset={-12}
              tooltip={(x) => (
                <div className={styles.tooltip}>
                  <span className={styles.tooltipDate}>{x.point.data.xValue}</span>
                  <span className={styles.tooltipValue}>{x.point.data.yValue}</span>
                </div>
              )}
            />
          </div>
          <ResponsiveLine
            curve="natural"
            theme={{
              background: 'transparent',
              textColor: '#3F77C1',
              fontSize: 14,
              axis: {
                ticks: {
                  line: {
                    stroke: '#D3DFF0',
                    strokeWidth: 1,
                  },
                },
                domain: {
                  line: {
                    stroke: '#D3DFF0',
                    strokeWidth: 1,
                  },
                },
              },
            }}
            colors="#164194"
            enableGridX={false}
            enableGridY={false}
            data={data}
            margin={{ top: 10, right: 10, bottom: 50, left: 60 }}
            xScale={{ type: 'linear', min: curva.xMin, max: curva.xMax }}
            yScale={{
              type: 'linear',
              min: curva.yMin,
              max: curva.yMax,
              stacked: true,
              reverse: false,
            }}
            yFormat=">-.2f"
            xFormat=">"
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: curva.xTickValues,
              legend: xName ? xName : t('Asse x'),
              legendPosition: 'middle',
              legendOffset: 40,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: curva.yTickValues,
              legend: yName ? yName : t('Asse y'),
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            pointSize={16}
            pointColor="#FFFFFF"
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            enableCrosshair={false}
            tooltip={(x) => (
              <div className={styles.tooltip}>
                <span className={styles.tooltipDate}>{x.point.data.xValue}</span>
                <span className={styles.tooltipValue}>{x.point.data.yValue}</span>
              </div>
            )}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          label={t(mobileExpanded ? 'Riduci tabella' : 'Espandi tabella')}
          variant="ghost"
          icon={mobileExpanded ? <Reduce /> : <Expand />}
          iconPos="left"
          onClick={() => setMobileExpanded(!mobileExpanded)}
        />
      </div>
    </div>
  )
}

export default Graph
