import React, { useState, useRef } from 'react'
import { useTrans } from 'hooks'
import { BreadcrumbType } from 'utils/types'
import { Plus, Minus } from 'components/atoms/Icons'
import { Button, Breadcrumbs, WContainer } from 'components/atoms'
import styles from './RoundHead.module.sass'

interface Props {
  breadcrumbs?: BreadcrumbType[]
  icon?: React.ReactNode
  title?: string
  description?: string
  readMore?: boolean
  size?: 'md' | 'lg'
  color?: 'default' | 'white'
  className?: string
}

const RoundHead = (props: Props) => {
  const {
    breadcrumbs = [],
    icon = undefined,
    title = '',
    description = '',
    readMore = true,
    size = 'md',
    color = 'default',
    className = '',
  } = props

  const t = useTrans()

  const [descriptionOpen, setDescriptionOpen] = useState(false)
  const innerDescriptionRef = useRef<HTMLDivElement>(null)

  return (
    <div className={`${styles.roundHead} ${
      styles[`roundHead--${size}`]} ${
      styles[`roundHead--${color}`]} ${
      readMore ? styles['roundHead--readMore'] : ''
      } ${className}`}>
      <div className={styles.content}>
        {breadcrumbs?.length > 0 && (
          <WContainer>
            <div className={styles.breadcrumbs}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          </WContainer>
        )}
        <WContainer variant="sm">
          <>
            {!!icon && <div className={styles.icon}>{icon}</div>}
            {!!title && <h1 className={styles.title}>{title}</h1>}
            {!!description && (
              <>
                <div
                  className={`${styles.descriptionWrap} ${
                    descriptionOpen ? styles['descriptionWrap--open'] : ''
                  }`}
                  style={
                    innerDescriptionRef?.current && descriptionOpen
                      ? {
                          maxHeight: innerDescriptionRef.current.clientHeight,
                        }
                      : {}
                  }
                >
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                    ref={innerDescriptionRef}
                  />
                </div>
                {readMore && (
                  <Button
                    label={descriptionOpen ? t('Chiudi') : t('Leggi tutto')}
                    variant="ghost"
                    icon={descriptionOpen ? <Minus /> : <Plus />}
                    iconPos="right"
                    onClick={() => setDescriptionOpen(!descriptionOpen)}
                    className={styles.readMore}
                  />
                )}
              </>
            )}
          </>
        </WContainer>
      </div>
      <div className={styles.shape} />
    </div>
  )
}

export default RoundHead
