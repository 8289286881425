import ArticoliCorrelati from 'components/organisms/ArticoliCorrelati/ArticoliCorrelati'
import BoxHeroListingCard from 'components/organisms/BoxHeroListingCard/BoxHeroListingCard'
import BoxList from 'components/organisms/BoxList/BoxList'
import CategorieProdottoCorrelate from 'components/organisms/CategorieProdottoCorrelate/CategorieProdottoCorrelate'
import DisclaimerModal from 'components/organisms/DisclaimerModal/DisclaimerModal'
import FaqListing from 'components/organisms/FaqListing/FaqListing'
import Footer from 'components/organisms/Footer/Footer'
import FormAccountPrivacy from 'components/organisms/FormAccountPrivacy/FormAccountPrivacy'
import FormBambino from 'components/organisms/FormBambino/FormBambino'
import FormCalcolaDataNascita from 'components/organisms/FormCalcolaDataNascita/FormCalcolaDataNascita'
import FormContattaci from 'components/organisms/FormContattaci/FormContattaci'
import FormEditDatiPersonali from 'components/organisms/FormEditDatiPersonali/FormEditDatiPersonali'
import FormLogin from 'components/organisms/FormLogin/FormLogin'
import FormOptin from 'components/organisms/FormOptin/FormOptin'
import FormOptins from 'components/organisms/FormOptins/FormOptins'
import FormPrivacy from 'components/organisms/FormPrivacy/FormPrivacy'
import FormRecuperaPassword from 'components/organisms/FormRecuperaPassword/FormRecuperaPassword'
import FormRegistrazione from 'components/organisms/FormRegistrazione/FormRegistrazione'
import FormResendActivation from 'components/organisms/FormResendActivation/FormResendActivation'
import FormResetPassword from 'components/organisms/FormResetPassword/FormResetPassword'
import FormUnsubs from 'components/organisms/FormUnsubs/FormUnsubs'
import GenericModal from 'components/organisms/GenericModal/GenericModal'
import Graph from 'components/organisms/Graph/Graph'
import Header from 'components/organisms/Header/Header'
import HeroSlider from 'components/organisms/HeroSlider/HeroSlider'
import HeroWithBox from 'components/organisms/HeroWithBox/HeroWithBox'
import ImageHead from 'components/organisms/ImageHead/ImageHead'
import ImpegnoSection1 from 'components/organisms/ImpegnoSection1/ImpegnoSection1'
import ImpegnoSection2 from 'components/organisms/ImpegnoSection2/ImpegnoSection2'
import ImpegnoSection3 from 'components/organisms/ImpegnoSection3/ImpegnoSection3'
import ImpegnoSection4 from 'components/organisms/ImpegnoSection4/ImpegnoSection4'
import ImpegnoSection5 from 'components/organisms/ImpegnoSection5/ImpegnoSection5'
import ImpegnoSection6 from 'components/organisms/ImpegnoSection6/ImpegnoSection6'
import LandingProdottoHead from 'components/organisms/LandingProdottoHead/LandingProdottoHead'
import LandingProdottoInfo from 'components/organisms/LandingProdottoInfo/LandingProdottoInfo'
import LandingProdottoIngredienti from 'components/organisms/LandingProdottoIngredienti/LandingProdottoIngredienti'
import LandingProdottoProdotti from 'components/organisms/LandingProdottoProdotti/LandingProdottoProdotti'
import ListingProdottiHead from 'components/organisms/ListingProdottiHead/ListingProdottiHead'
import ListingProdottiSection1 from 'components/organisms/ListingProdottiSection1/ListingProdottiSection1'
import ListingProdottiSection2 from 'components/organisms/ListingProdottiSection2/ListingProdottiSection2'
import ModalAggiornaFiglio from 'components/organisms/ModalAggiornaFiglio/ModalAggiornaFiglio'
import ModalAggiungiFiglio from 'components/organisms/ModalAggiungiFiglio/ModalAggiungiFiglio'
import ModalAggiungiParametro from 'components/organisms/ModalAggiungiParametro/ModalAggiungiParametro'
import ModalFeedback from 'components/organisms/ModalFeedback/ModalFeedback'
import MyAccountAccountFormCard from 'components/organisms/MyAccountAccountFormCard/MyAccountAccountFormCard'
import MyAccountDeleteCard from 'components/organisms/MyAccountDeleteCard/MyAccountDeleteCard'
import MyAccountFiglioFormCard from 'components/organisms/MyAccountFiglioFormCard/MyAccountFiglioFormCard'
import MyAccountLayout from 'components/organisms/MyAccountLayout/MyAccountLayout'
import MyAccountPrivacyFormCard from 'components/organisms/MyAccountPrivacyFormCard/MyAccountPrivacyFormCard'
import MyOptinFormCard from 'components/organisms/MyOptinFormCard/MyOptinFormCard'
import MyOptinsFormCard from 'components/organisms/MyOptinsFormCard/MyOptinsFormCard'
import MyUnsubscribeFormCard from 'components/organisms/MyUnsubscribeFormCard/MyUnsubscribeFormCard'
import Nav from 'components/organisms/Nav/Nav'
import NavMobile from 'components/organisms/NavMobile/NavMobile'
import Page from 'components/organisms/Page/Page'
import PreFooter from 'components/organisms/PreFooter/PreFooter'
import ProdottiCorrelati from 'components/organisms/ProdottiCorrelati/ProdottiCorrelati'
import RoundHead from 'components/organisms/RoundHead/RoundHead'
import SearchBar from 'components/organisms/SearchBar/SearchBar'
import SearchResultsDropdown from 'components/organisms/SearchResultsDropdown/SearchResultsDropdown'
import SettimanaHead from 'components/organisms/SettimanaHead/SettimanaHead'
import SliderMiglioriProdotti from 'components/organisms/SliderMiglioriProdotti/SliderMiglioriProdotti'
import StrumentiLinkUtili from 'components/organisms/StrumentiLinkUtili/StrumentiLinkUtili'
import ThankYouPage from 'components/organisms/ThankYouPage/ThankYouPage'
import Timeline from 'components/organisms/Timeline/Timeline'
import Toolbar from 'components/organisms/Toolbar/Toolbar'
import TopMenu from 'components/organisms/TopMenu/TopMenu'
import VerticalSliderBox from 'components/organisms/VerticalSliderBox/VerticalSliderBox'
import YoutubeModal from 'components/organisms/YoutubeModal/YoutubeModal'

export {
  ArticoliCorrelati,
  BoxHeroListingCard,
  BoxList,
  CategorieProdottoCorrelate,
  DisclaimerModal,
  FaqListing,
  Footer,
  FormAccountPrivacy,
  FormBambino,
  FormCalcolaDataNascita,
  FormContattaci,
  FormEditDatiPersonali,
  FormLogin,
  FormOptin,
  FormOptins,
  FormPrivacy,
  FormRecuperaPassword,
  FormRegistrazione,
  FormResendActivation,
  FormResetPassword,
  FormUnsubs,
  GenericModal,
  Graph,
  Header,
  HeroSlider,
  HeroWithBox,
  ImageHead,
  ImpegnoSection1,
  ImpegnoSection2,
  ImpegnoSection3,
  ImpegnoSection4,
  ImpegnoSection5,
  ImpegnoSection6,
  LandingProdottoHead,
  LandingProdottoInfo,
  LandingProdottoIngredienti,
  LandingProdottoProdotti,
  ListingProdottiHead,
  ListingProdottiSection1,
  ListingProdottiSection2,
  ModalAggiornaFiglio,
  ModalAggiungiFiglio,
  ModalAggiungiParametro,
  ModalFeedback,
  MyAccountAccountFormCard,
  MyAccountDeleteCard,
  MyAccountFiglioFormCard,
  MyAccountLayout,
  MyAccountPrivacyFormCard,
  MyOptinFormCard,
  MyOptinsFormCard,
  MyUnsubscribeFormCard,
  Nav,
  NavMobile,
  Page,
  PreFooter,
  ProdottiCorrelati,
  RoundHead,
  SearchBar,
  SearchResultsDropdown,
  SettimanaHead,
  SliderMiglioriProdotti,
  StrumentiLinkUtili,
  ThankYouPage,
  Timeline,
  Toolbar,
  TopMenu,
  VerticalSliderBox,
  YoutubeModal,
}
