import { Button, WContainer, WImage } from '@components/atoms'
import useTrans from '@hooks/useTrans'
import React from 'react'
import { MenuItemType } from 'utils/types'
import styles from './ImpegnoSection5.module.sass'

interface Props {
  item: MenuItemType
  className?: string
}

const ImpegnoSection5 = (props: Props) => {
  const { item = undefined, className = '' } = props

  const t = useTrans()

  return item ? (
    <div className={`${styles.impegnoSection5} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.content}>
            <div className={styles.icon}>
              <WImage
                src={'/images/partoRipartoLogo.png'}
                maxWidth={107}
                maxHeight={70}
                layout="intrinsic"
              />
            </div>
            {!!item.name && (
              <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />
            )}
            {!!item.description && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
            {!!item.ctaLabel && !!item.url && (
              <div className={styles.ctaBox}>
                <Button href={item.url} label={item.ctaLabel} className={styles.cta} />
              </div>
            )}
            <div
              className={styles.bottom}
              dangerouslySetInnerHTML={{
                __html: t(
                  '<p>Inoltre, mettiamo a disposizione di tutti i genitori contenuti informativi realizzati da esperti come <a href="#">gli articoli del Pediatra Salari</a> e i contenuti creati in <a href="#">collaborazione con LadyMum</a>.</p>'
                ),
              }}
            />
          </div>
          <div className={styles.imageBox}>
            <WImage
              src={item.image}
              maxWidth={767}
              maxHeight={558}
              layout="intrinsic"
              objectFit="cover"
            />
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default ImpegnoSection5
