import React from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { getIcon } from 'utils/icons'
import { useStoresQuery } from '@gql/graphql'
import { useSelector } from 'react-redux'
import { BrandButton, Separator, WImage } from 'components/atoms'
import styles from './Footer.module.sass'

interface Props {
  className?: string
}

const Footer = (props: Props) => {
  const { className = '' } = props
  const { data } = useStoresQuery({ fetchPolicy: 'cache-first' })
  const stores = data?.stores

  const t = useTrans()

  const { menu } = useSelector((state) => state.menu)

  const footerMenu = menu?.find((item) => item.key === 'footer')
  const socialMenu = menu?.find((item) => item.key === 'social')
  const corporateMenu = menu?.find((item) => item.key === 'corporate')

  const getSocialName = (socialUrl: string) =>
    socialUrl.includes('facebook')
      ? 'facebook'
      : socialUrl.includes('instagram')
      ? 'instagram'
      : 'youtube'

  const groupSize = Math.ceil(corporateMenu?.children?.length / 4)

  const groups = []
  for (let i = 0; i < corporateMenu?.children?.length; i += groupSize) {
    const group = corporateMenu?.children?.slice(i, i + groupSize)
    groups.push(group)
  }

  return (
    <footer className={`${styles.footer} ${className}`}>
      <div className={styles.top}>
        <div className={styles.topMenu}>
          <span className={styles.menuLabel}>{t('Scopri i nostri store')}</span>
          <div className={styles.menuItems}>
            {stores?.map((item) => (
              <div className={styles.storeMenuItem} key={item.pk}>
                <BrandButton className={styles.storeItem} item={item} />
              </div>
            ))}
          </div>
        </div>
        <Separator className={styles.menuSeparator} />
        <div className={`${styles.topMenu} ${styles.socialMenu}`}>
          <div>
            <span className={styles.menuLabel}>{t('Seguici sui social')}</span>
            <div className={styles.menuItems}>
              {socialMenu?.children?.map((item) => (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener norefferer"
                  className={styles.socialItem}
                  key={item.pk}
                >
                  {getIcon(item.style)}
                </a>
              ))}
            </div>
          </div>
          <WImage
            src="/images/b_corp.png"
            maxWidth={55}
            maxHeight={80}
            className={`${styles.corporateItem} ${styles.bCorpMobile}`}
          />
        </div>
        <Separator className={styles.menuSeparator} />
      </div>
      <div className={styles.corporateMenuFooter}>
        {groups.map((group, key) => (
          <div className={styles.corporateMenuGroup} key={key}>
            {group.map((item) =>
              item.style === 'cookiebot' ? (
                <a
                  className={styles.corporateItem}
                  href="javascript: Cookiebot.show()"
                  key={item.pk}
                >
                  {item.name}
                </a>
              ) : (
                <Link legacyBehavior href={item.url} key={item.pk}>
                  <a className={styles.corporateItem} target={item.blank ? '_blank' : '_self'}>
                    {item.name}
                  </a>
                </Link>
              )
            )}
          </div>
        ))}
        <WImage
          src="/images/DANONE_b_corporation.png"
          maxWidth={75}
          maxHeight={131}
          layout="intrinsic"
          className={`${styles.corporateItem} ${styles.bCorpDesktop}`}
        />
      </div>
      <div className={styles.containerMenuFooter}>
        <div className={styles.info}>
          <span>
            {t('Copyright © 2022 Danone S.p.A.')} - {t('Partita IVA 11202380157')}
          </span>
        </div>
        <div className={styles.menu}>
          {footerMenu?.children?.map((item) =>
            item.style === 'cookiebot' ? (
              <a className={styles.menuItem} href="javascript: Cookiebot.show()" key={item.pk}>
                {item.name}
              </a>
            ) : item.key === 'servizio-consumatori' ? (
              <a
                className={styles.menuItem}
                href={`mailto:${t('servizioconsumatori.mellin@danone.com')}`}
                key={item.pk}
              >
                {item.name}
              </a>
            ) : item.url ? (
              <Link legacyBehavior href={item.url} key={item.pk}>
                <a className={styles.menuItem}>{item.name}</a>
              </Link>
            ) : (
              ''
            )
          )}
        </div>
        <Separator className={styles.menuSeparator} />
      </div>
    </footer>
  )
}

export default Footer
