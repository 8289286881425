import React, { useState } from 'react'
import { useRouter } from 'next/router'
import useTrans from 'hooks/useTrans'
import { Formik } from 'formik'
import {
  IPurpose,
  IinitialVaues,
} from '@components/organisms/FormContattaci/FormContattaci.interfaces'
import {
  Case1,
  Case2,
  Case3,
  DatiPersonali,
} from '@components/organisms/FormContattaci/steps/forms/index'
import {
  Button,
  FormCheckbox,
  FormField,
  FormSelect,
  HeaderBox,
  OptinLabel,
  WContainer,
} from 'components/atoms'
import { StepProps, RequestType, validationSchema } from '../FormContattaci.interfaces'
import styles from './DatiRichiesta.module.sass'
import { useContattoSalesforceMutation } from '@gql/graphql'
import { useSelector } from 'react-redux'
import ModalFeedback from '@components/organisms/ModalFeedback/ModalFeedback'

const RenderForms = ({ requestType, registered, formik, submittedForm, purposeArea }) => {
  if (submittedForm) {
    return <DatiPersonali registered={registered} formik={formik} />
  }

  const elements = []
  if (requestType === RequestType.case1) {
    elements.push(
      <Case1 registered={registered} formik={formik} key="case1" purposeArea={purposeArea} />
    )
  }
  if (requestType === RequestType.case3) {
    elements.push(
      <Case3 registered={registered} formik={formik} key="case3" purposeArea={purposeArea} />
    )
  }
  // common (case2)
  elements.push(
    <Case2
      registered={registered}
      formik={formik}
      key="case2"
      requestType={requestType}
      purposeArea={purposeArea}
    />
  )
  return elements
}

const DatiRichiesta = ({ registered, requestType, purposes, setSteps, purposeArea }: StepProps) => {
  const t = useTrans()
  const router = useRouter()

  const { utente } = useSelector((state) => state.utente)

  const nextStep = () => setSteps((prev: number) => prev + 1)

  const [modalErrorCreateAccount, setModalErrorCreateAccount] = useState(false)
  const [error, setError] = useState('')

  const [createRichiesta] = useContattoSalesforceMutation()

  const [submittedForm, setSubmittedForm] = useState(false)
  const initialValues: IinitialVaues = {
    case1: {
      isCase1: requestType === RequestType.case1,
      store: '',
      orderNumber: '',
      orderDate: '',
      orderEmail: '',
    },
    case2: {
      purpose: '',
      request: '',
      file: null,
      contact: 'email',
      brand: '',
      contactBy: utente?.email,
      reason: '',
    },
    case3: {
      isCase3: requestType === RequestType.case3,
      ean: '',
      lotto: '',
      productName: '',
      brand: '',
      expiryDate: '',
    },
    personalData1: {
      isNotRegistered: false,
      firstname: utente?.nome,
      lastname: utente?.cognome,
      email: utente?.email,
      informativa: false,
    },
    personalData2: {
      registration: false,
      password: '',
      gender: '',
      birthday: '',
      optinMarketing: false,
      optinProgramMarketing2: false,
      optinProgramMarketing3: false,
      optinProgramMarketing4: false,
    },
  }

  const handleSubmit = (formik) => {
    if (registered) {
      formik.handleSubmit()
      return
    }
    formik.setFieldValue('personalData1.isNotRegistered', true)
    setSubmittedForm(true)
    nextStep()
  }

  return (
    <WContainer variant="md">
      <div className={styles.container}>
        <HeaderBox
          title={
            submittedForm
              ? t('Inserisci i tuoi dati e invia la tua richiesta')
              : t('Inserisci i dati della richiesta')
          }
          classNameHeader={styles.header}
          classNameTitle={styles.title}
        />
        <div className={styles.menu}>
          <Formik
            isInitialValid={false}
            validateOnChange
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              const response = await createRichiesta({
                variables: {
                  input: { ...values, purposeArea },
                },
              })
              if (!response.data?.contattoSalesforce?.status) {
                setModalErrorCreateAccount(true)
                setError(response.data?.contattoSalesforce.message)
              } else {
                const url = registered ? '/dashboard/informazioni-personali' : '/'
                router.push(`contatti/thank-you?nextPage=${url}`)
              }
              setSubmitting(false)
            }}
            initialValues={initialValues}
            validationSchema={validationSchema(t, purposeArea, utente)}
            enableReinitialize
          >
            {(props) => (
              <>
                {!submittedForm && (
                  <FormField
                    label={t('Motivo del contatto*')}
                    status={
                      props.touched.case2?.purpose
                        ? props.errors.case2?.purpose
                          ? 'error'
                          : 'success'
                        : null
                    }
                    errorMessage={props?.errors?.case2?.purpose}
                    className={styles.formField}
                    layout="full"
                  >
                    <FormSelect
                      onBlur={props.handleBlur('case2.purpose')}
                      placeholder={t("Seleziona un'opzione")}
                      value={props.values?.case2.purpose}
                      options={purposes.map((i: IPurpose) => ({
                        label: i.purpose,
                        value: i.purpose,
                      }))}
                      onChange={(v) => {
                        const purpose: IPurpose = purposes.find(
                          (p: IPurpose) => p.purpose === v.value
                        )
                        props.setFieldValue('case2.reason', purpose.reason)
                        props.setFieldValue('case2.purpose', v.value)
                      }}
                    />
                  </FormField>
                )}

                <RenderForms
                  registered={registered}
                  requestType={requestType}
                  formik={props}
                  submittedForm={submittedForm}
                  purposeArea={purposeArea}
                />

                {utente && (
                  <>
                    <p className={styles.privacyTitle}>{t('Privacy')}</p>
                    <FormField
                      status={
                        props.touched.personalData1?.informativa
                          ? props.errors.personalData1?.informativa
                            ? 'error'
                            : 'success'
                          : null
                      }
                      errorMessage={props.errors?.personalData1?.informativa}
                      layout="full"
                    >
                      <FormCheckbox
                        id="informativa"
                        checked={props.values?.personalData1.informativa}
                        onChange={props.handleChange('personalData1.informativa')}
                        label={<OptinLabel type="informativa-contatto" />}
                      />
                    </FormField>
                  </>
                )}

                {!submittedForm && (
                  <div className={styles.buttons}>
                    <Button
                      label={registered ? t('Invia la tua richiesta') : t('Conferma e procedi')}
                      disabled={!props.isValid || props.isSubmitting}
                      type="submit"
                      onClick={() => handleSubmit(props)}
                      variant="primary"
                      className={styles.submit}
                    />
                  </div>
                )}
              </>
            )}
          </Formik>
        </div>
      </div>
      <ModalFeedback
        open={modalErrorCreateAccount}
        setOpen={setModalErrorCreateAccount}
        closeButton={false}
        onClose={() => {
          setError('')
        }}
        title={t('Si è verificato un errore durante la registrazione')}
        description={t(error)}
        ctaLabel={t('Torna alla registrazione')}
      />
    </WContainer>
  )
}

export default DatiRichiesta
