import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { api } from 'utils'
import { useUtente } from 'hooks'
import { TOOLBAR } from 'utils/endpoints'
import { Button } from 'components/atoms'
import styles from './Toolbar.module.sass'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

interface Props {
  page?: any
  item?: any
}

const Toolbar = (props: Props) => {
  const { page = null, item = null } = props

  const router = useRouter()

  const { utente, loading } = useUtente()

  const [paginaContienePagebuilder, setPaginaContienePagebuilder] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPaginaContienePagebuilder(document.getElementsByClassName('pb-content').length > 0)
    }, 1000)
  }, [router.asPath])

  if (!utente) return null

  if (!(utente.isStaff || utente.isImpersonate)) return null

  const handleSwitchMode = (edit: boolean) => {
    api
      .post(TOOLBAR + 'set_edit/', { edit })
      .then(() => {
        router.reload()
      })
      .catch((error) => console.log(error))
  }

  return (
    <div className={`${styles.toolbar} toolbar`}>
      {utente.isImpersonate ? (
        <div className={`${styles.user} ${styles['user--impersonate']}`}>
          Stai impersonando{' '}
          <span>
            {utente.nome} {utente.cognome}
          </span>{' '}
          - <a href={utente.stopImpersonateUrl}>Smetti di impersonare</a>
        </div>
      ) : (
        <>
          <Button label="Admin" size="sm" href="/d/admin" newPage className={`${styles.adminLink} ${styles.button}`} />
          <div className={styles.user}>
            {utente.nome} {utente.cognome}
          </div>
          <section className={styles.left} />
          <section className={styles.right}>
            {item && item.adminUrl && (
              <Button
                label={`Modifica ${item.modelClass}`}
                size="sm"
                variant="ghost"
                href={item.adminUrl}
                newPage
              />
            )}
            {page && page.adminUrl && (
              <Button label="Admin Pagina" size="sm" variant="ghost" href={page.adminUrl} newPage />
            )}
            {paginaContienePagebuilder &&
              (utente.edit ? (
                <Button label="VEDI PUBBLICATA" size="sm" onClick={() => handleSwitchMode(false)} className={styles.button} />
              ) : (
                <Button label="MODIFICA" size="sm" onClick={() => handleSwitchMode(true)} className={styles.button} />
              ))}
          </section>
        </>
      )}
    </div>
  )
}

export default Toolbar
