import React, { useState } from 'react'
import Link from 'next/link'
import Image from 'next/legacy/image'
import { MenuItemType } from 'utils/types'
import PartoRipartoImage from 'images/PartoRiparto.png'
import { ChevronDown, ChevronUp, Profile } from 'components/atoms/Icons'
import styles from './TopMenu.module.sass'
import { useSelector } from 'react-redux'

interface ItemProps {
  item: MenuItemType
  className?: string
}

interface Props {
  className?: string
}

const TopMenuItem = (props: ItemProps) => {
  const { item = undefined, className = '' } = props

  const [open, setOpen] = useState(false)

  const content = (
    <span className={`${styles.content} ${styles[item.key]}`}>
      {item.key === 'parto-riparto' && <Image src={PartoRipartoImage} className={styles.image} />}
      <span className={styles.label} data-title={item.name}>
        <span>{item.name}</span>
      </span>
      {item.key === 'area-riservata' && (
        <span className={styles.icon}>
          <Profile />
        </span>
      )}
    </span>
  )

  return item ? (
    <div
      key={item.pk}
      className={`${styles.item} ${open ? styles['item--open'] : ''} ${
        item.key ? styles[`item--${item.key}`] : ''
      }`}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {item.url ? ( // se c'è il link c'è la parte di link e il bottone di apertura menu solo sulla freccia
        <>
          <Link legacyBehavior href={item.url}>
            <a className={styles.link}>{content}</a>
          </Link>
          {item.children?.length > 0 && (
            <span className={styles.arrow}>{open ? <ChevronUp /> : <ChevronDown />}</span>
          )}
        </>
      ) : (
        // se non c'è il link è un unico bottone che apre il menu
        <button onClick={() => setOpen(!open)}>
          {content}
          {item.children?.length > 0 && (
            <span className={styles.arrow}>{open ? <ChevronUp /> : <ChevronDown />}</span>
          )}
        </button>
      )}
      {/* Tendina */}
      {item.children?.length > 0 && (
        <div className={styles.dropdown}>
          {item.children.map((x) =>
            x.url ? (
              <Link legacyBehavior href={x.url} key={x.pk}>
                <a className={styles.dropdownItem}>{x.name}</a>
              </Link>
            ) : (
              <></>
            )
          )}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

const TopMenu = (props: Props) => {
  const { className = '' } = props

  const { menu } = useSelector((state) => state.menu)
  const topMenu = menu?.find((item) => item.key === 'top')

  return topMenu?.children?.length > 0 ? (
    <div className={`${styles.topMenu} ${className}`}>
      {topMenu.children.map((x) => (
        <TopMenuItem key={x.pk} item={x} />
      ))}
    </div>
  ) : (
    <></>
  )
}

export default TopMenu
