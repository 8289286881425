import React from 'react'
import useTrans from 'hooks/useTrans'
import { Edit } from "@components/atoms/Icons";
import { Button, Separator } from "@components/atoms";
import { MyAccountViewItem } from 'components/molecules'
import styles from './MyAccountAccountView.module.sass'

interface Props {
  email?: string
  editMode?: boolean
  editButton?: any
}

const MyAccountAccountView = (props: Props) => {

  const { 
    email = '',
    editMode = false,
    editButton = null,
  } = props

  const t = useTrans()

  return (
    <div className={styles.myAccountAccountView}>
        <div>
           <MyAccountViewItem label={t("Email")} value={email} valueUnderlined />
        </div>
        <Separator className={styles.separator}/>
        <div className={styles.container}>
            <MyAccountViewItem label={t("Password")} value="••••••••••" />
            {!editMode && editButton &&
              <div className={styles.editContainer}>
                  <Button {...{
                  variant: 'ghost',
                  label: t('Modifica password'),
                  icon: <Edit className={styles.svg} />,
                  iconPos: 'right',
                  size: 'sm',
                  ...editButton,
                  className: `${styles.editButton} ${editButton.className ? ` ${editButton.className}` : ''}`
                }}
                />
              </div>
            }
        </div>
    </div>
  )
}

export default MyAccountAccountView
