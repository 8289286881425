import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { Close, Info } from '@components/atoms/Icons'
import { Button, Modal, Tooltip, WContainer, WImage } from '@components/atoms'
import styles from './LandingProdottoProdotti.module.sass'
import { useRouter } from 'next/router'

interface Props {
  categoria: any // TODO
  className?: string
  paginaTitolo?: string
}

const LandingProdottoProdotti = (props: Props) => {
  const { categoria = undefined, className = '', paginaTitolo = '' } = props

  const t = useTrans()
  const [activeCategory, setActiveCategory] = useState(categoria?.children?.[0])
  const [modalInfoItem, setModalInfoItem] = useState(null)
  const router = useRouter()


  return categoria ? (
    <div className={`${styles.landingProdottoProdotti} ${className}`}>
      <WContainer>
        <div className={styles.content}>
          <p className={styles.intro}>{t('Prodotti')}</p>
          {!!categoria.labelProdotti && <p className={styles.title}>{categoria.labelProdotti}</p>}
          {!!categoria.descrizione && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: categoria.descrizione }}
            />
          )}
        </div>
      </WContainer>
      {categoria.children?.length > 0 && (
        <>
          <WContainer variant='fluid'>
            <div className={styles.categories}>
              <Swiper spaceBetween={14} slidesPerView="auto" centerInsufficientSlides>
                {categoria.children.map((x) => (
                  <SwiperSlide key={`categorie-${x.pk}`}>
                    <Button
                      variant={x.pk === activeCategory?.pk ? 'primary' : 'secondary'}
                      color="primary"
                      className={styles.categoriesButton}
                      onClick={() => {
                        setActiveCategory(x)
                      }}
                      label={x.titolo}
                    />
                  </SwiperSlide>                
                ))}
              </Swiper>
            </div>
          </WContainer>
          <WContainer>
            {!!activeCategory && (
              <div className={styles.category}>
                {!!activeCategory.descrizione && (
                  <div
                    className={styles.categoryDescription}
                    dangerouslySetInnerHTML={{ __html: activeCategory.descrizione }}
                  />
                )}
                {activeCategory?.prodotti?.length > 0 && (
                  <div className={styles.productList}>
                    <Swiper
                      spaceBetween={20}
                      slidesPerView={1.2}
                      watchOverflow
                      centerInsufficientSlides
                      pagination={{ clickable: true }}
                      breakpoints={{
                        0: { centeredSlides: true },
                        576: { slidesPerView: 2.5 },
                        768: { slidesPerView: 3.5 },
                        992: { slidesPerView: 4 },
                      }}
                    >
                      {activeCategory.prodotti.map((x, index) => (
                        <SwiperSlide key={`prodotto-${x.pk}`}>
                          <div className={styles.product}>
                            {x.informazioniAggiuntive?.length > 0 && (
                              <>
                                <Tooltip
                                  id={`product_info_${x.pk}`}
                                  color="primary"
                                  pos={index < 2 ? 'right' : 'left'}
                                  className={styles.productInfoTooltip}
                                  label={
                                    <div className={styles.productInfoBox}>
                                      {x.informazioniAggiuntive.map((info) => (
                                        <div key={`info-${info.pk}`} className={styles.productInfoItem}>
                                          <div className={styles.productInfoIcon}>
                                            <WImage
                                              src={info.image}
                                              maxWidth={58}
                                              maxHeight={58}
                                              alt={info.nome}
                                              title={info.nome}
                                            />
                                          </div>
                                          <div
                                            className={styles.productInfoLabel}
                                            dangerouslySetInnerHTML={{ __html: info.descrizione }}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  }
                                >
                                  <span className={styles.productInfoTrigger}>
                                    <Info />
                                  </span>
                                </Tooltip>
                                <button
                                  type="button"
                                  className={styles.productInfoTriggerMobile}
                                  onClick={() => setModalInfoItem(x)}
                                >
                                  <Info />
                                </button>
                              </>
                            )}
                            <div className={styles.productImageBox}>
                              <WImage
                                src={x.mainImage}
                                maxWidth={180}
                                maxHeight={180}
                                layout="intrinsic"
                                alt={x.name}
                                title={x.name}
                              />
                            </div>
                            {!!x.titolo && <p className={styles.productTitle}>{x.titolo}</p>}
                            {!!x.descrizione && (
                              <div
                                className={styles.productDescription}
                                dangerouslySetInnerHTML={{ __html: x.descrizione }}
                              />
                            )}
                            {!!x.link && (
                              <div className={styles.productCtaBox}>
                                <Button
                                  label={x.cta || t('Acquista ora')}
                                  variant="secondary"
                                  href={`${x.link}?utm_source=mellin.it&utm_medium=referral&utm_campaign=prodotti-["${router.asPath}"]`}
                                  newPage={x.blank}
                                  external={x.blank}
                                />
                              </div>
                            )}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            )}
          </WContainer>
        </>
      )}
      <Modal
        open={!!modalInfoItem && modalInfoItem?.informazioniAggiuntive?.length > 0}
        setOpen={() => setModalInfoItem(null)}
        className={styles.productInfoModal}
        closeButton
      >
        {modalInfoItem && (
          <div className={styles.productInfoModalContent}>
            {modalInfoItem.informazioniAggiuntive.map((info) => (
              <div key={`modal-info-${info.pk}`} className={styles.productInfoModalItem}>
                <div className={styles.productInfoModalIcon}>
                  <WImage
                    src={info.image}
                    maxWidth={58}
                    maxHeight={58}
                    alt={info.nome}
                    title={info.nome}
                  />
                </div>
                <div
                  className={styles.productInfoModalLabel}
                  dangerouslySetInnerHTML={{ __html: info.descrizione }}
                />
              </div>
            ))}
          </div>
        )}
      </Modal>
    </div>
  ) : (
    <></>
  )
}

export default LandingProdottoProdotti
