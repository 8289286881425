import React from 'react'
import useTrans from 'hooks/useTrans'
import { formatDate } from 'utils/safe'
import { MyAccountViewItem } from 'components/molecules'
import styles from './MyAccountDatiView.module.sass'

interface Props {
  nome?: string
  cognome?: string
  sesso?: string
  dataNascita?: string
}

const MyAccountDatiView = ({ nome, cognome, sesso, dataNascita }: Props) => {
  const t = useTrans()

  return (
    <div className={styles.myAccountDatiView}>
      <MyAccountViewItem label={t('Nome')} value={nome} />
      <MyAccountViewItem label={t('Cognome')} value={cognome} />
      <MyAccountViewItem label={t('Sesso')} value={sesso} />
      <MyAccountViewItem label={t('Data di Nascita')} value={dataNascita} />
    </div>
  )
}

export default MyAccountDatiView
