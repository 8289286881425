import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getIcon } from 'utils/icons'
import { MenuItemType } from 'utils/types'
import { WContainer } from '@components/atoms'
import { NeonatoIllustration } from '@components/atoms/Icons'
import useTrans from '@hooks/useTrans'
import styles from './ImpegnoSection4.module.sass'

interface Props {
  item: MenuItemType
  className?: string
}

const ImpegnoSection4 = (props: Props) => {
  const t = useTrans()

  const { item, className = '' } = props

  return item ? (
    <div className={`${styles.impegnoSection4} ${className}`}>
      <WContainer>{!!item.name && <p className={styles.title}>{item.name}</p>}</WContainer>
      <div className={styles.slider}>
        <Swiper
          spaceBetween={16}
          centeredSlides
          pagination={{ clickable: true }}
          slidesPerView="auto"
          loop
        >
          {item.children.map((x) => (
            <SwiperSlide key={x.pk}>
              <div className={styles.item}>
                {!!x.style && <div className={styles.itemIcon}>{getIcon(x.style)}</div>}
                {!!item.description && (
                  <div
                    className={styles.itemDescription}
                    dangerouslySetInnerHTML={{ __html: x.description }}
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <WContainer>
        <div className={styles.results}>
          <p className={styles.resultsTitle}>{t('Il risultato delle iniziative in atto?')}</p>
          <div className={styles.resultsBox}>
            <div className={styles.resultsIcon}>
              <NeonatoIllustration />
            </div>
            <p className={styles.resultsContent}>{t('Un +7% di nascite in azienda')}</p>
          </div>
          <p className={styles.resultsDescription}>{t("Perchè l'impegno, funziona.")}</p>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default ImpegnoSection4
