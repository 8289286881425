import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'
import {
  FiglioNode,
  useAddParametroCurvaMutation,
  useUpdateParametroCurvaMutation,
} from 'gql/graphql'
import { useRouter } from 'next/dist/client/router'
import { useTrans } from 'hooks'
import { getDateDiffInMonths } from 'utils/safe'
import { FormLayout, FormField, FormDate, FormInput, Modal, Button } from 'components/atoms'

interface Props {
  open: boolean
  curva: any
  setOpen: (e: boolean) => void
  datiFiglio: FiglioNode
}

const validationSchema = (t) =>
  yup.object().shape({
    data: yup.date().required(t('Questo campo non può essere vuoto')).nullable(),
    peso: yup
      .number()
      .required(t('Questo campo non può essere vuoto'))
      .nullable()
      .typeError(t('deve essere un numero'))
      .positive(t(`Il peso deve essere positivo`))
      .max(20, t('Il valore massimo è 20kg')),
    lunghezza: yup
      .number()
      .typeError(t('deve essere un numero'))
      .required(t('Questo campo non può essere vuoto'))
      .nullable()
      .positive(t(`Il peso deve essere positivo`))
      .max(120, t('Il valore massimo è 120cm')),
  })

// growthCurveWeight
// growthCurveHeight
// pk
// insertionDate

const ModalAggiungiParametro = ({ open, setOpen, datiFiglio, curva }: Props) => {
  const t = useTrans()
  const router = useRouter()
  const [error, setError] = useState<string | null>()
  const [addParametro, { loading }] = useAddParametroCurvaMutation()
  const [updateParametro] = useUpdateParametroCurvaMutation()
  const etaFiglioInMesi = getDateDiffInMonths(datiFiglio?.dataNascita, dayjs().format('YYYY-MM-DD'))

  return (
    <Modal open={open} setOpen={setOpen}>
      <Formik
        validateOnChange
        initialValues={{
          data: dayjs().format('YYYY-MM-DD'), // Default: data di oggi
          peso: '',
          lunghezza: '',
        }}
        isInitialValid={false}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
          const EsisteParamInQuellaData = curva?.filter(
            (param) =>
              dayjs(param.insertionDate).format('YYYY-MM-DD') ==
              dayjs(values.data).format('YYYY-MM-DD')
          )

          if (EsisteParamInQuellaData?.length > 0) {
            updateParametro({
              variables: {
                curve: {
                  childrenId: datiFiglio?.pk,
                  id: EsisteParamInQuellaData?.[0]?.pk,
                  param1: Number.parseFloat(values?.lunghezza),
                  param2: Number.parseFloat(values?.peso),
                  date: values?.data,
                },
              },
            })
              .then(() => router.reload())
              .catch((err) => console.log(err))
          } else {
            addParametro({
              variables: {
                curve: {
                  childrenId: datiFiglio?.pk,
                  param1: Number.parseFloat(values?.lunghezza),
                  param2: Number.parseFloat(values?.peso),
                  date: values?.data,
                },
              },
            })
              .then(() => router.reload())
              .catch((err) => console.log(err))
          }
        }}
      >
        {(props) => (
          <>
            <Modal.Content
              title={t('Inserisci nuovo parametro')}
              description={t(
                datiFiglio?.genderType === 'F'
                  ? 'Quanto è cresciuta {0}?'
                  : 'Quanto è cresciuto {0}?',
                [datiFiglio?.firstName]
              )}
            >
              <FormLayout>
                <FormField
                  label={t('Data di rilevamento')}
                  layout="full"
                  status={props.touched.data ? (props.errors.data ? 'error' : 'success') : null}
                  errorMessage={props?.errors?.data}
                >
                  <FormDate
                    value={props.values.data}
                    onChange={(value) => {
                      props.setFieldValue('data', value)
                      setError(null)
                    }}
                    yearFrom={new Date().getFullYear() - 18}
                    yearNumber={20}
                  />
                </FormField>
                <FormField
                  label={t('Quanto pesa?')}
                  layout="full"
                  status={props.touched.peso ? (props.errors.peso ? 'error' : 'success') : null}
                  errorMessage={props?.errors?.peso}
                >
                  <FormInput
                    placeholder={t('Peso')}
                    onBlur={props.handleBlur('peso')}
                    value={props.values.peso}
                    onChange={props.handleChange('peso')}
                    unitaMisura="kg"
                  />
                </FormField>
                <FormField
                  label={t(etaFiglioInMesi > 24 ? 'Quanto è alto' : 'Quanto è lungo?')}
                  layout="full"
                  status={
                    props.touched.lunghezza ? (props.errors.lunghezza ? 'error' : 'success') : null
                  }
                  errorMessage={props?.errors?.lunghezza}
                >
                  <FormInput
                    placeholder={t(etaFiglioInMesi > 24 ? 'Altezza' : 'Lunghezza')}
                    onBlur={props.handleBlur('lunghezza')}
                    value={props.values.lunghezza}
                    onChange={props.handleChange('lunghezza')}
                    unitaMisura="cm"
                  />
                </FormField>
              </FormLayout>
            </Modal.Content>
            {error && (
              <p>
                {error} <strong>Desideri Modificarlo?</strong>
              </p>
            )}
            <Modal.Buttons>
              <Button label={t('Annulla')} variant="ghost" onClick={() => setOpen(false)} />
              <Button
                disabled={!props.isValid}
                label={t('Aggiungi alla curva')}
                loading={loading}
                onClick={() => props.handleSubmit()}
              />
            </Modal.Buttons>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalAggiungiParametro
