import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useUpdateServiceUserMutation } from 'gql/graphql'
import useTrans from 'hooks/useTrans'
import { useRouter } from 'next/router'
import { Button, FormField, FormCheckbox, FormLayout, OptinLabel } from 'components/atoms'
import { FormCard } from 'components/molecules'

const validationSchema = (t) =>
  yup.object().shape({
    privacy: yup.boolean().required(t('Questo campo è obbligatorio')),
    privacyCommerciale: yup.boolean(),
    privacyProfilazione: yup.boolean(),
    privacyInformativa: yup.boolean(),
  })

const FormPrivacy = ({ initialValuesFromProps, edit, setEdit }: any) => {
  const t = useTrans()
  const router = useRouter()
  const [updatePrivacy, { loading }] = useUpdateServiceUserMutation()
  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema(t)}
      onSubmit={(values) => {
        updatePrivacy({
          variables: {
            privacyData: {
              optinMellin: values?.privacyCommerciale,
              optinMarketing: values?.privacyInformativa,
              optinProgramMarketing2: values?.privacyProfilazione,
            },
          },
        })
          .then((res) => {
            // setInitialValuesFromProps({ ...res?.data?.updateUtente?.utente })
            // setEdit(false)
            router.reload()
          })
          .catch((error) => console.log(error))
      }}
      initialValues={{
        privacy: initialValuesFromProps?.privacy || false,
        privacyCommerciale: initialValuesFromProps?.privacyCommerciale || false,
        privacyProfilazione: initialValuesFromProps?.privacyProfilazione || false,
        privacyInformativa: initialValuesFromProps?.privacyInformativa || false,
      }}
    >
      {(props) => (
        <FormCard
          title={t('Modifica i tuoi dati account')}
          editMode={edit}
          editButton={edit ? null : { onClick: () => setEdit(true) }}
        >
          <FormCard.Content>
            <FormLayout>
              <strong>
                {t(
                  'Ai sensi e per gli effetti degli articoli artt. 7,13 e 15 del Regolamento UE 2016/679,'
                )}
              </strong>
              <FormField
                layout="full"
                status={
                  props.touched?.privacy ? (props.errors?.privacy ? 'error' : 'success') : null
                }
                errorMessage={props?.errors?.privacy}
              >
                <FormCheckbox
                  id="privacy"
                  disabled={!!edit}
                  onBlur={props.handleBlur('privacy')}
                  label={<OptinLabel type="datiParticolari" />}
                  checked
                  readOnly
                />
              </FormField>
              <FormField
                layout="full"
                status={
                  props.touched?.privacyInformativa
                    ? props.errors?.privacyInformativa
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.privacyInformativa}
              >
                <FormCheckbox
                  id="privacyInformativa"
                  onBlur={props.handleBlur('privacyInformativa')}
                  label={<OptinLabel type="informativa" />}
                  checked={props.values?.privacyInformativa}
                  onChange={(e) =>
                    edit && props.setFieldValue('privacyInformativa', e?.target?.checked)
                  }
                  readOnly={!edit}
                />
              </FormField>
              <FormField
                layout="full"
                status={
                  props.touched?.privacyCommerciale
                    ? props.errors?.privacyCommerciale
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.privacyCommerciale}
              >
                <FormCheckbox
                  id="privacyCommerciale"
                  onBlur={props.handleBlur('privacyCommerciale')}
                  label={<OptinLabel type="commerciale" />}
                  checked={props.values?.privacyCommerciale}
                  onChange={(e) =>
                    edit && props.setFieldValue('privacyCommerciale', e?.target?.checked)
                  }
                  readOnly={!edit}
                  accordion
                />
              </FormField>
              <FormField
                layout="full"
                status={
                  props.touched?.privacyProfilazione
                    ? props.errors?.privacyProfilazione
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.privacyProfilazione}
              >
                <FormCheckbox
                  id="privacyProfilazione"
                  onBlur={props.handleBlur('privacyProfilazione')}
                  label={<OptinLabel type="profilazione" />}
                  checked={props.values?.privacyProfilazione}
                  onChange={(e) =>
                    edit && props.setFieldValue('privacyProfilazione', e?.target?.checked)
                  }
                  readOnly={!edit}
                />
              </FormField>
            </FormLayout>
          </FormCard.Content>
          {edit && (
            <FormCard.Buttons>
              <Button label={t('Annulla')} onClick={() => setEdit(false)} variant="ghost" />
              <Button
                loading={loading}
                type="submit"
                disabled={!props.isValid}
                onClick={() => props.handleSubmit()}
                label={t('Salva modifiche')}
                variant="primary"
              />
            </FormCard.Buttons>
          )}
        </FormCard>
      )}
    </Formik>
  )
}

export default FormPrivacy
