import { Formik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import { Button, FormField, FormInput, FormLayout } from 'components/atoms'
import styles from './FormRecuperaPassword.module.sass'
import { useRecuperaPasswordMutation } from '@gql/graphql'
import { useRouter } from 'next/router'
import {ModalFeedback} from "@components/organisms";
import useTrans from "@hooks/useTrans";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Questo campo è obbligatorio')
    .email("Il formato dell'email dev'essere valido")
    .nullable(),
})

const FormRecuperaPassword = () => {
  const initialValues = {
    email: null,
  }

  const [recuperaPassword, { loading }] = useRecuperaPasswordMutation()
  const [submitted, setSubmitted] = useState(false)

  const router = useRouter()
  const t = useTrans()

  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) =>
        recuperaPassword({
          variables: {
            email: values.email,
          },
        })
          .then((res) => {
              setSubmitted(true)
          })
          .catch((error) => {
          })
      }
    >
      {(props) =>
        <>
          <FormLayout>
            <FormField
              label="Email"
              status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
              errorMessage={props.errors.email as string}
              layout="full"
              className={styles.label}
            >
              <FormInput
                value={props.values.email}
                onBlur={props.handleBlur('email')}
                onChange={props.handleChange('email')}
                placeholder="La tua email"
              />
            </FormField>
          </FormLayout>
          <Button
            disabled={!!props.errors.email || !props.dirty}
            variant="primary"
            label="Invia"
            onClick={() => props.handleSubmit()}
            type="submit"
            className={styles.submit}
            loading={loading}
          />

          <ModalFeedback
            open={submitted}
            setOpen={setSubmitted}
            closeButton={false}
            onClose={event => router.push("")}
            title={t('Ti abbiamo inviato una mail')}
            description={t(
              'Controlla la tua casella ti posta. Ti abbiamo inviato una mail con il link per reimpostare la password.'
            )}
            ctaLabel={t('Torna alla home')}
          />
        </>
      }
    </Formik>
  )
}

export default FormRecuperaPassword
