import React, { useState } from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { MenuItemType } from 'utils/types'
import { ArrowRight } from '@components/atoms/Icons'
import { Button, WContainer } from '@components/atoms'
import { ArticoloCard, GenericCard, ProdottoCard } from '@components/molecules'
import styles from './Nav.module.sass'

interface DropdownProps {
  item: MenuItemType
  open?: boolean
  className?: string
}

interface DropdownColProps {
  item: MenuItemType
  parent?: string
}

interface DropdownMenuProps {
  item: MenuItemType
  levels?: number
  parent?: string
}

interface Props {
  menu: MenuItemType
  activeItem?: MenuItemType | null
  setActiveItem?: (item: MenuItemType) => void
  className?: string
}

const NavDropdown = (props: DropdownProps) => {
  const { item = undefined, open = false, className = '' } = props

  const t = useTrans()

  return item?.children?.length ? (
    <div className={`${styles.dropdown} ${open ? styles['dropdown--open'] : ''} ${className}`}>
      <WContainer>
        {item.key === 'prodotti' ? (
          <div className={styles.prodotti}>
            {item.children.map((item2) => (
              <Link legacyBehavior href={item2.url || '#'} key={item2.pk}>
                <a className={styles.prodottiItem}>
                  <ProdottoCard
                    key={item2.pk}
                    prodotto={{
                      pk: item2.pk,
                      titolo: item2.name,
                      descrizione: item2.description,
                      mainImage: item2.image,
                    }}
                    size="sm"
                  />
                </a>
              </Link>
            ))}
          </div>
        ) : (
          <div className={styles.dropdownLayout}>
            {item.children.map((x) => (
              <NavDropdownCol key={x.pk} item={x} parent={item.name} />
            ))}
          </div>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

const NavDropdownCol = (props: DropdownColProps) => {
  const { item = undefined, parent = '' } = props

  const [type, col] = item.style?.split('_') || ['', '1']

  return (
    <div
      className={`${styles.col} ${styles[`col--${col}`]} ${
        item.key ? styles[`key--${item.key}`] : ''
      }`}
    >
      {type === 'box' && (
        <div className={styles.boxes}>
          {item.children.map((item2) => (
            <GenericCard key={item2.pk} item={item2} size="sm" iconSize="sm" />
          ))}
        </div>
      )}
      {type === 'articoli' && (
        <div className={styles.articoli}>
          {item.children?.length > 0 &&
            item.children.map((x) => <ArticoloCard key={x.pk} articolo={x.articolo} size="sm" />)}
        </div>
      )}
      {(type === 'menu' || type === 'menu2levels') && (
        <NavDropdownMenu item={item} parent={parent} levels={type === 'menu2levels' ? 2 : 1} />
      )}
    </div>
  )
}

const NavDropdownMenu = (props: DropdownMenuProps) => {
  const { item = undefined, levels = 1, parent = '' } = props

  const [activeItem, setActiveItem] = useState<MenuItemType | null>(null)

  return item ? (
    <div className={`${styles.dropdownMenu} ${levels > 1 ? styles['dropdownMenu--2levels'] : ''}`}>
      <div className={styles.dropdownMenuLevel1}>
        {item?.children?.length > 0 &&
          item.children.map((x) =>
            x.children?.length > 0 ? (
              <button
                key={x.pk}
                type="button"
                className={`${styles.dropdownMenuLevel1Item} ${
                  activeItem && activeItem.pk === x.pk
                    ? styles['dropdownMenuLevel1Item--active']
                    : ''
                }`}
                onMouseEnter={() => setActiveItem(x)}
              >
                <span className={styles.dropdownMenuItemLabel}>{x.name}</span>
                <span className={styles.dropdownMenuItemArrow}>
                  <ArrowRight />
                </span>
              </button>
            ) : (
              x.url && (
                <Link legacyBehavior href={x.url} key={x.pk}>
                  <a
                    className={styles.dropdownMenuLevel1Item}
                    onMouseEnter={() => setActiveItem(null)}
                  >
                    {x.name}
                  </a>
                </Link>
              )
            )
          )}
      </div>
      {levels > 1 && (
        <div className={styles.dropdownMenuLevel2}>
          {activeItem?.children?.length > 0 &&
            activeItem.children.map((x) =>
              x.url ? (
                <div
                  key={x.pk}
                  className={`${styles.dropdownMenuLevel2Item} ${
                    x.style ? styles[`dropdownMenuLevel2Item--${x.style}`] : ''
                  }`}
                >
                  <Link legacyBehavior href={x.url}>
                    <a className={styles.dropdownMenuLevel2ItemLink}>{x.name}</a>
                  </Link>
                </div>
              ) : (
                <></>
              )
            )}
          {activeItem?.url && (
            <div className={styles.dropdownMenuLevel2FinalLink}>
              <Button variant="ghost" label={activeItem.ctaLabel} size="sm" href={activeItem.url} />
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

const Nav = (props: Props) => {
  const { menu = undefined, className = '' } = props

  const [activeItem, setActiveItem] = useState<MenuItemType | null>(null)

  return menu && menu.children?.length > 0 ? (
    <>
      <nav className={`${styles.nav} ${className}`}>
        {menu.children.map((x) => (
          <div
            key={x.pk}
            className={styles.itemWrap}
            onMouseEnter={() => setActiveItem(x)}
            onMouseLeave={() => setActiveItem(null)}
          >
            {x.url ? (
              <Link legacyBehavior href={x.url}>
                <a
                  className={`${styles.item} ${
                    activeItem?.pk === x.pk ? styles['item--active'] : ''
                  }`}
                >
                  <span className={styles.itemContent}>{x.name}</span>
                </a>
              </Link>
            ) : (
              <span
                className={`${styles.item} ${
                  activeItem?.pk === x.pk ? styles['item--active'] : ''
                }`}
              >
                <span className={styles.itemContent}>{x.name}</span>
              </span>
            )}
            <NavDropdown item={x} open={x.pk === activeItem?.pk} />
          </div>
        ))}
      </nav>
    </>
  ) : (
    <></>
  )
}

Nav.Dropdown = NavDropdown

export default Nav
