import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { FormUnsubs } from 'components/organisms'
import styles from './MyUnsubscribeFormCard.module.sass'

interface Props {
  unsubs?: {},
  token?: string
}

const MyUnsubscribeFormCard = (props: Props) => {
  const {
      unsubs = null,
      token = '',
  } = props
  const [edit, setEdit] = useState(true)

  return (
    <div className={styles.card}>
      <FormUnsubs
        unsubs={unsubs}
        token={token}
        edit={edit}
        setEdit={(v) => setEdit(v)}
      />
    </div>
  )
}

export default MyUnsubscribeFormCard
