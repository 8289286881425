import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useTrans from '@hooks/useTrans'
import { MenuItemType } from 'utils/types'
import { Button } from 'components/atoms'
import { ProdottoCorrelatoCard } from '@components/molecules'
import styles from './ProdottiCorrelati.module.sass'

interface Props {
  item: MenuItemType
  className?: string
}

const ProdottiCorrelati = (props: Props) => {
  const { className = '', item = undefined } = props
  const t = useTrans()

  return !!item ? (
    <div className={`${styles.prodottiCorrelati} ${className}`}>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title}>{item.name}</p>}
        {!!item.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </div>

      <Swiper
        spaceBetween={16}
        slidesPerView={1}
        centerInsufficientSlides
        pagination={{ clickable: true }}
        watchOverflow
        breakpoints={{
          320: {
            slidesPerView: 1.3,
          },
          576: {
            slidesPerView: 2.3,
          },
          768: {
            slidesPerView: 3,
          }}
        }
      >
        {item.children?.length > 0 &&
          item.children.slice(0, 2).map((x) => (
            <SwiperSlide key={x.pk}>
              <ProdottoCorrelatoCard
                key={x.pk}
                prodotto={{
                  pk: x.pk,
                  titolo: x.name,
                  descrizione: x.description,
                  mainImage: x.image,
                  link: x.url,
                  blank: x.blank
                }}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      {!!item.url && (
        <div className={styles.ctaBox}>
          <Button
            label={item.ctaLabel ? item.ctaLabel : t('Vai allo shop online')}
            size="sm"
            className={styles.cta}
            href={item.url}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}
export default ProdottiCorrelati
