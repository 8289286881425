import React from 'react'
import styles from './MyAccountViewItem.module.sass'

interface Props {
  label?: string
  value?: string
  valueUnderlined?: boolean
}

const MyAccountViewItem = (props: Props) => {

  const {
    label = '',
    value = '',
    valueUnderlined = false,
  } = props

  return <div className={styles.myAccountViewItem}>
    <span className={styles.label}>{label}</span>
    <span className={`${styles.value} ${valueUnderlined ? styles.underlined : ''}`}>{value}</span>
  </div>
}

export default MyAccountViewItem
