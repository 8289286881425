import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { useTrans } from 'hooks'
import { CiuccioF } from 'components/atoms/Icons'
import { FormDate, FormLayout, FormSelect, FormField, Button, Modal } from 'components/atoms'
import styles from './FormCalcolaDataNascita.module.sass'

const validationSchema = (t) =>
  yup.object().shape({
    data_primo_giorno: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    durata_ciclo: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

const FormCalcolaDataNascita = () => {
  const t = useTrans()
  const [result, setResult] = useState({
    open: false,
    result: null,
  })
  return (
    <>
      <Formik
        isInitialValid={false}
        validationSchema={validationSchema(t)}
        initialValues={{
          data_primo_giorno: dayjs().format('YYYY-MM-DD'),
          durata_ciclo: 28,
        }}
        onSubmit={(values) => {
          const calcolo = dayjs(values.data_primo_giorno)
            .subtract(3, 'months')
            .add(1, 'year')
            .add(7, 'day')

          const result =
            values.durata_ciclo > 28
              ? dayjs(calcolo).add(values.durata_ciclo - 28, 'day')
              : dayjs(calcolo).subtract(28 - values.durata_ciclo, 'day')

          setResult({
            open: true,
            result: result.format('DD/MM/YYYY'),
          })
        }}
      >
        {(props) => (
          <>
            <FormLayout>
              <FormField
                label={t('Inserisci il primo giorno del tuo ultimo ciclo')}
                errorMessage={props.errors?.data_primo_giorno && t('Inserire una data valida')}
                status={
                  props.touched?.data_primo_giorno
                    ? props.errors?.data_primo_giorno
                      ? 'error'
                      : 'success'
                    : null
                }
              >
                <FormDate
                  status={props.errors?.data_primo_giorno ? 'error' : ''}
                  value={props.values?.data_primo_giorno}
                  onChange={(value) => props.setFieldValue('data_primo_giorno', value)}
                />
              </FormField>

              <FormField
                label={t('Durata del ciclo (giorni)')}
                errorMessage={props.errors?.durata_ciclo}
                status={
                  props.touched?.durata_ciclo
                    ? props.errors?.durata_ciclo
                      ? 'error'
                      : 'success'
                    : null
                }
                layout ='full'
              >
                <FormSelect
                  placeholder={t('Inserire durata del ciclo')}
                  value={props.values?.durata_ciclo}
                  onChange={(value) => props.setFieldValue('durata_ciclo', value?.value)}
                  options={[...Array.from({ length: 16 }).keys()].map((i) => ({
                    label: i + 21,
                    value: i + 21,
                  }))}
                />
              </FormField>
            </FormLayout>
            <div className={styles.submitBox}>
              <Button
                onClick={() => props.handleSubmit()}
                variant="primary"
                label="Calcola data di nascita"
                className={styles.submit}
              />
            </div>
          </>
        )}
      </Formik>
      <Modal
        open={result?.open || false}
        setOpen={(value) => setResult((prev) => ({ ...prev, open: value }))}
        className={styles.modal}
        closeButton={true}
      >
        <Modal.Content >
          <div className={styles.modalContent}>
            <div className={styles.modalIcon}>
              <CiuccioF />
            </div>
            <p>{'Il tuo bambino nascerà il '}</p>
            <p className={styles.modalResult}>{result?.result?.toString()}</p>
          </div>
        </Modal.Content>

        <Modal.Buttons>
          <Button
            label={t('Calcola nuova data di nascita')}
            onClick={() => setResult({ result: null, open: false })}
            variant='secondary'
          />
        </Modal.Buttons>
      </Modal>
    </>
  )
}

export default FormCalcolaDataNascita
