import React from 'react'
import useTrans from 'hooks/useTrans'
import { StepProps } from '../FormContattaci.interfaces'
import {
  FormDate,
  FormField,
  FormInput,
  FormLayout,
  FormSelect,
  FormStep,
  WImage,
} from 'components/atoms'
import { useStoresQuery } from '@gql/graphql'
import { useSelector } from 'react-redux'

const Case1 = ({ formik }: StepProps) => {
  const t = useTrans()
  const { data } = useStoresQuery({ fetchPolicy: 'cache-first' })
  const stores = data?.stores.map((item) => ({ value: item.name, label: item.name }))

  const { utente } = useSelector((state) => state.utente)

  return (
    <FormStep>
      <FormStep.Content>
        <FormLayout>
          <FormField
            label={t('Store*')}
            errorMessage={formik.errors.case1?.store}
            status={formik.errors.case1?.store ? 'error' : null}
          >
            <FormSelect
              options={stores}
              placeholder={t('Seleziona uno store')}
              value={formik.values?.case1.store}
              onBlur={formik.handleBlur('case1.store')}
              onChange={(v) => {
                formik.setFieldValue('case1.store', v.value)
              }}
            />
          </FormField>

          <FormField
            label={t('Numero ordine')}
            status={
              formik.touched.case1?.orderNumber
                ? formik.errors.case1?.orderNumber
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.case1?.orderNumber}
            helper={{
              id: 'order-helper',
              label: (
                <>
                  {t(
                    "Trovi il numero d'ordine nell'area personale del sito su cui ha acquistato o nell'email di conferma ordine"
                  )}
                </>
              ),
              pos: 'bottom',
            }}
          >
            {utente?.ordini?.length ? (
              <FormSelect
                options={utente?.ordini.map((i) => ({
                  label: i.orderNumber,
                  value: i.orderNumber,
                }))}
                placeholder={t('Seleziona un ordine')}
                value={formik.values?.case1.orderNumber}
                onBlur={formik.handleBlur('case1.orderNumber')}
                onChange={(v) => {
                  formik.setFieldValue('case1.orderNumber', v.value)
                }}
              />
            ) : (
              <FormInput
                onBlur={formik.handleBlur('case1.orderNumber')}
                placeholder={t('Numero ordine')}
                value={formik.values?.case1?.orderNumber}
                onChange={formik.handleChange('case1.orderNumber')}
              />
            )}
          </FormField>

          <FormField
            label={t('Data ordine (gg/mm/aaaa)')}
            errorMessage={formik.errors?.orderDate && t('Inserire una data valida')}
            status={
              formik.touched?.orderDate ? (formik.errors?.orderDate ? 'error' : 'success') : null
            }
          >
            <FormDate
              status={formik.errors?.case1?.orderDate ? 'error' : ''}
              value={formik.values?.case1?.orderDate}
              onChange={(value) => formik.setFieldValue('case1.orderDate', value)}
            />
          </FormField>

          <FormField
            label={t('Email*')}
            status={
              formik.touched.case1?.orderEmail
                ? formik.errors.case1?.orderEmail
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.case1?.orderEmail}
            layout="full"
          >
            <FormInput
              onBlur={formik.handleBlur('case1.orderEmail')}
              placeholder={t("Email dell'ordine")}
              value={formik.values?.case1?.orderEmail}
              onChange={formik.handleChange('case1.orderEmail')}
              type="email"
            />
          </FormField>
        </FormLayout>
      </FormStep.Content>
    </FormStep>
  )
}

export default Case1
