import React, { useState } from 'react'
import useTrans from 'hooks/useTrans'
import {
  Button,
  FormCheckbox,
  FormDate,
  FormField,
  FormInput,
  FormInputPassword,
  FormLayout,
  FormSelect,
  FormStep,
  FormToggle,
  HeaderBox,
  OptinLabel,
  Separator,
  WContainer,
  WImage,
} from 'components/atoms'
import { StepProps, genderProps } from '../../FormContattaci.interfaces'
import styles from './DatiPersonali.module.sass'
import { useBrandOptinsQuery } from '@gql/graphql'

const DatiPersonali = ({ formik }: StepProps) => {
  const t = useTrans()

  const { data: optins, optinsError } = useBrandOptinsQuery()

  const [registration, setRegistration] = useState(false)
  const [confirmRegistration, setConfirmRegistration] = useState(false)

  const [preferenzeOpen, setPreferenzeOpen] = useState(false)

  const checkButtonDisable = () => {
    const personalData1 = !formik.isValid
    if (!confirmRegistration) {
      return personalData1
    }
    const personalData2 = !!formik.errors?.personalData2 || !formik.touched.personalData2
    return personalData1 && personalData2
  }
  const handleSubmit = () => {
    if (registration && !confirmRegistration) {
      setConfirmRegistration(true)
      formik.setFieldValue('personalData2.registration', true)
      return
    }
    formik.handleSubmit()
  }
  const labelButton = !confirmRegistration
    ? t('Invia la tua richiesta')
    : t('Registrati e invia la richiesta')

  return (
    <WContainer variant="md">
      <div className={styles.container}>
        <div className={styles.menu}>
          <FormStep>
            <FormStep.Content>
              <FormLayout>
                <FormField
                  label={t('Nome*')}
                  status={
                    formik.touched.personalData1?.firstname
                      ? formik.errors.personalData1?.firstname
                        ? 'error'
                        : 'success'
                      : null
                  }
                  errorMessage={formik?.errors?.personalData1?.firstname}
                >
                  <FormInput
                    onBlur={formik.handleBlur('personalData1.firstname')}
                    placeholder={t('Nome')}
                    value={formik.values?.personalData1?.firstname}
                    onChange={formik.handleChange('personalData1.firstname')}
                  />
                </FormField>

                <FormField
                  label={t('Cognome*')}
                  status={
                    formik.touched.personalData1?.lastname
                      ? formik.errors.personalData1?.lastname
                        ? 'error'
                        : 'success'
                      : null
                  }
                  errorMessage={formik?.errors?.personalData1?.lastname}
                >
                  <FormInput
                    onBlur={formik.handleBlur('personalData1.lastname')}
                    placeholder={t('Cognome')}
                    value={formik.values?.personalData1.lastname}
                    onChange={formik.handleChange('personalData1.lastname')}
                  />
                </FormField>

                <FormField
                  label={t('Email*')}
                  status={
                    formik.touched.personalData1?.email
                      ? formik.errors.personalData1?.email
                        ? 'error'
                        : 'success'
                      : null
                  }
                  errorMessage={formik?.errors?.personalData1?.email}
                  className={styles.lgField}
                >
                  <FormInput
                    onBlur={formik.handleBlur('personalData1.email')}
                    placeholder={t('La tua email')}
                    value={formik.values?.personalData1.email}
                    onChange={formik.handleChange('personalData1.email')}
                  />
                </FormField>

                <div className={styles.checkboxRegister}>
                  <FormCheckbox
                    label="Voglio registrarmi a Danone"
                    id="registration"
                    checked={registration}
                    onChange={() => setRegistration(!registration)}
                    // disabled={confirmRegistration}
                  />
                </div>

                {registration && (
                  <>
                    <br />
                    <br />
                    <div className={styles.lgField}>
                      <FormField
                        label={t('Password')}
                        status={
                          formik.touched.personalData2?.password
                            ? formik.errors.personalData2?.password
                              ? 'error'
                              : 'success'
                            : null
                        }
                        errorMessage={formik?.errors?.personalData2?.password}
                        helper={{
                          id: 'password-helper',
                          label: (
                            <>
                              La password deve contenere:
                              <br /> - minimo 8 caratteri
                              <br /> - almeno una lettera maiuscola
                            </>
                          ),
                          pos: 'right',
                        }}
                      >
                        <FormInputPassword
                          togglePassword
                          onBlur={formik.handleBlur('personalData2.password')}
                          placeholder={t('Password')}
                          value={formik.values?.personalData2.password}
                          onChange={formik.handleChange('personalData2.password')}
                        />
                      </FormField>
                    </div>

                    <FormField
                      label={t('Sesso*')}
                      errorMessage={formik.errors.personalData2?.gender}
                      status={formik.errors.personalData2?.gender ? 'error' : null}
                    >
                      <FormSelect
                        isSearchable
                        {...genderProps}
                        onBlur={formik.handleBlur('personalData2.gender')}
                        placeholder={t("Scegli un'opzione")}
                        value={formik.values.personalData2.gender || ''}
                        onChange={(value) =>
                          formik.setFieldValue('personalData2.gender', value?.value)
                        }
                      />
                    </FormField>

                    <FormField
                      label={t('Data di nascita (GG/mm/aaaa)*')}
                      errorMessage={
                        formik.errors.personalData2?.birthday && t('Inserire una data valida')
                      }
                      status={
                        formik.touched.personalData2?.birthday
                          ? formik.errors.personalData2?.birthday
                            ? 'error'
                            : 'success'
                          : null
                      }
                    >
                      <FormDate
                        onBlur={formik.handleBlur('personalData2.birthday')}
                        status={formik.errors.personalData2?.birthday ? 'error' : ''}
                        value={formik.values.personalData2?.birthday}
                        onChange={(value) => formik.setFieldValue('personalData2.birthday', value)}
                      />
                    </FormField>
                  </>
                )}

                <Separator className={styles.separator} />

                <p className={styles.privacyTitle}>{t('Privacy')}</p>
                <FormField
                  status={
                    formik.touched.personalData1?.informativa
                      ? formik.errors.personalData1?.informativa
                        ? 'error'
                        : 'success'
                      : null
                  }
                  errorMessage={formik.errors?.personalData1?.informativa}
                  layout="full"
                >
                  <FormCheckbox
                    id="informativa"
                    checked={formik.values?.personalData1.informativa}
                    onChange={formik.handleChange('personalData1.informativa')}
                    label={<OptinLabel type="informativa-contatto" />}
                  />
                </FormField>

                {registration && (
                  <>
                    <p className={styles.privacyTitle}>
                      {t(
                        'Vuoi ricevere comunicazioni, partecipare a ricerche di mercato e alle communities Danone?'
                      )}
                    </p>
                    <FormField
                      status={
                        formik.touched.personalData2?.optinMarketing
                          ? formik.errors.personalData2?.optinMarketing
                            ? 'error'
                            : 'success'
                          : null
                      }
                      errorMessage={formik.errors?.personalData2?.optinMarketing}
                      layout="full"
                      className={styles.fieldMarketing}
                    >
                      <FormCheckbox
                        id="optinMarketing"
                        checked={formik.values?.personalData2?.optinMarketing}
                        onChange={formik.handleChange('personalData2.optinMarketing')}
                        label={<OptinLabel type="commerciale" />}
                      />
                      <Button
                        label={
                          preferenzeOpen
                            ? t('Nascondi le preferenze di ricontatto dei Brand Danone')
                            : t('Gestisci le tue preferenze di ricontatto dei Brand Danone')
                        }
                        variant="ghost"
                        size="sm"
                        className={styles.preferenzeTrigger}
                        onClick={() => setPreferenzeOpen(!preferenzeOpen)}
                      />
                      {preferenzeOpen && (
                        <div className={styles.preferenzeContainer}>
                          {optins?.brandOptins &&
                            optins?.brandOptins?.map((optinBrand) => (
                              <div className={styles.optinBrandCard} key={optinBrand.name}>
                                <div className={styles.optinNameImageContainer}>
                                  <WImage
                                    alt={optinBrand.name}
                                    src={optinBrand.image}
                                    id={optinBrand.name}
                                  />
                                  <div className={styles.optinBrandName}>
                                    <strong className={styles.name}>{optinBrand.name}</strong>
                                    {optinBrand.informativa && (
                                      <a
                                        href={optinBrand.informativa}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={styles.optinBrandInformativa}
                                      >
                                        {t("Leggi l'informativa Privacy")}
                                      </a>
                                    )}
                                  </div>
                                </div>
                                <FormToggle
                                  id={`optins.${optinBrand.optinName}`}
                                  checked={formik.values.optins?.[optinBrand.optinName]}
                                  onChange={(e) => {
                                    formik.setFieldValue(e.target.id, e.target.checked)
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      )}
                    </FormField>
                    <p className={styles.privacyTitle}>
                      {t(
                        'Vuoi avere un’esperienza personalizzata in basa alle tue abitudini di acquisto?'
                      )}
                    </p>
                    <FormField
                      status={
                        formik.touched.personalData2?.optinProgramMarketing2
                          ? formik.errors.personalData2?.optinProgramMarketing2
                            ? 'error'
                            : 'success'
                          : null
                      }
                      errorMessage={formik.errors?.personalData2?.optinProgramMarketing2}
                      layout="full"
                    >
                      <FormCheckbox
                        id="optinProgramMarketing2"
                        checked={formik.values?.personalData2?.optinProgramMarketing2}
                        onChange={formik.handleChange('personalData2.optinProgramMarketing2')}
                        label={<OptinLabel type="profilazione" />}
                      />
                    </FormField>
                    <FormField
                      status={
                        formik.touched.personalData2?.optinProgramMarketing3
                          ? formik.errors.personalData2?.optinProgramMarketing3
                            ? 'error'
                            : 'success'
                          : null
                      }
                      errorMessage={formik.errors?.personalData2?.optinProgramMarketing3}
                      layout="full"
                    >
                      <FormCheckbox
                        id="optinProgramMarketing3"
                        checked={formik.values?.personalData2?.optinProgramMarketing3}
                        onChange={formik.handleChange('personalData2.optinProgramMarketing3')}
                        label={<OptinLabel type="statistici" />}
                      />
                    </FormField>
                    {/* <FormField
                      status={
                        formik.touched.personalData2?.optinProgramMarketing4
                          ? formik.errors.personalData2?.optinProgramMarketing4
                            ? 'error'
                            : 'success'
                          : null
                      }
                      errorMessage={formik.errors?.personalData2?.optinProgramMarketing4}
                      layout="full"
                    >
                      <FormCheckbox
                        id="optinProgramMarketing4"
                        checked={formik.values?.personalData2?.optinProgramMarketing4}
                        onChange={formik.handleChange('personalData2.optinProgramMarketing4')}
                        label={<OptinLabel type="analisiDatiParticolari" />}
                      />
                    </FormField> */}
                  </>
                )}
              </FormLayout>
            </FormStep.Content>

            <FormStep.Buttons>
              <Button
                label={labelButton}
                disabled={checkButtonDisable() || formik.isSubmitting}
                variant="primary"
                onClick={() => handleSubmit()}
                className={styles.submit}
              />
            </FormStep.Buttons>
          </FormStep>
        </div>
      </div>
    </WContainer>
  )
}

export default DatiPersonali
