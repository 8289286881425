import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import useTrans from 'hooks/useTrans'
import { Button, HeaderBox, WContainer } from 'components/atoms'
import {
  INFORMAZIONI_PRODOTTI,
  RECLAMO,
  PRIVACY_INFO_ACCOUNT,
  RequestType,
  StepProps,
  purposes,
} from '../FormContattaci.interfaces'
import styles from './MotivoRichiesta.module.sass'

const MotivoRichiesta = ({
  registered,
  setSteps,
  setRequestType,
  setPurposes,
  setPurposeArea,
}: StepProps) => {
  const t = useTrans()
  const router = useRouter()

  const nextStep = () => setSteps((prev: number) => prev + 1)

  function caseBase(caso: RequestType, title: string) {
    nextStep()
    setPurposeArea(title)
    setRequestType(caso)
  }

  const case1 = (title: string) => {
    if (router.asPath != '/info-acquisti-online') {
      router.push('/info-acquisti-online?skip=true')
    }
  }
  const case2 = (title: string) => {
    caseBase(RequestType.case2, title)
  }
  const case3 = (title: string) => {
    caseBase(RequestType.case3, title)
  }

  const items = [
    { title: t('Informazioni acquisto online'), onClick: case1 },
    { title: t(INFORMAZIONI_PRODOTTI), onClick: case3 },
    { title: t(RECLAMO), onClick: case3 },
    { title: t(PRIVACY_INFO_ACCOUNT), onClick: case2 },
    { title: t('Promozioni e iniziative'), onClick: case2 },
    { title: t('Informazioni generali'), onClick: case2 },
    { title: t('Complimento e suggerimenti'), onClick: case2 },
  ]

  useEffect(() => {
    if (router.asPath.includes('/info-acquisti-online')) {
      caseBase(RequestType.case1, t('Informazioni acquisto online'))
      setPurposes(purposes[0])
    }
  }, [])

  return (
    <WContainer variant="md">
      <div className={styles.container}>
        <HeaderBox
          title={t('Qual è il motivo della tua richiesta?')}
          classNameHeader={styles.header}
          classNameTitle={styles.title}
        />
        <div className={styles.menu}>
          {items &&
            items.map((item, key) => (
              <Button
                label={item.title}
                onClick={() => {
                  item.onClick(item.title)
                  setPurposes(purposes[key])
                }}
                className={styles.item}
                key={item.title}
              />
            ))}
        </div>
      </div>
    </WContainer>
  )
}

export default MotivoRichiesta
