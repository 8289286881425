import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { useAddFiglioMutation, useUpdateFiglioMutation } from 'gql/graphql'
import { useRouter } from 'next/dist/client/router'
import useTrans from 'hooks/useTrans'
import {
  Button,
  FormDate,
  FormField,
  FormInput,
  FormLayout,
  FormRadio,
  FormCheckbox,
} from 'components/atoms'
import { FormCard } from 'components/molecules'
import styles from './FormBambino.module.sass'

interface Props {
  isEdit?: boolean
  initialValuesFromProps?: any
  setInitialValuesFromProps?: any
  onClose?: () => void
}

const validationSchema = (t) =>
  yup.object().shape({
    nome: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    sesso: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    data_nascita: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

const FormBambino = ({
  isEdit,
  onClose,
  initialValuesFromProps,
  setInitialValuesFromProps,
}: Props) => {
  const [updateFiglio] = useUpdateFiglioMutation()
  const [addFiglio, { loading }] = useAddFiglioMutation()
  const t = useTrans()
  const router = useRouter()

  return (
    <Formik
      validateOnChange
      isInitialValid={false}
      validationSchema={validationSchema(t)}
      onSubmit={(values) => {
        if (!isEdit) {
          addFiglio({
            variables: {
              children: {
                firstName: values?.nome,
                gender: values?.sesso?.toString().toLowerCase(),
                dateOfBirth: dayjs(values?.data_nascita).format('YYYY-MM-DD'),
                isTwin: values?.is_twin,
              },
            },
          })
            .then(() => router.reload())
            .catch((error_) => console.log(error_))
        } else {
          updateFiglio({
            variables: {
              children: {
                id: initialValuesFromProps?.pk,
                firstName: values?.nome,
                gender: values?.sesso?.toString().toLowerCase(),
                dateOfBirth: dayjs(values?.data_nascita).format('YYYY-MM-DD'),
                isTwin: values?.is_twin,
              },
            },
          })
            .then((res) => {
              // ? nel caso dovesse modificare il genderType o la data di nascita si refresha pagina
              // ? per aggiornare il graph e la modal
              if (
                values?.sesso !== initialValuesFromProps?.genderType ||
                dayjs(values?.data_nascita).format('YYYY-MM-DD') !==
                  initialValuesFromProps?.dataNascita
              ) {
                router.reload()
              } else {
                const updatedFiglio = { ...res?.data?.updateFiglio?.children }
                setInitialValuesFromProps({
                  ...updatedFiglio,
                })
                onClose()
              }
            })
            .catch((error_) => console.log(error_))
        }
      }}
      initialValues={
        isEdit
          ? {
              nome: initialValuesFromProps?.firstName,
              sesso: initialValuesFromProps?.genderType,
              data_nascita: initialValuesFromProps?.dataNascita,
              is_twin: initialValuesFromProps?.isTwin,
            }
          : {
              nome: '',
              sesso: undefined,
              data_nascita: dayjs().format('YYYY-MM-DD'),
              is_twin: false,
            }
      }
    >
      {(props) => (
        <FormCard
          title={
            isEdit
              ? `Modifica dati di ${initialValuesFromProps?.firstName}`
              : t('Aggiungi un altro figlio')
          }
          description={isEdit ? '' : t('Raccontaci qualcosa del tuo bambino!')}
          editMode
          className={`${styles.formBambino} ${isEdit ? styles['formBambino--edit'] : ''}`}
        >
          <FormCard.Content>
            <FormLayout>
              <FormField
                label={isEdit ? t('Nome*') : t('Come si chiama/chiamerà?*')}
                status={props.touched?.nome ? (props.errors?.nome ? 'error' : 'success') : null}
                errorMessage={props.errors?.nome}
                layout={isEdit ? 'md' : 'full'}
                className={styles['field--name']}
              >
                <FormInput
                  value={props.values?.nome}
                  onBlur={props.handleBlur('nome')}
                  onChange={props.handleChange('nome')}
                  placeholder={t('Inserisci nome')}
                />
              </FormField>

              {/* In modifica questo campo va dopo */}
              {!isEdit && (
                <FormField
                  label={isEdit ? t('Data di nascita*') : t('Quanto è nato/nascerà?*')}
                  status={
                    props.touched?.data_nascita
                      ? props.errors?.data_nascita
                        ? 'error'
                        : 'success'
                      : null
                  }
                  errorMessage={props.errors?.data_nascita}
                  layout={isEdit ? 'md' : 'full'}
                  className={styles['field--birthdate']}
                >
                  <FormDate
                    status={props.errors?.data_nascita ? 'error' : ''}
                    value={props.values?.data_nascita}
                    onChange={(value) => props.setFieldValue('data_nascita', value)}
                    yearFrom={2000}
                    yearNumber={dayjs().year() - 1998}
                  />
                </FormField>
              )}

              <FormField
                label={isEdit ? t('Sesso*') : t('Il tuo bambino è/sarà*')}
                status={props.touched?.sesso ? (props.errors?.sesso ? 'error' : 'success') : null}
                errorMessage={props.errors?.sesso}
                layout={isEdit ? 'md' : 'full'}
                className={styles['field--gender']}
              >
                <FormRadio
                  id="sesso_del_bambino"
                  value={props.values?.sesso}
                  onChange={props.handleChange('sesso')}
                  options={[
                    { label: t('Maschio'), value: 'm', id: 'maschio' },
                    { label: t('Femmina'), value: 'f', id: 'femmina' },
                    { label: t('Non lo so ancora'), value: 'x', id: 'non lo so' },
                  ]}
                />
              </FormField>

              {isEdit && (
                <FormField
                  label={isEdit ? t('Data di nascita*') : t('Quanto è nato/a?*')}
                  status={
                    props.touched?.data_nascita
                      ? props.errors?.data_nascita
                        ? 'error'
                        : 'success'
                      : null
                  }
                  errorMessage={props.errors?.data_nascita}
                  layout={isEdit ? 'md' : 'full'}
                  className={styles['field--birthdate']}
                >
                  <FormDate
                    status={props.errors?.data_nascita ? 'error' : ''}
                    value={props.values?.data_nascita}
                    onChange={(value) => props.setFieldValue('data_nascita', value)}
                    yearFrom={2000}
                    yearNumber={dayjs().year() - 1998}
                  />
                </FormField>
              )}

              {isEdit && (
                <FormField
                  label={isEdit ? t('Il tuo bambino ha*') : t('Il tuo bambino avrà*')}
                  status={
                    props.touched?.is_twin ? (props.errors?.is_twin ? 'error' : 'success') : null
                  }
                  layout="full"
                >
                  <FormCheckbox
                    id="is_twin_edit"
                    checked={props.values?.is_twin}
                    onChange={(value) => props.setFieldValue('is_twin', !props.values?.is_twin)}
                    label={t('Uno o più gemelli')}
                  />
                </FormField>
              )}
              {!isEdit && (
                <FormField
                  label={isEdit ? t('Il tuo bambino ha*') : t('Il tuo bambino avrà*')}
                  status={
                    props.touched?.is_twin ? (props.errors?.is_twin ? 'error' : 'success') : null
                  }
                  layout="full"
                >
                  <FormCheckbox
                    id="is_twin_add"
                    checked={props.values?.is_twin}
                    onChange={(value) => props.setFieldValue('is_twin', !props.values?.is_twin)}
                    label={t('Uno o più gemelli')}
                  />
                </FormField>
              )}
            </FormLayout>
          </FormCard.Content>
          <FormCard.Buttons>
            <Button label={t('Annulla')} onClick={() => onClose && onClose()} variant="ghost" />
            <Button
              loading={loading}
              type="submit"
              disabled={!props.isValid}
              onClick={() => props.handleSubmit()}
              label={t('Salva modifiche')}
              variant="primary"
            />
          </FormCard.Buttons>
        </FormCard>
      )}
    </Formik>
  )
}

export default FormBambino
