import React, { useState } from 'react'
import useTrans from 'hooks/useTrans'
import {FormCard, MyAccountDatiView} from 'components/molecules'
import { FormEditDatiPersonali } from 'components/organisms'
import styles from './DatiFormCard.module.sass'

interface Props {
  dati?: {
    nome?: string
    cognome?: string
    sesso?: string
    dataNascita?: string
  }
}

const DatiFormCard = (props: Props) => {
  const { dati = null } = props
  const t = useTrans()
  const [initialValuesFromProps, setInitialValuesFromProps] = useState({
    ...dati,
  })
  const [editDati, setEditDati] = useState(false)

  return (
    <div className={`${editDati ? styles['datiFormCard--edit'] : ''}`}>
      <div className={styles.content}>
        {editDati ? (
          <FormEditDatiPersonali
            initialValuesFromProps={{ ...initialValuesFromProps }}
            setInitialValuesFromProps={setInitialValuesFromProps}
            onCancel={() => setEditDati(false)}
          />
        ) : (
          <>
            <FormCard title={t("Dati personali")} editButton={{ onClick: () => setEditDati(true)}}>
              <MyAccountDatiView {...initialValuesFromProps} />
            </FormCard>
          </>
        )}
      </div>
    </div>
  )
}

export default DatiFormCard
