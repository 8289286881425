import React, { useEffect } from 'react'
import { Modal } from '@components/atoms'
import styles from './YoutubeModal.module.sass'
import { Close } from '@components/atoms/Icons'


interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  className?: string
  src: string
}

const YoutubeModal = (props: Props) => {

  const { 
    open = false,
    setOpen = undefined,
    src = '',
    className = '',
  } = props

  useEffect(() => {
    if (open)
      document.body.classList.add('w-noscroll')
    else
      document.body.classList.remove('w-noscroll')
  }, [open])



  const close = (e: any) => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }

  return open ? (
    <>
      <div className={styles.overlay} />
      <div className={`${styles.modal} ${className}`}> 
      <button type="button" className={styles.close} onClick={close}>
        <Close />
      </button>
          <div className={styles.videoContainer}>
            <iframe src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen" allowfullscreen className={styles.video}></iframe>
          </div>
      </div>
    </>
  ) : (
    <></>
  ) 
  // return (
  //   <Modal open={open} setOpen={setOpen} className={`${styles.youtubeModal} ${className}`} scrollable>
  //     <Modal.Content>
  //     <div className={styles.videoContainer}>
  //       <iframe width="100%" height="315" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className={styles.video}></iframe>
  //     </div>
  //     </Modal.Content>
  //   </Modal>
  // )
}

export default YoutubeModal

