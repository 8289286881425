import React from 'react'
import useTrans from '@hooks/useTrans'
import { useBrandsQuery } from '@gql/graphql'
import { BrandButton } from 'components/atoms'
import styles from './BrandCard.module.sass'

interface Props {
  showTitle?: boolean
  classNameContent?: string
}

const BrandCard = (props: Props) => {
  const { showTitle = true, classNameContent = '' } = props

  const t = useTrans()
  const { data } = useBrandsQuery({ fetchPolicy: 'cache-first' })

  const title = t('Gli altri brand di Danone')
  const description = t('Clicca sui loghi per visitare i siti dei brand del gruppo Danone')
  const brands = data?.brands

  const styleContent = `${styles.content} ${classNameContent}`

  return (
    <div className={`${styles.brandCard}`}>
      <div className={styleContent}>
        {showTitle && title && <p className={styles.title}>{title}</p>}
        {showTitle && description && <div className={styles.description}>{description}</div>}

        <div className={styles.brandsContainer}>
          <ul className={styles.brandsWrapper}>
            {brands?.map((item) => (
              <li key={item.pk}>
                <BrandButton item={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BrandCard
