import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function GestisciPreferenze({ className }: Props) {
  return (
    <IconBase name="gestisci-preferenze" w={51} h={52} className={className}>
      <rect width="50" height="50" rx="50" fill="currentColor" />
      <g transform="translate(0 -12)">
        <path
          d="M25.5 32C25.5 31.45 25.05 31 24.5 31H19.32L19.98 27.82L20 27.59C20 27.28 19.87 27 19.67 26.79L18.88 26L13.94 30.94C13.67 31.21 13.5 31.59 13.5 32V38.5C13.5 39.33 14.17 40 15 40H21.75C22.37 40 22.9 39.62 23.13 39.09L25.39 33.8C25.46 33.63 25.5 33.44 25.5 33.25V32ZM36 36H29.25C28.63 36 28.1 36.38 27.87 36.91L25.61 42.2C25.54 42.37 25.5 42.56 25.5 42.75V44C25.5 44.55 25.95 45 26.5 45H31.68L31.02 48.18L31 48.42C31 48.73 31.13 49.01 31.33 49.22L32.12 50L37.06 45.06C37.33 44.79 37.5 44.41 37.5 44V37.5C37.5 36.67 36.83 36 36 36Z"
          fill="white"
        />
      </g>
    </IconBase>
  )
}

export default GestisciPreferenze
