import React, { useEffect, useState } from 'react'
import { useUtente } from 'hooks'

interface Props {
  contentTypeId: number
  objectId: number
  pkey: string
  serverSideContent?: any
  size?: '' | 'xs'
}

const PlaceholderReact = (props: Props) => {
  return null
}

export default PlaceholderReact
