import React, { useState } from 'react'
import { FiglioCard } from 'components/molecules'
import { FormBambino } from 'components/organisms'
import styles from './MyAccountFiglioFormCard.module.sass'

interface Props {
  onDeleteFiglio: (id: number) => void
  dati?: {
    firstName: string
    genderType: 'f' | 'm' | 'x'
    dataNascita: string
    pk?: number
    isTwin: boolean
  }
}

const MyAccountFiglioFormCard = (props: Props) => {
  const { dati = null, onDeleteFiglio } = props
  const [initialValuesFromProps, setInitialValuesFromProps] = useState({ ...dati })
  const [edit, setEdit] = useState(false)

  return (
    <div className={styles.myAccountFiglioFormCard}>
      {edit ? (
        <div className={styles.editForm}>
          <FormBambino
            setInitialValuesFromProps={setInitialValuesFromProps}
            initialValuesFromProps={initialValuesFromProps}
            isEdit
            onClose={() => setEdit(false)}
          />
        </div>
      ) : (
        <FiglioCard
          dati={initialValuesFromProps}
          isFiglioProfile
          onEdit={() => setEdit(true)}
          onDeleteFiglio={onDeleteFiglio}
        />
      )}
    </div>
  )
}

export default MyAccountFiglioFormCard
