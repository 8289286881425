import React, { useState } from 'react'
import { useRouter } from 'next/router'
import useTrans from 'hooks/useTrans'
import { StepProps } from '../FormContattaci.interfaces'
import {
  PRIVACY_INFO_ACCOUNT,
  RequestType,
} from '@components/organisms/FormContattaci/FormContattaci.interfaces'
import {
  FormField,
  FormInput,
  FormLayout,
  FormRadio,
  FormSelect,
  FormStep,
  FormTextarea,
} from 'components/atoms'
import styles from './Case2.module.sass'
import FormFile from '../../../../atoms/FormFile/FormFile'
import { useBrandsQuery } from '@gql/graphql'

const Case2 = ({ formik, requestType, purposeArea }: StepProps) => {
  const t = useTrans()
  const router = useRouter()

  const { data } = useBrandsQuery({ fetchPolicy: 'cache-first' })
  const brands = data?.brands.map((item) => ({ value: item.name, label: item.name }))

  const contactBy =
    formik?.values?.case2.contact === 'phone'
      ? t('Inserisci il tuo numero di telefono')
      : t('Inserisci la tua email')
  const brand = purposeArea === PRIVACY_INFO_ACCOUNT ? t('Sito di registrazione') : t('Brand*')
  const brandPlaceholder =
    purposeArea === PRIVACY_INFO_ACCOUNT
      ? t('Scegli il sito di registrazione')
      : t('Scegli il brand')

  const handleFileChange = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onloadend = () => {
      const base64String = reader.result
      e.target.files[0].base64String = base64String
      e.target.files[0].filename = e.target.files[0].name
      formik.setFieldValue('case2.file', e.target.files[0])
    }

    reader.readAsDataURL(file)
  }

  return (
    <FormStep>
      <FormStep.Content>
        <FormLayout className={styles.formLayout}>
          <FormField
            label={t('Descrivi la tua richiesta*')}
            status={
              formik.touched.case2?.request
                ? formik.errors.case2?.request
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.case2?.request}
            className={styles.formField}
          >
            <FormTextarea
              onBlur={formik.handleBlur('case2.request')}
              placeholder={t('La tua richiesta')}
              value={formik.values?.case2.request}
              onChange={formik.handleChange('case2.request')}
              rows={6}
            />
          </FormField>

          {requestType === RequestType.case2 && (
            <FormField
              label={brand}
              errorMessage={formik.errors.case2?.brand}
              status={formik.errors.case2?.brand ? 'error' : null}
              className={styles.formField}
            >
              <FormSelect
                options={brands}
                placeholder={brandPlaceholder}
                value={formik.values?.case2.brand}
                onBlur={formik.handleBlur('case2.brand')}
                onChange={(v) => formik.setFieldValue('case2.brand', v.value)}
              />
            </FormField>
          )}

          <FormField label={t('Carica un file')} className={styles.formField}>
            <FormFile
              placeholder={
                formik.values?.case2.file
                  ? formik.values?.case2.file.name
                  : t('Nessun file selezionato')
              }
              id="file"
              name="file"
              showButtonLoad
              showAlert
              onChange={handleFileChange}
              errorMessage={formik.errors.case2?.file}
              status={formik.errors.case2?.file ? 'error' : null}
            />
          </FormField>

          <FormField
            label={t('Come vuoi essere ricontattato?*')}
            className={styles.formField}
            layout="full"
          >
            <div className={styles.contactField}>
              <FormRadio
                id="contact"
                value={formik.values?.case2.contact}
                onChange={formik.handleChange('case2.contact')}
                callback={() => {
                  formik.setFieldValue('case2.contactBy', '')
                }}
                options={[
                  {
                    id: 'email',
                    label: t('Email'),
                    value: t('email'),
                  },
                  {
                    id: 'phone',
                    label: t('Telefono'),
                    value: t('phone'),
                  },
                ]}
                classNameOption={styles.formRadio}
              />
              <FormInput
                onBlur={formik.handleBlur('case2.contactBy')}
                placeholder={contactBy}
                value={formik.values?.case2?.contactBy}
                onChange={formik.handleChange('case2.contactBy')}
                className={`${styles.inputContact} ${
                  formik.values?.case2.contact === 'phone' ? styles[`inputContact--phone`] : ''
                }`}
              />
            </div>
          </FormField>
        </FormLayout>
      </FormStep.Content>
    </FormStep>
  )
}

export default Case2
