import React from 'react'
import { BreadcrumbType } from 'utils/types'
import { Breadcrumbs, WContainer, WImage } from '@components/atoms'
import styles from './LandingProdottoHead.module.sass'

interface Props {
  breadcrumbs?: BreadcrumbType[]
  image?: string
  imageMobile?: string
  title?: string
  description?: string
  variant?: '' | 'latti' | 'bio'
  children?: React.ReactNode
  className?: string
}

const LandingProdottoHead = (props: Props) => {
  const {
    breadcrumbs = [],
    image = '',
    imageMobile = '',
    title = '',
    description = '',
    variant = '',
    children = undefined,
    className = '',
  } = props

  return (
    <div className={`${styles.landingProdottoHead} ${variant ? styles[`landingProdottoHead--${variant}`] : ''} ${className}`}>
      <div className={styles.shape}>
        <div className={`${styles.imageBox} ${styles['imageBox--desktop']}`}>
          <WImage
            src={image}
            maxWidth={1920}
            maxHeight={514}
            layout="fill"
            objectFit="cover"
            priority
            loading="eager"
          />
        </div>
        <div className={`${styles.imageBox} ${styles['imageBox--mobile']}`}>
          <WImage
            src={imageMobile || image}
            maxWidth={767}
            maxHeight={215}
            layout="fill"
            objectFit="cover"
            priority
            loading="eager"
          />
        </div>
      </div>
      <WContainer>
        {breadcrumbs?.length > 0 && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        <div className={styles.content}>
          {!!title && <h1 className={styles.title}>{title}</h1>}
          {!!description && (
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {children}
        </div>
      </WContainer>
    </div>
  )
}

export default LandingProdottoHead
