import React from 'react'
import useTrans from 'hooks/useTrans'
import { useBrandsQuery } from '@gql/graphql'
import { StepProps } from '../FormContattaci.interfaces'
import {
  FormDate,
  FormField,
  FormInput,
  FormLayout,
  FormSelect,
  FormStep,
  WImage,
} from 'components/atoms'
import styles from './Case3.module.sass'
import { isReclamoOrInfoProdotti } from 'utils'

const Case3 = ({ formik, purposeArea }: StepProps) => {
  const t = useTrans()

  const { data } = useBrandsQuery({ fetchPolicy: 'cache-first' })
  const brands = data?.brands.map((item) => ({ value: item.name, label: item.name }))

  return (
    <FormStep>
      <FormStep.Content>
        <FormLayout>
          <FormField
            label={isReclamoOrInfoProdotti(purposeArea) ? t('Codice ean') : t('Codice ean*')}
            status={
              formik.touched.case3?.ean ? (formik.errors.case3?.ean ? 'error' : 'success') : null
            }
            errorMessage={formik?.errors?.case3?.ean}
            className={styles.formField}
            helper={{
              id: 'ean-helper',
              label: (
                <>
                  {t(
                    'Riportare le cifre numeriche che si leggono sotto il codice a barre. Senza lasciare spazi'
                  )}
                  <WImage
                    src="/images/tooltip/ean.png"
                    maxWidth={200}
                    maxHeight={138}
                    layout="intrinsic"
                    objectFit="cover"
                    loading="lazy"
                    className={styles.tooltipImage}
                  />
                </>
              ),
              pos: 'bottom',
            }}
          >
            <FormInput
              onBlur={formik.handleBlur('case3.ean')}
              placeholder={t('Codice EAN')}
              value={formik.values?.case3?.ean}
              onChange={formik.handleChange('case3.ean')}
            />
          </FormField>

          <FormField
            label={isReclamoOrInfoProdotti(purposeArea) ? t('Lotto') : t('Lotto*')}
            status={
              formik.touched.case3?.lotto
                ? formik.errors.case3?.lotto
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.case3?.lotto}
            className={styles.formField}
            helper={{
              id: 'lotto-helper',
              label: (
                <>
                  {t(
                    'Riportare le cifre numeriche che si leggono subito dopo la data di scadenza. Indicare tutte le cifre, anche l’ora rispettando gli spazi vuoti.'
                  )}
                  <WImage
                    src="/images/tooltip/lotto.png"
                    maxWidth={200}
                    maxHeight={87}
                    layout="intrinsic"
                    objectFit="cover"
                    loading="lazy"
                    className={styles.tooltipImage}
                  />
                </>
              ),
              pos: 'bottom',
            }}
          >
            <FormInput
              onBlur={formik.handleBlur('case3.lotto')}
              placeholder={t('Lotto')}
              value={formik.values?.case3?.lotto}
              onChange={formik.handleChange('case3.lotto')}
            />
          </FormField>

          <FormField
            label={t('Brand*')}
            errorMessage={formik.errors.case3?.brand}
            status={formik.errors.case3?.brand ? 'error' : null}
            className={styles.formField}
          >
            <FormSelect
              options={brands}
              placeholder={t('Scegli il brand')}
              value={formik.values?.case3.brand}
              onBlur={formik.handleBlur('case3.brand')}
              onChange={(v) => formik.setFieldValue('case3.brand', v.value)}
            />
          </FormField>

          <FormField
            label={isReclamoOrInfoProdotti(purposeArea) ? t('Nome prodotto') : t('Nome prodotto*')}
            status={
              formik.touched.case3?.productName
                ? formik.errors.case3?.productName
                  ? 'error'
                  : 'success'
                : null
            }
            errorMessage={formik?.errors?.case3?.productName}
            className={styles.formField}
          >
            <FormInput
              onBlur={formik.handleBlur('case3.productName')}
              placeholder={t('Nome prodotto')}
              value={formik.values?.case3.productName}
              onChange={formik.handleChange('case3.productName')}
            />
          </FormField>

          <FormField
            label={t('Data di scadenza (gg/mm/aaaa)')}
            errorMessage={formik.errors?.expiryDate && t('Inserire una data valida')}
            status={
              formik.touched?.expiryDate ? (formik.errors?.expiryDate ? 'error' : 'success') : null
            }
          >
            <FormDate
              status={formik.errors?.expiryDate ? 'error' : ''}
              value={formik.values?.case3.expiryDate}
              onChange={(value) => formik.setFieldValue('case3.expiryDate', value)}
              yearFrom={new Date().getFullYear() - 5}
              yearNumber={new Date().getFullYear() + 11 - (new Date().getFullYear() - 5)}
            />
          </FormField>
        </FormLayout>
      </FormStep.Content>
    </FormStep>
  )
}

export default Case3
