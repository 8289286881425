import React from 'react'
import styles from './SliderMiglioriProdotti.module.sass'
import { MenuItemType } from 'utils/types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { WImage, Button } from 'components/atoms'
import { ProdottoCard } from '@components/molecules'
import { useRouter } from 'next/router'
import useTrans from '@hooks/useTrans'

interface Props {
  item: MenuItemType
  size?: 'default' | 'big' | 'sm'
  className?: string
}

const SliderMiglioriProdotti = (props: Props) => {
  const { className = '', item = undefined } = props
  const t = useTrans()
  const router = useRouter()

  return !!item ? (
    <div className={`${styles.sliderMiglioriProdotti} ${className}`}>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title}>{item.name}</p>}
        {!!item.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </div>

      <Swiper
        spaceBetween={16}
        slidesPerView={2.3}
        pagination={{ clickable: true }}
        watchOverflow
        breakpoints={{
          768: {
            slidesPerView: 5,
          },
        }}
      >
        {item.children?.length > 0 &&
          item.children.map((x) => (
            <SwiperSlide key={x.pk}>
              <ProdottoCard
                key={x.pk}
                prodotto={{
                  pk: x.pk,
                  titolo: x.name,
                  descrizione: x.description,
                  mainImage: x.image,
                  link: `${x.url}?utm_source=mellin.it&utm_medium=referral&utm_campaign=consigliati-["${router.pathname}"]`,
                  blank: x.blank,
                }}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      {!!item.url && (
        <div className={styles.ctaBox}>
          <Button
            label={item.ctaLabel ? item.ctaLabel : t('Vai allo shop online')}
            size="sm"
            className={styles.cta}
            href={`${item.url}?utm_source=mellin.it&utm_medium=referral&utm_campaign=consigliati-["${router.pathname}"]`}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}
export default SliderMiglioriProdotti
