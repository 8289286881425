import { ArticoloCard, GenericCard } from '@components/molecules'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MenuItemType } from 'utils/types'
import styles from './StrumentiLinkUtili.module.sass'

interface Props {
  item: MenuItemType
  className?: string
}

const StrumentiLinkUtili = (props: Props) => {
  const { item = undefined, className = '' } = props

  return !!item ? (
    <div className={`${styles.strumentiLinkUtili} ${className}`}>
      {!!item.name && <p className={styles.title}>{item.name}</p>}
      <div className={styles.layout}>
        {item.children?.length > 0 && (
          <div className={styles.strumenti}>
            <GenericCard className="shadow--cta-primary" key={item.children[0].pk} item={item.children[0]} />
          </div>
        )}
        <div className={styles.linksDesktop}>
          {item.children?.length == 3 &&
            item.children
              .slice(1, 3)
              .map((x, index) => (
                <div className={styles.linksDesktopItem} key={x.pk}>
                  <ArticoloCard key={x.pk} articolo={x.articolo} variant="horizontal" className="shadow"/>
                </div>
              ))}
        </div>
        <div className={styles.linksMobile}>
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            pagination={{ clickable: true }}
            watchOverflow
            breakpoints={{
              320: {
                slidesPerView: 1.3,
              },
              480: {
                slidesPerView: 2,
              },
            }}
          >
            {item.children?.length == 3 &&
              item.children.slice(1, 3).map((x, index) => (
                <SwiperSlide key={x.pk}>
                  <ArticoloCard key={x.pk} articolo={x.articolo} variant="horizontal" className="shadow"/>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default StrumentiLinkUtili
