import React from 'react'
import { ContestType } from 'utils/types'
import { WImage, Link } from '@components/atoms'
import styles from './EventoCard.module.sass'

interface Props {
  contest: ContestType
  className?: string
  priority?: boolean
}

const EventoCard = (props: Props) => {
  const { contest = {}, className = '', priority = false } = props

  return !!contest ? (
    <div className={`${styles.eventoCard} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={contest.image}
          maxWidth={494}
          maxHeight={286}
          layout="intrinsic"
          objectFit="cover"
          quality={100}
          priority={priority}
          loading={priority ? 'eager' : 'lazy'}
        />
      </div>
      <div className={styles.content}>
        {!!contest.title && <p className={styles.title}>{contest.title}</p>}
        {!!contest.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: contest.description }}
          />
        )}
        {!!contest.ctaLabel && (
          <Link
            label={contest.ctaLabel}
            href={contest.fileUrl ? contest.fileUrl : contest.externalLink}
            decoration
            newPage
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default EventoCard
