import React from 'react'
import { WImage } from '@components/atoms'
import styles from './QuoteBox.module.sass'


interface Props {
  titolo?: string
  descrizione?: string | React.ReactNode // nel PageBuilder è un TextEditor
  showImage?: boolean
  image?: string
  alt?: string
  className?: string
  editMode?: string
}

const QuoteBox = (props: Props) => {

  const { 
    titolo = '',
    descrizione = '',
    showImage = '',
    image = '',
    alt = '',
    className = '',
    editMode = false,
  } = props

  return (
    <div className={`${styles.quoteBox} ${className}`}>
      <div className={styles.content}>
        {!!titolo && (
          editMode || typeof titolo !== 'string'
          ? <div className={styles.title}>{titolo}</div>
          : <p className={styles.title}>{titolo}</p>
        )}
        {!!descrizione && (
          editMode || typeof descrizione !== 'string'
          ? <div className={styles.description}>{descrizione}</div>
          : <div className={styles.description} dangerouslySetInnerHTML={{ __html: descrizione}} />
        )}
      </div>
      {showImage && (
        <div className={styles.imageBox}>
          <WImage 
            src={image}
            maxWidth={767}
            maxHeight={230}
            layout="intrinsic"
            objectFit="cover"
            alt={alt}
            title={alt}
            key={image} // Serve perchè altrimenti non cambia immagine in editMode nel Pagebuilder
          />
        </div>
      )}
    </div>
  )
}

export default QuoteBox
