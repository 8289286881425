import React, { useState } from 'react'
import useTrans from '@hooks/useTrans'
import useUtente from '@hooks/useUtente'
import { RequestType } from '@components/organisms/FormContattaci/FormContattaci.interfaces'
import { Button, HeaderBox, LoaderOverlay, StepBar, WContainer } from 'components/atoms'
import { FormLogin } from '@components/organisms'
import { AskRegistrationCard } from '@components/molecules'
import { AltreInformazioni, DatiRichiesta, MotivoRichiesta } from './steps/index'
import styles from './FormContattaci.module.sass'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

const RenderSteps = ({
  registered,
  steps,
  setSteps,
  requestType,
  setRequestType,
  purposes,
  setPurposes,
  purposeArea,
  setPurposeArea,
}) => {
  switch (steps) {
    case 0:
      return (
        <MotivoRichiesta
          registered={registered}
          setSteps={setSteps}
          setRequestType={setRequestType}
          setPurposes={setPurposes}
          setPurposeArea={setPurposeArea}
        />
      )
    case 1:
      return requestType === RequestType.case1 ? (
        <AltreInformazioni
          registered={registered}
          setSteps={setSteps}
          setRequestType={setRequestType}
        />
      ) : (
        <DatiRichiesta
          registered={registered}
          requestType={requestType}
          purposes={purposes}
          purposeArea={purposeArea}
          setSteps={setSteps}
        />
      )
    default:
      return (
        <DatiRichiesta
          registered={registered}
          requestType={requestType}
          purposes={purposes}
          purposeArea={purposeArea}
          setSteps={setSteps}
        />
      )
  }
}

const FormContattaci = ({ initialStep }: { initialStep?: number | any }) => {
  const t = useTrans()
  const { utente } = useSelector((state) => state.utente)
  const nextPage = '/contatti'

  const [steps, setSteps] = useState(initialStep || 0)
  const [openLogin, setOpenLogin] = useState(false)
  const [noAccount, setNoAccount] = useState(false)
  const [requestType, setRequestType] = useState<RequestType>()
  const [purposes, setPurposes] = useState([])
  const [purposeArea, setPurposeArea] = useState('')
  const router = useRouter()

  const stepsItems = [
    t('Motivo della richiesta'),
    ...(requestType === RequestType.case1 ? [t('Altre informazioni')] : []),
    t('Dati della richiesta'),
    ...(!utente ? [t('Dati personali')] : []),
  ]

  return !utente &&
    !noAccount &&
    (router.query.skip === 'false' || typeof router.query.skip === 'undefined') ? (
    <>
      <div className={styles.desktop}>
        <WContainer variant="xs">
          <div className={styles.container}>
            <HeaderBox
              title={t('Ho un account Danone')}
              classNameHeader={styles.header}
              classNameTitle={styles.title}
            />
            <FormLogin nextPage={nextPage} from="/contatti" />
          </div>
        </WContainer>
        <AskRegistrationCard onClick={() => setNoAccount(true)} />
      </div>
      <div className={styles.mobile}>
        {openLogin ? (
          <FormLogin nextPage={nextPage} from="/contatti" />
        ) : (
          <Button
            label="Accedi a Danone"
            onClick={() => setOpenLogin(true)}
            className={styles.submit}
          />
        )}
        <AskRegistrationCard
          className={styles.mobileButtons}
          showButton={!openLogin}
          onClick={() => setNoAccount(true)}
        />
      </div>
    </>
  ) : (
    <div className={styles.steps}>
      <StepBar activeStepIndex={steps + 1} steps={stepsItems} className={styles.stepbar} />
      <div className={styles.box}>
        <RenderSteps
          registered={Boolean(utente)}
          steps={steps}
          setSteps={setSteps}
          requestType={requestType}
          setRequestType={setRequestType}
          purposes={purposes}
          setPurposes={setPurposes}
          purposeArea={purposeArea}
          setPurposeArea={setPurposeArea}
        />
      </div>
    </div>
  )
}

export default FormContattaci
