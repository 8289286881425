import React, { useEffect, useState } from 'react'
import { Formik, useFormikContext } from 'formik'
import { useUnsubscribeMutation } from 'gql/graphql'
import useTrans from 'hooks/useTrans'
import { useRouter } from 'next/router'
import { Image } from 'react-bootstrap'
import { Button, FormField, FormLayout, FormCheckbox } from 'components/atoms'
import { FormCard } from 'components/molecules'
import { ModalFeedback } from 'components/organisms'
import styles from './FormUnsubs.module.sass'
import Link from 'next/link'

const CheckFormValues = () => {
  const { values, submitForm } = useFormikContext()
  const excluded_keys = ['unsubAll']

  useEffect(() => {
    if (values?.unsubAll) {
      Object.keys(values).some((key) => {
        if (!excluded_keys.includes(key)) {
          values[key] = true
        }
      })
    }
  }, [values?.unsubAll, submitForm])

  return null
}

const FormUnsubs = ({ unsubs, token, edit, setEdit }: any) => {
  const t = useTrans()
  const router = useRouter()

  const [updateUnsubs, { loading }] = useUnsubscribeMutation()
  const [modalShowConfirmUpdate, setModalShowConfirmUpdate] = useState(false)
  const [modalShowErrorUpdate, setModalShowErrorUpdate] = useState(false)

  let unsubsValues = {}
  if (unsubs) {
    for (const item of unsubs) {
      unsubsValues[item.optinName] = item.value
    }
  }

  return (
    <Formik
      isInitialValid={false}
      onSubmit={(values) => {
        let v = []
        if (!values?.unsubAll) {
          for (const i in values) {
            if (i !== 'unsubAll' && values?.[i]) {
              v.push(i)
            }
          }
        }
        updateUnsubs({
          variables: {
            input: {
              token: token,
              preferences: v,
            },
          },
        })
          .then((res) => {
            if (res?.data?.unsubscribe?.content?.status_code === 200) {
              setModalShowConfirmUpdate(true)
              return
            }
            setModalShowErrorUpdate(true)
          })
          .catch((error) => console.log(error))
      }}
      initialValues={{
        ...unsubsValues,
        unsubAll: false,
      }}
      enableReinitialize
    >
      {(props) => (
        <FormCard editMode={edit} editButton={edit ? null : { onClick: () => setEdit(true) }}>
          <FormCard.Content>
            <FormLayout>
              <div className={styles.containerOptins}>
                {unsubs &&
                  unsubs?.map((item) => (
                    <div className={styles.optinBrandCard}>
                      <div className={styles.optinNameImageContainer}>
                        <Image alt={item.name} src={item.image} id={item.name} />
                        <strong className={styles.name}>{item.name}</strong>
                      </div>
                      <FormCheckbox
                        id={item.optinName}
                        checked={props.values?.[item.optinName]}
                        onChange={(e) =>
                          props.setFieldValue(e.target.id, !props.values?.[e.target.id])
                        }
                      />
                    </div>
                  ))}
              </div>

              <div className={styles.container}>
                <FormField className={styles.formField}>
                  <FormCheckbox
                    id="unsubAll"
                    wrapperClassName={styles.reverse}
                    checkClassName={styles.checkbox}
                    labelClassName={styles.label}
                    label={t('Non voglio più ricevere comunicazioni da tutti i brand Danone')}
                    checked={props.values?.unsubAll}
                    onChange={(e) => props.setFieldValue('unsubAll', !props.values?.unsubAll)}
                  />
                </FormField>
              </div>
            </FormLayout>
          </FormCard.Content>

          <div className={styles.subDescription}>
            <p>Vuoi gestire tutte le tue preferenze di ricontatto e la tua privacy?</p>
            <p className={styles.links}>
              <Link legacyBehavior href="/login">
                Accedi
              </Link>
            </p>
          </div>

          {edit && (
            <div className={styles.buttonsDesktop}>
              <FormCard.Buttons className={styles.buttons}>
                <Button
                  className={styles.button}
                  loading={loading}
                  type="submit"
                  disabled={!props.isValid}
                  onClick={() => props.handleSubmit()}
                  label={t('Disiscrivimi')}
                  variant="primary"
                />
              </FormCard.Buttons>
            </div>
          )}

          {edit && !props.isValid && (
            <div className={styles.buttonsMobile}>
              <FormCard.Buttons className={styles.buttons}>
                <Button
                  className={styles.button}
                  loading={loading}
                  type="submit"
                  disabled={!props.isValid}
                  onClick={() => props.handleSubmit()}
                  label={t('Disiscrivimi')}
                  variant="primary"
                />
              </FormCard.Buttons>
            </div>
          )}

          {edit && props.isValid && (
            <div className={styles.sticky}>
              <FormCard.Buttons className={styles.buttons}>
                <Button
                  className={styles.button}
                  loading={loading}
                  type="submit"
                  disabled={!props.isValid}
                  onClick={() => props.handleSubmit()}
                  label={t('Disiscrivimi')}
                  variant="primary"
                />
              </FormCard.Buttons>
            </div>
          )}

          <CheckFormValues />

          <ModalFeedback
            open={modalShowConfirmUpdate}
            setOpen={setModalShowConfirmUpdate}
            onClose={() => router.push('/')}
            title={t('Le tua richiesta di disicrizione è avvenuta con successo')}
            description={t(
              'Le modifiche alle tue preferenze sono state registrate e diventeranno effetive in 24 ore'
            )}
            ctaLabel={t('Torna alla home')}
          />

          <ModalFeedback
            open={modalShowErrorUpdate}
            setOpen={setModalShowErrorUpdate}
            onClose={() => router.push('/')}
            title={t('Si è verificato un errore durante la tua richiesta di disiscrizione.')}
            description={t('Il token di disiscrizione non è valido')}
            ctaLabel={t('Torna alla home')}
            showIcon={false}
          />
        </FormCard>
      )}
    </Formik>
  )
}

export default FormUnsubs
