import React from 'react'
import { MenuItemType } from 'utils/types'
import { Button } from 'components/atoms'
import { getIcon } from 'utils/icons'
import { useTrans } from 'hooks'
import styles from './GenericCard.module.sass'

// TODO: fare variante "small" uguale alla mobile perchè nel menu desktop è usata la "Card mobile"

interface Props {
  item: MenuItemType
  variant?: 'white' | 'neutral'
  size?: 'default' | 'sm'
  iconSize?: 'default' | 'sm'
  className?: string
}

const GenericCard = (props: Props) => {
  const {
    item = undefined,
    className = '',
    variant = 'neutral',
    size = 'sm',
    iconSize = 'sm',
  } = props

  const classNames = `
    ${styles.genericCard} 
    ${variant ? styles[`genericCard--${variant}`] : ''}
    ${size ? styles[`genericCard--${size}`] : ''}
    ${iconSize ? styles[`genericCard--icon-${iconSize}`] : ''}
    ${className ? styles[`genericCard--${className}`] : ''}`
  
  const t = useTrans()

  return !!item ? (
    <div className={classNames}>
      <span className={styles.icon}>{getIcon(item.style)}</span>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title}>{item.name}</p>}
        {!!item.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
        {!!item.url && !!item.ctaLabel? (
          <div className={styles.ctaBox}>
            <Button
              variant={className === "shadow--cta-primary"? "primary": "secondary"}
              label={item.ctaLabel}
              size="sm"
              className={styles.cta}
              href={item.url}
            />
          </div>
        ) : (
          <div className={styles.list}>
            {item.children?.length > 0 &&
              item.children.map((x) => (
                <div className={styles.ctaBox} key={x.pk}>
                  <Button
                    variant={x.style}
                    label={x.name}
                    size="sm"
                    className={styles.cta}
                    href={x.url}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default GenericCard
