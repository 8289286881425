import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useGetFigliUtenteQuery } from 'gql/graphql'
import {
  Ciuccio,
  Dashboard,
  Hourglass,
  Logout,
  Mail,
  Plus,
  Profile,
  Locked,
} from 'components/atoms/Icons'
import styles from './MyAccountMenu.module.sass'
import useTrans from '@hooks/useTrans'
import { Button } from '@components/atoms'

interface ItemProps {
  icon?: any
  iconRight?: any
  label?: string
  active?: boolean
}

interface SlideProps {
  icon?: any
  iconRight?: any
  label?: string
  link?: string
  active?: boolean
  onClick?: () => void
}

interface Props {
  className?: string
  activeItemKey: string
}

const menuItemsFixed = [
  {
    key: 'profile',
    icon: <Profile />,
    label: 'I tuoi dati',
    link: '/dashboard/informazioni-personali',
  },
  { key: 'privacy', icon: <Locked />, label: 'Preferenze', link: '/dashboard/preferenze' },
]

const MyAccountMenuItem = (props: ItemProps) => {
  const { icon = null, iconRight = null, label = '', active = false } = props

  return (
    <div
      className={`${styles.myAccountMenuItem} ${active ? styles[`myAccountMenuItem--active`] : ''}`}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && <span className={styles.label}>{label}</span>}
      {iconRight && <span className={styles.iconRight}>{iconRight}</span>}
    </div>
  )
}

const MyAccountMenuItemSlide = ({ icon, iconRight, label, link, active, onClick }: SlideProps) =>
  active ? (
    <MyAccountMenuItem icon={icon} iconRight={iconRight} label={label} active />
  ) : (
    <Link legacyBehavior href={link}>
      <a className={styles.item} onClick={onClick}>
        <MyAccountMenuItem icon={icon} iconRight={iconRight} label={label} />
      </a>
    </Link>
  )

const MyAccountMenu = (props: Props) => {
  const { className = '', activeItemKey = '' } = props
  const [menuItems, setMenuItems] = useState(menuItemsFixed)
  const { data, loading, error } = useGetFigliUtenteQuery()

  const t = useTrans()

  useEffect(() => {
    data && !loading && setMenuItems(() => [...menuItemsFixed])
  }, [data, loading])

  return menuItemsFixed.length > 0 ? (
    <div className={`${styles.myAccountMenu} ${className}`}>
      <div className={styles.list}>
        <div className={styles.block}>
          <div className={styles.blockList}>
            {menuItemsFixed.map((item) => (
              <MyAccountMenuItemSlide
                key={item.key}
                {...item}
                active={item.key === activeItemKey}
              />
            ))}
          </div>
        </div>
        <div className={styles.blockEnd}>
          <div className={styles.blockList}>
            <div className={styles.logout}>
              <MyAccountMenuItemSlide label={t('Esci')} icon={<Logout />} link="/logout" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default MyAccountMenu
