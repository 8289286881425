import React from 'react'
import { Modal } from 'components/atoms'
import { FormBambino } from '..'

interface Props {
  open: boolean
  setOpen: (e: boolean) => void
}

const ModalAggiungiFiglio = ({ open, setOpen }: Props) => (
  <Modal open={open} setOpen={setOpen}>
    <FormBambino onClose={() => setOpen(false)} />
  </Modal>
)

export default ModalAggiungiFiglio
