import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { FormPrivacy } from 'components/organisms'
import styles from './MyAccountPrivacyFormCard.module.sass'

interface Props {
  dati?: {
    privacy?: boolean
    privacyCommerciale?: boolean
    privacyProfilazione?: boolean
    privacyInformativa?: boolean
  }
}

const MyAccountPrivacyFormCard = (props: Props) => {
  const { dati = null } = props
  const [initialValuesFromProps, setInitialValuesFromProps] = useState({ ...dati })
  const t = useTrans()
  const [edit, setEdit] = useState(false)

  return (
    <div className={styles.myAccountPrivacyFormCard}>
      <FormPrivacy
        setInitialValuesFromProps={setInitialValuesFromProps}
        initialValuesFromProps={{
          ...initialValuesFromProps,
        }}
        edit={edit}
        setEdit={(v) => setEdit(v)}
      />
    </div>
  )
}

export default MyAccountPrivacyFormCard
