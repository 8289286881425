import React from 'react'
import { Props as ButtonProps } from 'components/atoms/Button/Button'
import { Props as HelpProps } from 'components/atoms/Help/Help'
import { Locked, Plus } from 'components/atoms/Icons'
import { Button, Help } from 'components/atoms'
import styles from './MyAccountSection.module.sass'

interface Props {
  title?: string
  description?: string
  button?: ButtonProps
  help?: HelpProps
  locked?: boolean
  lockedLabel?: string
  className?: string
  children?: any
}

const MyAccountSection = (props: Props) => {

  const { 
    title = '',
    description = '',
    button = null,
    help = null,
    locked = false,
    lockedLabel = '',
    className = '',
    children = null
  } = props

  return (
    <div className={`${styles.myAccountSection} ${locked ? styles['myAccountSection--locked'] : ''} ${className}`}>
      {(title || button) && (
        <div className={styles.head}>
          {title && <p className={styles.title}>
            {locked && <span className={styles.titleIcon}><Locked /></span>}
            <span>{title}</span>
          </p>}
          {(button || help) && (
            <div className={styles.action}>
              {button ? (
                <Button {...{
                  variant: 'secondary',
                  icon: <Plus />,
                  iconPos: 'left',
                  ...button,
                }} /> 
              ) : (
                <Help {...help} />
              )}
            </div>
          )}
        </div>
      )}
      {description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
      <div className={styles.body}>
        <div className={styles.content}>{children}</div>
        {locked && (
          <div className={styles.lockedOverlay}>
            <div className={styles.lockedModal}>
              <span className={styles.lockedModalIcon}><Locked /></span>
              {lockedLabel && (<span className={styles.lockedModalLabel}>{lockedLabel}</span>)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MyAccountSection
