import React from 'react'
import useTrans from '@hooks/useTrans'
import { ChevronLeft } from '@components/atoms/Icons'
import { Button } from '@components/atoms'
import { MyAccountMenu } from '@components/molecules'
import styles from './MyAccountLayout.module.sass'

interface Props {
  titolo?: string
  descrizione?: string
  menuActiveItemKey?: string
  backLink?: boolean
  children?: React.ReactNode
  className?: string
}

const MyAccountLayout = (props: Props) => {
  const {
    titolo = '',
    descrizione = '',
    menuActiveItemKey = 'dashboard',
    children = null,
    className = '',
    backLink = false,
  } = props

  const t = useTrans()

  return (
    <>
      <div className={`${styles.myAccountLayout} ${className}`}>
        <div className={styles.sidebar}>
          <MyAccountMenu activeItemKey={menuActiveItemKey} />
        </div>
        <div className={styles.main}>
          {!!backLink && (
            <Button
              icon={<ChevronLeft />}
              iconPos="left"
              label={t('Torna alla bacheca')}
              variant="ghost"
              href={'/dashboard/informazioni-personali'}
              className={styles.backButton}
            />
          )}
          {!!titolo && <h1 className={styles.title}>{titolo}</h1>}
          {!!descrizione && (
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: descrizione }} />
          )}
          {children}
        </div>
      </div>
    </>
  )
}

export default MyAccountLayout
