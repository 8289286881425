import React, { useState } from 'react'
import useTrans from 'hooks/useTrans'
import { MenuItemType } from 'utils/types'
import styles from './BoxHeroListingCard.module.sass'
import { Swiper, SwiperSlide } from 'swiper/react'
import { GenericCard } from '@components/molecules'
import { WContainer, Button, WImage, Modal } from 'components/atoms'
import Head from 'next/head'
import useWindowDimensions from '@hooks/useWindowDimensions'
import { Play, YouTube } from '@components/atoms/Icons'
import YoutubeModal from '../YoutubeModal/YoutubeModal'

interface Props {
  item: MenuItemType
  className?: string
}

const BoxHeroListingCard = (props: Props) => {
  const { className = '', item = undefined } = props
  const t = useTrans()
  const { width } = useWindowDimensions()
  const [open, setOpen] = useState(false)

  return !!item ? (
    <div className={styles.boxHeroListingCard}>
      <div className={`${styles.boxHero} ${styles.boxHero__desktop}`}>
        <WImage
          src={item.image}
          maxWidth={1920}
          maxHeight={648}
          layout="intrinsic"
          objectFit="cover"
          loading="eager"
          priority
        />
      </div>

      <div className={`${styles.boxHero} ${styles.boxHero__mobile}`}>
        <WImage
          src={item.imageMobile}
          maxWidth={1920}
          maxHeight={648}
          layout="intrinsic"
          objectFit="cover"
          loading="eager"
          priority
        />
      </div>

      <div className={styles.content__container}>
        <div className={styles.content}>
          {!!item.name && <h1 className={styles.title}>{item.name}</h1>}
          {!!item.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
          <div className={styles.ctaBox}>
            <Button label={t('Scopri di più')} size="sm" className={styles.cta} href={item.url} />

            <Button
              label={t('Guarda il video')}
              iconPos="right"
              size="sm"
              className={`${styles.cta} ${styles['cta--desktop']}`}
              onClick={() => setOpen(true)}
            />

            <Button
              label={t('Guarda il video')}
              size="sm"
              variant="secondary"
              className={`${styles.cta} ${styles['cta--mobile']}`}
              onClick={() => setOpen(true)}
            />
          </div>
        </div>

        <YoutubeModal
          open={open}
          setOpen={setOpen}
          className={styles.modalVideo}
          src="https://www.youtube.com/embed/SmnIRaghwyI?rel=0&mute=1&autoplay=1"
        />
      </div>

      <WContainer>
        <div className={styles.articoliSlider}>
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            pagination={{ clickable: true }}
            watchOverflow
            breakpoints={{
              320: {
                slidesPerView: 1.1,
              },
              576: {
                slidesPerView: 2.5,
              },
              991: {
                slidesPerView: 3,
              },
            }}
          >
            {item.children?.length > 0 &&
              item.children.map((x) => (
                <SwiperSlide key={x.pk}>
                  <GenericCard key={x.pk} item={x} variant="white" className="shadow" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default BoxHeroListingCard
