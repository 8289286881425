import React from 'react'
import { BreadcrumbType } from 'utils/types'
import { Breadcrumbs, WContainer, WImage } from '@components/atoms'
import styles from './ImageHead.module.sass'

interface Props {
  breadcrumbs?: BreadcrumbType[]
  image?: string
  title?: string
  description?: string
  children?: React.ReactNode
  className?: string
}

const ImageHead = (props: Props) => {
  const {
    breadcrumbs = [],
    image = '',
    title = '',
    description = '',
    children = undefined,
    className = '',
  } = props

  return (
    <div className={`${styles.imageHead} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={image}
          maxWidth={767}
          maxHeight={450}
          layout="intrinsic"
          objectFit="cover"
          priority
          loading="eager"
        />
      </div>
      <WContainer>
        {breadcrumbs?.length > 0 && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        <div className={styles.content}>
          {!!title && <h1 className={styles.title}>{title}</h1>}
          {!!description && (
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {children}
        </div>
      </WContainer>
    </div>
  )
}

export default ImageHead
