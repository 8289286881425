import { WContainer, WImage } from '@components/atoms'
import { Quote } from '@components/atoms/Icons'
import QuoteClose from '@components/atoms/Icons/QuoteClose'
import useTrans from '@hooks/useTrans'
import React from 'react'
import { MenuItemType } from 'utils/types'
import styles from './ImpegnoSection3.module.sass'

interface Props {
  item: MenuItemType
  className?: string
}

const ImpegnoSection3 = (props: Props) => {
  const { item = undefined, className = '' } = props

  const t = useTrans()

  return item ? (
    <div className={`${styles.impegnoSection3} ${className}`}>
      <WContainer>
        <div className={styles.layout}>
          <div className={styles.content}>
            <div className={styles.logo}>
              <WImage
                src={'/images/logoDanone.png'}
                maxWidth={167}
                maxHeight={55}
                layout="intrinsic"
              />
            </div>
            {!!item.name && <p className={styles.title}>{item.name}</p>}
            {!!item.description && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
          </div>
          <div className={styles.quote}>
            <Quote className={styles.quoteIconOpen} />
            <QuoteClose className={styles.quoteIconClose} />
            <div className={styles.quoteContent}>
              <div
                className={styles.quoteDescription}
                dangerouslySetInnerHTML={{
                  __html: t(
                    '<p>“Nel 2011, con il coraggio derivante dalla mia esperienza di neo-mamma, avevo un sogno: mettere fine al paradigma che vede le mamme che lavorano divise fra l’aspirazione di far carriera e l’accudimento della propria famiglia. Ho voluto abbattere un tabù e parlare di maternità per cambiare una consuetudine ancora molto radicata nel nostro Paese e che vede la donna non supportata nel ruolo di mamma al lavoro.”</p><p style="text-align: right"><strong>- <a href="#">Sonia Malaspina</a>, HR Director Mellin</p>'
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default ImpegnoSection3
