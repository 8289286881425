import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { FormOptins } from 'components/organisms'
import styles from './MyOptinsFormCard.module.sass'

interface Props {
  dati?: {}
}

const MyOptinsFormCard = (props: Props) => {
  const { dati = null } = props
  const [initialValuesFromProps, setInitialValuesFromProps] = useState({ ...dati })
  const t = useTrans()
  const [edit, setEdit] = useState(true)

  useEffect(() => {
    setInitialValuesFromProps({ ...dati })
  }, [dati])

  return (
    <div className={styles.myOptinsFormCard}>
      <FormOptins
        setInitialValuesFromProps={setInitialValuesFromProps}
        initialValuesFromProps={{
          ...initialValuesFromProps,
        }}
        edit={edit}
        setEdit={(v) => setEdit(v)}
      />
    </div>
  )
}

export default MyOptinsFormCard
