import React from 'react'
import styles from './ConsigliCard.module.sass'

interface Props {
  icon: any
  title?: string
  description?: string
  children?: any
  className?: string
}

const ConsigliCard = (props: Props) => {

  const { 
    icon = null,
    title = '',
    description = '',
    children = null,
    className = '',
  } = props

  return (
    <div className={`${styles.consigliCard} ${className}`}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.content}>
        {title && <p className={styles.title}>{title}</p>}
        {description && <p className={styles.description}>{description}</p>}
        <div className={styles.buttons}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default ConsigliCard
