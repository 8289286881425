import React, { useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { IinitialVaues } from './FormRegistrazione.interfaces'
import {
  Button,
  FormCheckbox,
  FormDate,
  FormField,
  FormInput,
  FormInputPassword,
  FormLayout,
  FormSelect,
  FormToggle,
  OptinLabel,
  WImage,
} from 'components/atoms'
import styles from './FormRegistrazione.module.sass'
import { useCreateUtenteMutation, useBrandOptinsQuery } from '@gql/graphql'
import { useRouter } from 'next/router'
import useTrans from '@hooks/useTrans'
import { ModalFeedback, MyOptinsFormCard } from '@components/organisms'

const validationSchema = (t) =>
  yup.object().shape({
    dati_accesso: yup.object().shape({
      firstName: yup.string().nullable().required(t('Questo campo non può essere vuoto')),
      lastName: yup.string().nullable().required(t('Questo campo non può essere vuoto')),
      email: yup
        .string()
        .email(t("Il formato dell'email dev'essere valido"))
        .nullable()
        .required(t('Questo campo non può essere vuoto')),
      password: yup
        .string()
        .nullable()
        .required(t('Questo campo non può essere vuoto'))
        .min(8, t('Minimo 8 Caratteri'))
        .matches('^(?=.*?[A-Z])', 'Questo campo deve contenere almeno una lettera maiuscola.'),
    }),
    dati_personali: yup.object().shape({
      gender: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
      birthday: yup
        .date()
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 14)),
          t('Devi avere almeno 14 anni')
        )
        .required(t('Questo campo non può essere vuoto')),
    }),
    informativa: yup.boolean().oneOf([true], t("Devi accettare l'informativa")), // informativa
    optinMarketing: yup.boolean(), // privacy_commerciale
    optinProgramMarketing1: yup.boolean(), // datiParticolari
    optinProgramMarketing2: yup.boolean(), // privacy_profilazione
  })

const genderValues = ['F', 'M', 'X']

export const genderProps = {
  options: ['Donna', 'Uomo', 'Altro'].map((label, index) => ({
    label,
    value: genderValues[index],
  })),
}

const FormRegistrazione = ({
  from,
  preferenzeOpenDefault,
}: {
  from?: string
  preferenzeOpenDefault?: boolean
}) => {
  const [createUtente] = useCreateUtenteMutation()
  const { data: optins, optinsError } = useBrandOptinsQuery()

  const router = useRouter()
  const t = useTrans()

  const nextPage = router.query?.next

  const initialValues: IinitialVaues = {
    dati_accesso: {
      firstName: null,
      lastName: null,
      email: (router.query?.email as string) || null,
      password: null,
    },
    dati_personali: {
      birthday: '',
      gender: null,
    },
    informativa: false,
    optinMarketing: false,
    optinProgramMarketing1: false,
    optinProgramMarketing2: false,
    optinProgramMarketing3: false,
    optinProgramMarketing4: false,
  }

  const [modalConfirmCreateAccount, setModalConfirmCreateAccount] = useState(false)
  const [modalErrorCreateAccount, setModalErrorCreateAccount] = useState(false)
  const [error, setError] = useState('')

  const [preferenzeOpen, setPreferenzeOpen] = useState(preferenzeOpenDefault)

  const optinInitialValues = optins?.brandOptins
    ?.map((o) => o.optinName)
    .reduce((obj, key) => {
      obj[key] = false
      return obj
    }, {})

  return (
    <Formik
      isInitialValid={false}
      validateOnChange
      onSubmit={(values, { setSubmitting }) => {
        const optinValues = optins?.brandOptins
          ?.map((o) => o.optinName)
          .reduce((obj, key) => {
            obj[key] = values[key]
            return obj
          }, {})

        createUtente({
          variables: {
            input: {
              firstName: values?.dati_accesso?.firstName,
              lastName: values?.dati_accesso?.lastName,
              fullName: `${values?.dati_accesso?.firstName} ${values?.dati_accesso?.lastName}`,
              gender: values?.dati_personali?.gender,
              email1: values?.dati_accesso?.email,
              password: values?.dati_accesso?.password,
              birthday: dayjs(values?.dati_personali?.birthday).format('YYYY-MM-DD'),
              children: [],
              optins: {
                optinMarketing: values?.optinMarketing,
                optinProgramMarketing1: values?.optinProgramMarketing1,
                optinProgramMarketing2: values?.optinProgramMarketing2,
                optinProgramMarketing3: values?.optinProgramMarketing3,
                optinProgramMarketing4: values?.optinProgramMarketing4,
                ...optinValues,
              },
            },
          },
        })
          .then((response) => {
            const data = response.data.createUtente?.content
            if (data) {
              if (data.status_code !== 200) {
                setModalErrorCreateAccount(true)
                setError(data.content?.statusDescription[0].errorMessage)
              } else {
                setModalConfirmCreateAccount(true)
              }
            }
          })
          .catch(() => {
            setModalErrorCreateAccount(true)
          })
          .finally(() => setSubmitting(false))
      }}
      initialValues={
        optins?.brandOptins ? { ...initialValues, ...optinInitialValues } : initialValues
      }
      validationSchema={validationSchema(t)}
      enableReinitialize
    >
      {(props) => (
        <>
          <FormLayout>
            <FormField
              label={t('Nome*')}
              status={
                props.touched.dati_accesso?.firstName
                  ? props.errors.dati_accesso?.firstName
                    ? 'error'
                    : 'success'
                  : null
              }
              errorMessage={props?.errors?.dati_accesso?.firstName}
            >
              <FormInput
                onBlur={props.handleBlur('dati_accesso.firstName')}
                placeholder={t('Nome')}
                value={props.values?.dati_accesso?.firstName}
                onChange={props.handleChange('dati_accesso.firstName')}
              />
            </FormField>

            <FormField
              label={t('Cognome*')}
              status={
                props.touched.dati_accesso?.lastName
                  ? props.errors.dati_accesso?.lastName
                    ? 'error'
                    : 'success'
                  : null
              }
              errorMessage={props?.errors?.dati_accesso?.lastName}
            >
              <FormInput
                onBlur={props.handleBlur('dati_accesso.lastName')}
                placeholder={t('Cognome')}
                value={props.values.dati_accesso?.lastName}
                onChange={props.handleChange('dati_accesso.lastName')}
              />
            </FormField>

            <FormField
              label={t('Email*')}
              status={
                props.touched.dati_accesso?.email
                  ? props.errors.dati_accesso?.email
                    ? 'error'
                    : 'success'
                  : null
              }
              errorMessage={props?.errors?.dati_accesso?.email}
            >
              <FormInput
                onBlur={props.handleBlur('dati_accesso.email')}
                placeholder={t('Email')}
                value={props.values.dati_accesso?.email}
                onChange={props.handleChange('dati_accesso.email')}
              />
            </FormField>

            <FormField
              label={t('Password')}
              status={
                props.touched.dati_accesso?.password
                  ? props.errors.dati_accesso?.password
                    ? 'error'
                    : 'success'
                  : null
              }
              errorMessage={props?.errors?.dati_accesso?.password}
              helper={{
                id: 'password-helper',
                label: (
                  <>
                    La password deve contenere:
                    <br /> - minimo 8 caratteri
                    <br /> - almeno una lettera maiuscola
                  </>
                ),
                pos: 'right',
              }}
            >
              <FormInputPassword
                togglePassword
                onBlur={props.handleBlur('dati_accesso.password')}
                placeholder={t('Password')}
                value={props.values?.dati_accesso.password}
                onChange={props.handleChange('dati_accesso.password')}
              />
            </FormField>

            <FormField
              label={t('Sesso*')}
              errorMessage={props.errors.dati_personali?.gender}
              status={props.errors.dati_personali?.gender ? 'error' : null}
            >
              <FormSelect
                isSearchable
                {...genderProps}
                onBlur={props.handleBlur('dati_personali.gender')}
                placeholder={t("Scegli un'opzione")}
                value={props.values.dati_personali.gender || ''}
                onChange={(value) => props.setFieldValue('dati_personali.gender', value?.value)}
              />
            </FormField>

            <FormField
              label={t('Data di nascita (GG/mm/aaaa)*')}
              errorMessage={props.errors.dati_personali?.birthday}
              status={props.errors.dati_personali?.birthday ? 'error' : 'success'}
            >
              <FormDate
                onBlur={props.handleBlur('dati_personali.birthday')}
                status={props.errors.dati_personali?.birthday ? 'error' : ''}
                value={props.values.dati_personali?.birthday}
                onChange={(value) => props.setFieldValue('dati_personali.birthday', value)}
              />
            </FormField>

            <div className={styles.optins}>
              <p className={styles.privacy_title}>{t('Privacy')}</p>
              <FormField
                layout="full"
                status={
                  props.touched?.informativa
                    ? props.errors?.informativa
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props?.errors?.informativa}
              >
                <FormCheckbox
                  id="informativa"
                  label={<OptinLabel type="informativa" />}
                  checked={props.values?.informativa}
                  onChange={props.handleChange('informativa')}
                />
              </FormField>
              <p className={styles.privacy_title}>
                {t(
                  'Vuoi ricevere comunicazioni, partecipare a ricerche di mercato e alle communities Danone?'
                )}
              </p>
              <FormField
                status={
                  props.touched.optinMarketing
                    ? props.errors.optinMarketing
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props.errors.optinMarketing}
                layout="full"
                className={styles.fieldMarketing}
              >
                <FormCheckbox
                  id="optinMarketing"
                  checked={props.values?.optinMarketing}
                  onChange={props.handleChange('optinMarketing')}
                  label={<OptinLabel type="commerciale" />}
                />
                <Button
                  label={
                    preferenzeOpen
                      ? t('Nascondi le preferenze di ricontatto dei Brand Danone')
                      : t('Gestisci le tue preferenze di ricontatto dei Brand Danone')
                  }
                  variant="ghost"
                  size="sm"
                  className={styles.preferenzeTrigger}
                  onClick={() => setPreferenzeOpen(!preferenzeOpen)}
                />
                {preferenzeOpen && (
                  <div className={styles.preferenzeContainer}>
                    {optins?.brandOptins &&
                      optins?.brandOptins?.map((optinBrand) => (
                        <div className={styles.optinBrandCard} key={optinBrand.name}>
                          <div className={styles.optinNameImageContainer}>
                            <WImage
                              alt={optinBrand.name}
                              src={optinBrand.image}
                              id={optinBrand.name}
                            />
                            <div className={styles.optinBrandName}>
                              <strong className={styles.name}>{optinBrand.name}</strong>
                              {optinBrand.informativa && (
                                <a
                                  href={optinBrand.informativa}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={styles.optinBrandInformativa}
                                >
                                  {t("Leggi l'informativa Privacy")}
                                </a>
                              )}
                            </div>
                          </div>
                          <FormToggle
                            id={optinBrand.optinName}
                            checked={props.values[optinBrand.optinName]}
                            onChange={(e) => {
                              props.setFieldValue(e.target.id, e.target.checked)
                            }}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </FormField>
              <p className={styles.privacy_title}>
                {t(
                  'Vuoi avere un’esperienza personalizzata in basa alle tue abitudini di acquisto?'
                )}
              </p>
              <FormField
                status={
                  props.touched.optinProgramMarketing2
                    ? props.errors.optinProgramMarketing2
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props.errors.optinProgramMarketing2}
                layout="full"
              >
                <FormCheckbox
                  id="optinProgramMarketing2"
                  checked={props.values?.optinProgramMarketing2}
                  onChange={props.handleChange('optinProgramMarketing2')}
                  label={<OptinLabel type="profilazione" />}
                />
              </FormField>
              <FormField
                status={
                  props.touched.optinProgramMarketing3
                    ? props.errors.optinProgramMarketing3
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props.errors.optinProgramMarketing3}
                layout="full"
              >
                <FormCheckbox
                  id="optinProgramMarketing3"
                  checked={props.values?.optinProgramMarketing3}
                  onChange={props.handleChange('optinProgramMarketing3')}
                  label={<OptinLabel type="statistici" />}
                />
              </FormField>
              {/* <FormField
                status={
                  props.touched.optinProgramMarketing4
                    ? props.errors.optinProgramMarketing4
                      ? 'error'
                      : 'success'
                    : null
                }
                errorMessage={props.errors.optinProgramMarketing4}
                layout="full"
              >
                <FormCheckbox
                  id="optinProgramMarketing4"
                  checked={props.values?.optinProgramMarketing4}
                  onChange={props.handleChange('optinProgramMarketing4')}
                  label={<OptinLabel type="analisiDatiParticolari" />}
                />
              </FormField> */}
            </div>
          </FormLayout>
          <p className={styles.privacy_title}>
            {t(
              'Proseguendo con la registrazione confermi di voler procedere alla creazione di un account Danone che ti permetterà di accedere a tutti i siti di Danone Nutricia S.p.A. Società Benefit.'
            )}
          </p>

          <div className={styles.buttons}>
            <Button
              disabled={!props.isValid || props.isSubmitting}
              className={styles.submit}
              type="submit"
              onClick={() => props.handleSubmit()}
              label={t('Crea un account Danone')}
              variant="primary"
            />
          </div>

          <ModalFeedback
            open={modalConfirmCreateAccount}
            setOpen={setModalConfirmCreateAccount}
            closeButton={false}
            onClose={(event) => {
              if (nextPage) router.push(nextPage)
              else {
                router.push('/login')
              }
            }}
            title={t('La registrazione è avvenuta con successo!')}
            description={t(
              'Riceverai presto un’email in cui ti chiederemo di finalizzare l’iscrizione cliccando sul link che troverai nel testo.'
            )}
            ctaLabel={t('Accedi al tuo profilo')}
          />
          <ModalFeedback
            open={modalErrorCreateAccount}
            setOpen={setModalErrorCreateAccount}
            closeButton={false}
            onClose={(event) => {
              setError('')
            }}
            title={t('Si è verificato un errore durante la registrazione')}
            description={t(error)}
            ctaLabel={t('Torna alla registrazione')}
          />
        </>
      )}
    </Formik>
  )
}

export default FormRegistrazione
