import React from 'react'
import { MenuItemType } from 'utils/types'
import styles from './ArticoliCorrelati.module.sass'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArticoloCard } from '@components/molecules'

interface Props {
  item: MenuItemType
  className?: string
}
const ArticoliCorrelati = (props: Props) => {
  const { className = '', item = undefined } = props

  return !!item ? (
    <div className={`${styles.articoliCorrelati} ${className}`}>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title}>{item.name}</p>}
        {!!item.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
      </div>

      <div className={styles.articoliSlider}>
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          pagination={{ clickable: true }}
          watchOverflow
          breakpoints={{
            320: { slidesPerView: 1.3 },
            576: {
              slidesPerView: 2.5,
            },
            991: {
              slidesPerView: 3,
            },
          }}
        >
          {item.children?.length > 0 &&
            item.children.map((x) => (
              <SwiperSlide key={x.pk}>
                <ArticoloCard key={x.pk} articolo={x.articolo} className="shadow" />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ArticoliCorrelati
