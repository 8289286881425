import React from 'react'
import { useTrans } from 'hooks'
import { Button, Modal } from 'components/atoms'
import {BrandCard} from "@components/molecules"
import styles from './DeleteModal.module.sass'


interface Props {
  open: boolean
  setOpen: (e: boolean) => void
  title: string
  description: string
  onConfirm: () => void
}

const DeleteModal = ({ open, setOpen, title, description, onConfirm }: Props) => {
  const t = useTrans()

  return (
    <Modal className={styles.head} open={open} setOpen={setOpen}>
      <Modal.Content classNameTitle={styles.title} classNameDescription={styles.description}
                     title={title} description={description} />

      <BrandCard showTitle={false}/>

      <Modal.Buttons>
        <Button
          variant="primary"
          label={t('Elimina definitivamente')}
          className={styles.confirmButton}
          onClick={() => {
            onConfirm && onConfirm()
          }}
        />
        <Button className={styles.cancelButton} label={t('Annulla')} onClick={() => setOpen(false)}/>
      </Modal.Buttons>

      <div
        className={styles.subDescription}
      >
        {t('Se invece preferisci modificare le tue preferenze sulle comunicazioni di Danone ')}
        <a className={styles.subLink} href="/dashboard/preferenze">
          {t('clicca qui')}
        </a>
      </div>
    </Modal>
  )
}

export default DeleteModal
