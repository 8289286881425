import { Formik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useLoginMutation } from '@gql/graphql'
import { useRouter } from 'next/router'
import useTrans from '@hooks/useTrans'
import useLocalStorage from '@hooks/useLocalStorage'
import {
  Button,
  FormCheckbox,
  FormField,
  FormInput,
  FormLayout,
  FormInputPassword,
  Link,
} from 'components/atoms'
import styles from './FormLogin.module.sass'

const validationSchema = (t) =>
  yup.object().shape({
    email: yup.string().email(t("Il formato dell'email dev'essere valido")).required().nullable(),
    password: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    ricordati: yup.boolean().nullable(),
  })

const FormLogin = ({ nextPage, from, email }: { nextPage: ''; from?: string; email?: string }) => {
  const [login, { loading }] = useLoginMutation()
  const [error, setError] = useState<string | undefined | null>()
  const [, setToken] = useLocalStorage('token', null)

  const router = useRouter()
  const t = useTrans()

  const initialValues = {
    email: email,
    password: null,
    ricordati: null,
  }

  return (
    <Formik
      isInitialValid={false}
      validateOnChange
      onSubmit={(values) =>
        login({
          variables: {
            email: values.email,
            password: values.password,
          },
        })
          .then((res) => {
            const status_code = res.data?.tokenAuth?.data?.status_code

            switch (status_code) {
              case 200:
                // save token and gui
                setToken(res.data?.tokenAuth?.data?.content?.tokenObject?.token)
                router.push(nextPage || '/dashboard/informazioni-personali')
                break
              case 403:
                setError('Le credenziali inserite non sono corrette.')
                break
              default:
                setError('Si è verificato un errore, ti preghiamo di riprovare più tardi')
            }
          })
          .catch(() => {
            setError('Si è verificato un errore, ti preghiamo di riprovare più tardi')
          })
      }
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      enableReinitialize
    >
      {(props) => (
        <>
          <FormLayout>
            <FormField
              label="Email"
              layout="full"
              status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
              errorMessage={props?.errors?.email as string}
            >
              <FormInput
                onBlur={props.handleBlur('email')}
                placeholder="La tua email"
                value={props.values.email}
                onChange={props.handleChange('email')}
              />
            </FormField>
            <FormField
              label="Password"
              layout="full"
              status={props.touched.password ? (props.errors.password ? 'error' : 'success') : null}
              errorMessage={props?.errors?.password as string}
            >
              <FormInputPassword
                togglePassword={true}
                placeholder="Password"
                onBlur={props.handleBlur('password')}
                value={props.values.password}
                onChange={props.handleChange('password')}
              />
            </FormField>
          </FormLayout>
          <p className={styles.error}>{error}</p>
          <div className={styles.helpers}>
            <FormCheckbox
              label="Ricordati di me"
              id="ricordati"
              checked={props.values.ricordati}
              onChange={(e) => props.setFieldValue('ricordati', e.target.checked)}
            />

            <Link
              label={t('Password dimenticata?')}
              href="recupera-password"
              arrow={false}
              className={styles.forgotPassword}
              decoration
            />
          </div>
          <Button
            label="Accedi a Danone"
            onClick={() => props.handleSubmit()}
            disabled={Object.values(props.errors).length > 0 || loading}
            className={styles.submit}
          />
          {!from && (
            <p className={styles.registration}>
              {t('Non hai un account? ')}
              <Link label={t(' Registrati')} href="registrazione" arrow={false} decoration />
            </p>
          )}
        </>
      )}
    </Formik>
  )
}

export default FormLogin
