import React from 'react'
import { Modal } from 'components/atoms'
import { FeedbackCard } from 'components/molecules'
import styles from './ModalConfirmChangePreferenze.module.sass'

interface Props {
  open: boolean
  setOpen: (e: boolean) => void
  closeButton?: boolean
  onClose?: () => void
  title?: string
  description?: string
  ctaLabel?: string
  showIcon?: boolean
}

const ModalFeedback = (props: Props) => {
  const {
      open,
      setOpen,
      closeButton = true,
      onClose = null,
      title = null,
      description = null,
      ctaLabel = null,
      showIcon = true,
  } = props


  return <Modal open={open} setOpen={setOpen} closeButton={closeButton}>
    <FeedbackCard
      showIcon={showIcon}
      onClose={() => {
        setOpen(false)
        onClose()
      }}
      title={title}
      description={description}
      ctaLabel={ctaLabel}
    />
  </Modal>
}

export default ModalFeedback
