import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'
import useTrans from '@hooks/useTrans'
import { Button, Modal } from '@components/atoms'
import styles from './GenericModal.module.sass'

interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  className?: string
  url?: string
}

const GenericModal = (props: Props) => {
  const { open = false, setOpen = () => {}, className = '', url=''} = props
  const t = useTrans()
  const router = useRouter()

  const getContent = (url: string) => {
  
  const key = url.includes('patronato-acli') ? 'patronato_acli': url.includes('counseling') ? 'counseling':''
    
    switch (key){
      case "patronato_acli": return {
        title: t('Prenditi un attimo per leggere questo'),
        description:  t('<p>Gentile utente, ti informiamo che avrai accesso alla pagina web di Patronato Acli, per procedere alla richiesta della consulenza in ambito giuslavorista. La consulenza ti verrà fornita direttamente dagli esperti di Patronato Acli, ente partner di Jointly, e totalmente autonomo ed indipendente rispetto a Mellin.</p><p>I tuoi dati personali saranno raccolti e trattati da Patronato Acli ai sensi della informativa sul trattamento dei dati personali fornita da quest’ultimo e consultabile al seguente <a href="https://www.patronato.acli.it/informativa-sul-trattamento-dei-dati-personali-regolamento-ue-2016-679/" target="_blank">link</a>. I tuoi dati personali non saranno per alcuna ragione trasmessi a Mellin.</p>')
        
    }
      case "counseling": return {
        title: t('Prenditi un attimo per leggere questo'),
        description: t('<p>Gentile utente, ti informiamo che avrai accesso alla pagina web di Modus, per procedere alla richiesta della consulenza in ambito psicopedagogico. La consulenza ti verrà fornita direttamente dagli esperti di Modus, ente partner di Jointly, e totalmente autonomo ed indipendente rispetto a Mellin.</p> <p>I tuoi dati personali saranno raccolti e trattati da Modus ai sensi della informativa sul trattamento dei dati personali fornita da quest’ultimo e consultabile al seguente <a href="https://www.modus-sb.it/privacy-policy/" target="_blank">link</a>. I tuoi dati personali non saranno per alcuna ragione trasmessi a Mellin.</p>'),
      }
      default:
        return null
    }

  }


  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={`${styles.genericModal} ${className}`}
      scrollable
    >
      <Modal.Content title={getContent(url)?.title}> 
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: getContent(url)?.description,
          }}
        />
      </Modal.Content>
      <Modal.Buttons>
        <Button
          label={t('OK, HO CAPITO')}
          onClick={() => {
            setOpen(false)
            router.push(url)
          }}
        />
      </Modal.Buttons>
    </Modal>
  )
}

export default GenericModal
