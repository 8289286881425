import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { MyAccountAccountView, FormCard } from 'components/molecules'
import { FormAccountPrivacy } from 'components/organisms'
import styles from './MyAccountAccountFormCard.module.sass'

interface Props {
  dati?: {
    email?: string
  }
}

const MyAccountAccountFormCard = (props: Props) => {

  const { 
    dati = null,
  } = props

  const t = useTrans()
  const [edit, setEdit] = useState(false)

  return <div>
    {edit ? (
      <FormAccountPrivacy 
        initialValuesFromProps={{
          email: dati?.email,
        }}
        onCancel={() => setEdit(false)}
      />
    ) : (
      <FormCard title={t("Account e password")} >
        <MyAccountAccountView {...dati} editButton={{ onClick: () => setEdit(true)}}
        />
      </FormCard>
    )}
  </div> 
}

export default MyAccountAccountFormCard
