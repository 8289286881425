import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CategoriaFaqNode } from '@gql/graphql'
import useUtente from '@hooks/useUtente'
import useTrans from '@hooks/useTrans'
import { getIcon } from 'utils/icons'
import { scrollTo } from 'utils/safe'
import { Button, Accordion } from '@components/atoms'
import styles from './FaqListing.module.sass'


interface Props {
  categorieFaq: CategoriaFaqNode[]
  className?: string
}

const FaqListing = (props: Props) => {

  const { 
    categorieFaq = [],
    className = '',
  } = props

  const t = useTrans()
  const categorieRefs = useRef([])
  const faqRefs = useRef([])

  const { utente } = useUtente()  
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const router = useRouter()

  const [hash, setHash] = useState(router.asPath.match(/#([^\?]+)/i)?.[1])

  const scrollToRef = (ref) => {
    if (ref)
      scrollTo(ref, toolbarPresente, 10, window.innerWidth < 992, window.scrollY)
  }

  useEffect(() => {
    if (hash && categorieFaq.length > 0){
      const hashCategorieRefIndex = (categorieFaq.map(x => x.slug)).indexOf(hash)
      if (hashCategorieRefIndex > -1)
        scrollToRef(categorieRefs.current[hashCategorieRefIndex])
      else {
        const hashFaqRefIndex = (categorieFaq.reduce((list, x) => list.concat(x.faqs.map(y => y.slug)), [])).indexOf(hash)
        if (hashFaqRefIndex > -1)
          scrollToRef(faqRefs.current[hashFaqRefIndex])
      }
    }      
  }, [hash])

  useEffect(() => {
    setHash(router.asPath.match(/#([^\?]+)/i)?.[1])
  }, [router.asPath])

  return categorieFaq.length > 0 ? (
    <div className={`${styles.faqListing} ${className}`}>
      <div className={styles.categoriesSlider}>
        <Swiper 
          slidesPerView={1.2} 
          spaceBetween={20} 
          centeredSlides 
          pagination={{ clickable: true }}
          watchOverflow
          breakpoints={{
            768: {
              slidesPerView: 3,
              centeredSlides: false
            }
          }}
        >
          {categorieFaq.map((x, index) => (
            <SwiperSlide key={x.pk}>
              <div className={styles.categoriesSliderItem}>
                <span className={styles.categoriesSliderItemIcon}>
                  {getIcon(`${x.alias}-illustration`)}
                </span>
                {!!x.titolo && <p className={styles.categoriesSliderItemTitle}>{x.titolo}</p>}
                <Button variant="ghost" label={t("Vedi tutto")} href={`#${x.slug}`} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.categoriesList}>
          {categorieFaq.map(c => (
            <div key={c.pk} className={styles.categoriesListItem} ref={(element) => (categorieRefs.current.push(element))}>
              {!!c.titolo && <p className={styles.categoriesListItemTitle}>{c.titolo}</p>}
              {c.faqs.length > 0 && (
              <div className={styles.faqList}>
                {c.faqs.map(f => (
                  <div className={styles.faqListItem} key={f.pk} ref={(element) => (faqRefs.current.push(element))}>
                    <Accordion label={f.domanda} content={f.risposta} isOpen={f.slug === hash} />
                  </div>
                ))}
              </div>
              )}
            </div>
          ))}
      </div>
    </div>
  ) : <></>
}

export default FaqListing
