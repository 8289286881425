import { Button } from '@components/atoms'
import React from 'react'
import { MenuItemType } from 'utils/types'
import styles from './EmptyMessageBox.module.sass'


interface Props {
  item: MenuItemType
  className?: string
}

const EmptyMessageBox = (props: Props) => {

  const { 
    item = undefined,
    className = '',
  } = props

  return item ? (
    <div className={`${styles.emptyMessageBox} ${className}`}>
      {!!item.name && <p className={styles.title}>{item.name}</p>}
      {!!item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
      {!!item.ctaLabel && !!item.url && (
        <div className={styles.ctaBox}>
          <Button label={item.ctaLabel} href={item.url} />
        </div>
      )}
    </div>
  ) : <></>
}

export default EmptyMessageBox
