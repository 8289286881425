import { ProdottoCard } from '@components/molecules'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MenuItemType } from 'utils/types'
import styles from './ListingProdottiSection2.module.sass'


interface Props {
  item: MenuItemType
  className?: string
}

const ListingProdottiSection2 = (props: Props) => {

  const { 
    item = undefined,
    className = '',
  } = props

  return item ? (
    <div className={`${styles.listingProdottiSection2} ${className}`}>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />}
        {!!item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
      </div>
      {item.children?.length > 0 && (
        <div className={styles.slider}>
          <Swiper 
            slidesPerView={2.2}
            spaceBetween={20} 
            pagination={{ clickable: true }}
            watchOverflow
            breakpoints={{
              768: {
                slidesPerView: 4.3
              },
              992: {
                slidesPerView: 6
              }
            }}
          >
            {item.children.map((x) => (
              <SwiperSlide key={x.pk}>
                <ProdottoCard prodotto={{
                    pk: x.pk,
                    titolo: x.name,
                    descrizione: x.description,
                    mainImage: x.image,
                    link: x.url,
                    blank: x.blank
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  ) : <></>
}

export default ListingProdottiSection2
