import React, { useState } from 'react'
import { useLocalStorage, useTrans } from 'hooks'
import { Button } from 'components/atoms'
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal'
import { useDeleteUtenteMutation } from 'gql/graphql'
import { useRouter } from 'next/dist/client/router'
import styles from './MyAccountDeleteCard.module.sass'

const MyAccountDeleteCard = () => {
  const t = useTrans()
  const router = useRouter()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [, setToken] = useLocalStorage('token', null)
  const [deleteUtente] = useDeleteUtenteMutation()
  return (
    <div className={styles.myAccountDeleteCard}>
      <p className={styles.title}>{t('Cancella account')}</p>
      <p className={styles.description}>
        {t('Desidero che il mio profilo personale venga cancellato dal database di Mellin.')}
      </p>
      <Button
        label={t('Cancella account')}
        variant="ghost"
        size="sm"
        onClick={() => setShowDeleteModal(true)}
      />
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        title={t(`Sei sicuro di voler eliminare il tuo account?`)}
        description={t(`Questa azione non è reversibile e cancellerà definitivamente tutti i dati da tutti i brand Danone.`)}
        onConfirm={() =>
          deleteUtente()
            .then(() => {
              router.push('/logout?delete=True')
            })
            .catch((err) => router.push('/logout'))
        }
      />
    </div>
  )
}

export default MyAccountDeleteCard
