import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { useTrans } from 'hooks'
import { ChevronLeft } from '@components/atoms/Icons'
import { Button, Separator } from '@components/atoms'
import {ChangeConfirm, FormOptin} from 'components/organisms'
import styles from './MyOptinFormCard.module.sass'

interface Props {
  dati?: {
      url: string
      name: string
      breadcrumb: string
  }
}

const MyOptinsFormCard = (props: Props) => {
  const { dati = null } = props
  const [initialValuesFromProps, setInitialValuesFromProps] = useState({ ...dati })
  const [showConfirmMessage, setShowConfirmMessage] = useState(false)
  const [edit, setEdit] = useState(true)
  const t = useTrans()
  const router = useRouter()

  return (
      <div className={styles.myOptinCard}>
          {!showConfirmMessage ?
              <div>
                  <Button
                    icon={<ChevronLeft />}
                    iconPos="left"
                    label={t("Torna a ") + dati?.breadcrumb }
                    variant="ghost"
                    href={dati?.url}
                    className={styles.backButton}
                  />
                  <h1 className={styles.title}>{t('Le tue preferenze')}</h1>
                  <div className={styles.description}>{t('Messaggio conferma optin')}</div>
                  <FormOptin
                    setInitialValuesFromProps={setInitialValuesFromProps}
                    initialValuesFromProps={{
                    ...initialValuesFromProps,
                    }}
                    edit={edit}
                    setEdit={(v) => setEdit(v)}
                    setShowConfirmMessage={(v) => setShowConfirmMessage(v)}
                  />
              </div>
          :
              <div className={styles.modalContainer}>
                  <ChangeConfirm onClose={() => {
                      router.push(dati?.url)
                    }}
                    label={`${t("Torna a")} ${dati?.breadcrumb}`}
                  />
                  <Separator className={styles.separator}/>
              </div>
          }
          <div
              className={`${styles.subLink} ${styles.subDescription}`}
          >
            {t('Messaggio modifica altre preferenze')}
          </div>
          <div className={styles.subLink}>
              <a href="#" onClick={() => router.push("/dashboard/preferenze")}>
                  {t('Clicca qui')}
              </a>
          </div>
      </div>
    )
}

// http://localhost:3002/dashboard/MELLIN/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVSUQiOiIzMzY1YWVhN2FjYzg0NDBmOGY1MDRhMDJjYWIwOTBiYyIsImVtYWlsIjoidGVzdDcwQHdlYnJhbmtpbmcuaXQiLCJwaG9uZSI6bnVsbCwiZG9tYWluIjoiSVRNRUxMSU4iLCJpc1JlZ2lzdGVyZWQiOnRydWUsInNlc3Npb24iOiJlMzQ4ZDZmNi1kYzFjLTQzZTAtODA2OS1hOGEwY2E0NDVhZmYiLCJkYXRhIjoie1widHlwZVwiOlwic2Vzc2lvblwifSIsImlhdCI6MTY3MjczMDQ4OCwiZXhwIjoxNjczNTk0NDg4fQ.dGNorTYoCwj6bSsamcVkEgnEiuV96Rz33Rij3Tw4XgE

export default MyOptinsFormCard
