import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from 'hooks'
import { MenuItemType } from 'utils/types'
import { LandingProdottoInfoShape } from '@components/atoms/Icons'
import { WContainer, WImage } from '@components/atoms'
import styles from './LandingProdottoInfo.module.sass'


interface Props {
  item: MenuItemType
  variant?: '' | 'latti' | 'bio'
  className?: string
}

const LandingProdottoInfo = (props: Props) => {

  const { 
    item = undefined,
    variant = '',
    className = '',
  } = props

  const t = useTrans()

  return item ? (
    <div className={`${styles.landingProdottoInfo} ${variant ? styles[`landingProdottoInfo--${variant}`] : ''} ${className}`}>
      <WContainer>
        <div className={styles.content}>
          <p className={styles.intro}>{t("Perchè sceglierli")}</p>
          {!!item.name && <p className={styles.title}>{item.name}</p>}
        </div>
        {item.children?.length > 0 && (
          <div className={styles.list}>
            <Swiper 
              watchOverflow
              pagination={{ clickable: true }}
              spaceBetween={20}
              slidesPerView={1.2} 
              breakpoints={{
                320: {
                  centeredSlides: true
                },
                576: {
                  slidesPerView: 2.1
                },
                768: {
                  slidesPerView: 3
                }
              }}>
              {item.children.map(x => (
                <SwiperSlide key={x.pk}>
                  <div className={styles.item}>
                    <div className={styles.itemImageBox}>
                      <WImage 
                        src={x.image}
                        maxWidth={210}
                        maxHeight={210}
                        layout="intrinsic"
                        alt={x.name/* TODO */}
                        title={x.name/* TODO */}
                      />
                      <LandingProdottoInfoShape className={styles.itemImageBg} />
                    </div>
                    {!!x.name && <p className={styles.itemTitle}>{x.name}</p>}
                    {!!x.description && <div className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: x.description }} />}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </WContainer>
    </div>
  ) : <></>
}

export default LandingProdottoInfo
