import React, { useEffect, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { Button, Modal } from '@components/atoms'
import useCookie from '@hooks/useCookie'
import styles from './DisclaimerModal.module.sass'

interface Props {
  className?: string
}

const DisclaimerModal = (props: Props) => {
  const { className = '' } = props

  const [open, setOpen] = useState(false)

  const t = useTrans()

  const [exists, setCookie] = useCookie('disclaimer', false)

  function toggleDisclaimer(show) {
    if (show) {
      setTimeout(() => setOpen(true), 3000)
    } else {
      setOpen(false)
    }
  }

  useEffect(() => {
    toggleDisclaimer(!exists)
  }, [exists])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className={`${styles.disclaimerModal} ${className}`}
      scrollable
    >
      <Modal.Content title={t('Per favore, leggi questa nota importante')}>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: t(
              '<p>Noi ci impegniamo a supportare l’allattamento al seno, perché crediamo che il tuo latte sia l’alimento migliore per il tuo bambino e gli fornisca molti benefici. L’Organizzazione Mondiale della Sanità raccomanda il latte materno come alimento esclusivo fino al 6° mese di vita del bambino, e come fonte lattea durante lo svezzamento e anche dopo l’anno. E’ importante che in preparazione e durante l’allattamento tu segua una dieta sana e bilanciata.</p><p>Se decidi di non allattare, o di combinare l’allattamento al seno con l’utilizzo di latti formulati, devi essere consapevole che ciò potrebbe interferire con la produzione del tuo latte e che questa decisione è difficilmente reversibile. Prima di iniziare ad utilizzare formule per l’infanzia, ti raccomandiamo anche di pensare alle implicazione che questa decisione ha a livello sociale ed economico. Per assicurare la salute del tuo bambino devi sempre seguire le istruzioni riportate in etichetta riguardo la preparazione, l’utilizzo e la conservazione delle formule per lattanti.</p><p>Per aiutarti a decidere al meglio sull’alimentazione del tuo bambino chiedi sempre consiglio al tuo Pediatra.</p>'
            ),
          }}
        />
      </Modal.Content>
      <Modal.Buttons>
        <Button
          label={t('Continua')}
          onClick={() => {
            setOpen(false)
            setCookie('disclaimer', '1', 1)
          }}
        />
      </Modal.Buttons>
    </Modal>
  )
}

export default DisclaimerModal
