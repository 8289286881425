import React from 'react'
import useWindowDimensions from '@hooks/useWindowDimensions'
import { Button, WImage } from '@components/atoms'
import { MenuItemType } from 'utils/types'
import styles from './ListingProdottiSection1.module.sass'


interface Props {
  item: MenuItemType
  className?: string
}

const ListingProdottiSection1 = (props: Props) => {

  const { 
    item = undefined,
    className = '',
  } = props

  const { width } = useWindowDimensions()

  return item ? (
    <div className={`${styles.listingProdottiSection1} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={item.imageMobile && width < 768 ? item.imageMobile : item.image}
          maxWidth={560}
          maxHeight={350}
          layout="intrinsic"
        />
      </div>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />}
        {!!item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
        {!!item.url && !!item.ctaLabel && (
          <Button href={item.url} label={item.ctaLabel} className={styles.cta} size="sm" />
        )}
      </div>
    </div>
  ) : <></>
}

export default ListingProdottiSection1
