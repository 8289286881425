import React from 'react'
import { WContainer } from '@components/atoms'
import styles from './ListingProdottiHead.module.sass'


interface Props {
  title?: string
  description?: string
  className?: string
}

const ListingProdottiHead = (props: Props) => {

  const {
    title = '',
    description = '',
    className = '',
  } = props


  return (
    <div className={`${styles.listingProdottiHead} ${className}`}>
      <WContainer variant="sm">
        <div className={styles.content}>
            {!!title && <h1 className={styles.title}>{title}</h1>}
            {!!description && (<div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />)}
        </div>
      </WContainer>
      <div className={styles.shape} />
    </div>
  )
}

export default ListingProdottiHead
