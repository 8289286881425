import React from 'react'
import { Check } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import styles from './FeedbackCard.module.sass'

interface Props {
  showIcon?: boolean
  onClose?: () => void
  title?: string
  description?: string
  ctaLabel?: string
}

const FeedbackCard = (props: Props) => {
  const {
      showIcon = true,
      onClose = null,
      title = null,
      description = null,
      ctaLabel = null
  } = props

  return (
    <div className={styles.container}>
      {showIcon && (
          <div className={styles.icon}>
            <Check />
          </div>
      )}
      {(title && <h1 className={styles.title}>{title}</h1>)}
      {(description && <p className={styles.description}>{description}</p>)}
      {(ctaLabel && onClose && <Button
            className={styles.button}
            type="submit"
            onClick={() => onClose()}
            label={ctaLabel}
            variant="primary"
        />)
      }
    </div>
  )
}

export default FeedbackCard
