import { Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { Button, FormField, FormInput, FormLayout } from 'components/atoms'
import styles from './FormResendActivation.module.sass'
import { useResendActivationMutation } from '@gql/graphql'
import { useRouter } from 'next/router'

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Questo campo è obbligatorio')
    .email("Il formato dell'email dev'essere valido")
    .nullable(),
})

const FormResendActivation = () => {
  const router = useRouter()

  const initialValues = {
    email: null,
  }

  const [resendActivation, { loading }] = useResendActivationMutation()

  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) =>
        resendActivation({
          variables: {
            email: values.email,
          },
        })
          .then(() => {
            router.push('/resend-activation/thank-you/')
          })
          .catch((error) => console.log(error))
      }
    >
      {(props) => (
        <>
          <FormLayout>
            <FormField
              label="Email*"
              status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
              errorMessage={props.errors.email as string}
              layout="full"
            >
              <FormInput
                value={props.values.email}
                onBlur={props.handleBlur('email')}
                onChange={props.handleChange('email')}
                placeholder="Inserisci la tua email"
              />
            </FormField>
          </FormLayout>
          <Button
            disabled={!!props.errors.email || !props.dirty}
            variant="primary"
            label="Invia"
            onClick={() => props.handleSubmit()}
            type="submit"
            className={styles.submit}
            loading={loading}
          />
        </>
      )}
    </Formik>
  )
}

export default FormResendActivation
