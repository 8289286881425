import React, { useEffect, useState } from 'react'
import { Formik, useFormikContext } from 'formik'
import * as yup from 'yup'
import { useUpdateUtenteOptinsMutation } from 'gql/graphql'
import useTrans from 'hooks/useTrans'
import { useRouter } from 'next/router'
import { Button, FormField, FormLayout, FormRadio, OptinLabel, FormToggle } from 'components/atoms'
import { FormCard } from 'components/molecules'
import { ModalFeedback } from 'components/organisms'
import { Image } from 'react-bootstrap'
import styles from './FormOptins.module.sass'

const validationSchema = (t) =>
  yup.object().shape({
    optinMarketing: yup.string(),
    optinProgramMarketing1: yup.string(),
    optinProgramMarketing2: yup.string(),
    optinProgramMarketing3: yup.string(),
    optinProgramMarketing4: yup.string(),
  })

const CheckOptins = () => {
  const { values, submitForm } = useFormikContext()
  const excluded_keys = [
    'optinMarketing',
    'optinProgramMarketing1',
    'optinProgramMarketing2',
    'optinProgramMarketing3',
    'optinProgramMarketing4',
  ]

  useEffect(() => {
    if (values?.optinMarketing === '0') {
      Object.keys(values).some((key) => {
        if (!excluded_keys.includes(key)) {
          values[key] = false
        }
      })
    }
  }, [values?.optinMarketing, submitForm])

  useEffect(() => {
    for (const key in values) {
      if (!excluded_keys.includes(key) && values[key]) {
        values.optinMarketing = '1'
        break
      }
    }
  }, [values, submitForm])
  return null
}

const FormOptins = ({ initialValuesFromProps, edit, setEdit }: any) => {
  const t = useTrans()
  const router = useRouter()

  const optinBrands = initialValuesFromProps?.optinBrands || null
  let optins = {}
  if (optinBrands) {
    for (const brand of optinBrands) {
      optins[brand.optinName] = brand.value
    }
  }

  const [updateOptins, { loading }] = useUpdateUtenteOptinsMutation()
  const [modalShowConfirmUpdate, setModalShowConfirmUpdate] = useState(false)

  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema(t)}
      onSubmit={(values) => {
        updateOptins({
          variables: {
            optins: {
              ...values,
              optinMarketing: values?.optinMarketing === '1',
              optinProgramMarketing1: values?.optinProgramMarketing1 === '1',
              optinProgramMarketing2: values?.optinProgramMarketing2 === '1',
              optinProgramMarketing3: values?.optinProgramMarketing3 === '1',
              optinProgramMarketing4: values?.optinProgramMarketing4 === '1',
            },
          },
        })
          .then((res) => {
            setModalShowConfirmUpdate(true)
          })
          .catch((error) => console.log(error))
      }}
      initialValues={{
        ...optins,
        optinMarketing: initialValuesFromProps?.optinMarketing || 'false',
        optinProgramMarketing1: initialValuesFromProps?.optinProgramMarketing1 || 'false',
        optinProgramMarketing2: initialValuesFromProps?.optinProgramMarketing2 || 'false',
        optinProgramMarketing3: initialValuesFromProps?.optinProgramMarketing3 || 'false',
        optinProgramMarketing4: initialValuesFromProps?.optinProgramMarketing4 || 'false',
      }}
      enableReinitialize
    >
      {(props) => (
        <FormCard editMode={edit} editButton={edit ? null : { onClick: () => setEdit(true) }}>
          <FormCard.Content>
            <FormLayout>
              <div className={styles.containerOptins}>
                {optinBrands &&
                  optinBrands?.map((optinBrand) => (
                    <div className={styles.optinBrandCard} key={optinBrand.name}>
                      <div className={styles.optinNameImageContainer}>
                        <Image alt={optinBrand.name} src={optinBrand.image} id={optinBrand.name} />
                        <strong className={styles.name}>{optinBrand.name}</strong>
                      </div>
                      <FormToggle
                        id={optinBrand.optinName}
                        checked={props.values?.[optinBrand.optinName]}
                        onChange={(e) => {
                          props.setFieldValue(e.target.id, e.target.checked)
                        }}
                      />
                    </div>
                  ))}
              </div>

              <div className={styles.container}>
                <FormField
                  className={styles.formField}
                  label={t('Marketing')}
                  errorMessage={props.errors?.optinMarketing}
                  status={props.errors?.optinMarketing ? 'error' : null}
                >
                  <FormRadio
                    id="optinMarketing"
                    label={<OptinLabel type="commerciale" />}
                    value={props.values?.optinMarketing}
                    onChange={props.handleChange('optinMarketing')}
                    options={[
                      {
                        id: 'marketingAccetto',
                        label: t('Accetto'),
                        value: '1',
                      },
                      {
                        id: 'marketingNonAccetto',
                        label: t('Non accetto'),
                        value: '0',
                      },
                    ]}
                  />
                </FormField>
                <FormField
                  className={styles.formField}
                  label={t('Profilazione')}
                  errorMessage={props.errors?.optinProgramMarketing2}
                  status={props.errors?.optinProgramMarketing2 ? 'error' : null}
                >
                  <FormRadio
                    label={<OptinLabel type="profilazione" />}
                    id="optinProgramMarketing2"
                    value={props.values?.optinProgramMarketing2}
                    onChange={props.handleChange('optinProgramMarketing2')}
                    options={[
                      {
                        id: 'profilazioneAccetto',
                        label: t('Accetto'),
                        value: '1',
                      },
                      {
                        id: 'profilazioneNonAccetto',
                        label: t('Non accetto'),
                        value: '0',
                      },
                    ]}
                  />
                </FormField>
                <FormField
                  className={styles.formField}
                  label={t('Dati Particolari')}
                  errorMessage={props.errors?.optinProgramMarketing1}
                  status={props.errors?.optinProgramMarketing1 ? 'error' : null}
                >
                  <FormRadio
                    label={<OptinLabel type="datiParticolari" />}
                    id="optinProgramMarketing1"
                    value={props.values?.optinProgramMarketing1}
                    onChange={props.handleChange('optinProgramMarketing1')}
                    options={[
                      {
                        id: 'datiParticolariAccetto',
                        label: t('Accetto'),
                        value: '1',
                      },
                      {
                        id: 'datiParticolariNonAccetto',
                        label: t('Non accetto'),
                        value: '0',
                      },
                    ]}
                  />
                </FormField>
                <FormField
                  className={styles.formField}
                  label={t('Statistici')}
                  errorMessage={props.errors?.optinProgramMarketing3}
                  status={props.errors?.optinProgramMarketing3 ? 'error' : null}
                >
                  <FormRadio
                    label={<OptinLabel type="statistici" />}
                    id="optinProgramMarketing3"
                    value={props.values?.optinProgramMarketing3}
                    onChange={props.handleChange('optinProgramMarketing3')}
                    options={[
                      {
                        id: 'statisticiAccetto',
                        label: t('Accetto'),
                        value: '1',
                      },
                      {
                        id: 'statisticiNonAccetto',
                        label: t('Non accetto'),
                        value: '0',
                      },
                    ]}
                  />
                </FormField>
                <FormField
                  className={styles.formField}
                  label={t('Analisi Dati Particolari')}
                  errorMessage={props.errors?.optinProgramMarketing4}
                  status={props.errors?.optinProgramMarketing4 ? 'error' : null}
                >
                  <FormRadio
                    label={<OptinLabel type="analisiDatiParticolari" />}
                    id="optinProgramMarketing4"
                    value={props.values?.optinProgramMarketing4}
                    onChange={props.handleChange('optinProgramMarketing4')}
                    options={[
                      {
                        id: 'analisiParticolariAccetto',
                        label: t('Accetto'),
                        value: '1',
                      },
                      {
                        id: 'analisiParticolariNonAccetto',
                        label: t('Non accetto'),
                        value: '0',
                      },
                    ]}
                  />
                </FormField>
              </div>
            </FormLayout>
          </FormCard.Content>

          {edit && (
            <div className={styles.buttonsDesktop}>
              <FormCard.Buttons className={styles.buttons}>
                <Button
                  className={styles.button}
                  loading={loading}
                  type="submit"
                  disabled={!props.isValid}
                  onClick={() => props.handleSubmit()}
                  label={t('Aggiorna le preferenze')}
                  variant="primary"
                />
              </FormCard.Buttons>
            </div>
          )}

          {edit && props.isValid && (
            <div className={styles.sticky}>
              <FormCard.Buttons className={styles.buttons}>
                <Button
                  className={styles.button}
                  loading={loading}
                  type="submit"
                  disabled={!props.isValid}
                  onClick={() => props.handleSubmit()}
                  label={t('Aggiorna le preferenze')}
                  variant="primary"
                />
              </FormCard.Buttons>
            </div>
          )}

          <CheckOptins />
          <ModalFeedback
            open={modalShowConfirmUpdate}
            setOpen={setModalShowConfirmUpdate}
            onClose={router.reload}
            title={t('Le preferenze sono state modificate')}
            description={t(
              'Le modifiche alle tue preferenze sono state registrate e diventeranno effetive in 24 ore'
            )}
            ctaLabel={t('Torna al profilo')}
          />
        </FormCard>
      )}
    </Formik>
  )
}

export default FormOptins
