import React from 'react'
import useTrans from '@hooks/useTrans'
import { MenuItemType } from 'utils/types'
import { Button } from '@components/atoms' 
import styles from './ActivityCard.module.sass'


interface Props {
  item?: MenuItemType
  editMode?: boolean
  className?: string
}

const ActivityCard = (props: Props) => {

  const { 
    item = null,
    editMode = false,
    className = '',
  } = props

  const t = useTrans()

  return item ? (
    <div className={`${styles.activityCard} ${editMode ? styles['activityCard--editMode'] : ''} ${className}`}>
      {!!item.name && (
        editMode || (typeof item.name !== 'string')
        ? <div className={styles.title}>{item.name}</div>
        : <p className={styles.title}>{item.name}</p>
      )}
      {!!item.description && (
        editMode || (typeof item.description !== 'string')
        ? <div className={styles.description}>{item.description}</div>
        : <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description}} />
      )}
      {item.url && <Button label={item.ctaLabel || t('Leggi l\'articolo')} href={item.url} size="sm" variant="ghost" className={styles.cta} />}
    </div>
  ) : <></>
}

export default ActivityCard
