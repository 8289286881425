import { WContainer } from '@components/atoms'
import { GenericCard } from '@components/molecules'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MenuItemType } from 'utils/types'
import styles from './ImpegnoSection6.module.sass'


interface Props {
  item: MenuItemType
  className?: string
}

const ImpegnoSection6 = (props: Props) => {

  const { 
    item = undefined,
    className = '',
  } = props

  return item ? (
    <div className={`${styles.impegnoSection6} ${className}`}>
      <WContainer>
        <div className={styles.content}>
          {!!item.name && <p className={styles.title}>{item.name}</p>}
          {!!item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
        </div>
        <div className={styles.slider}>
          <Swiper
            spaceBetween={16}
            slidesPerView={1.2}
            pagination={{ clickable: true }}
            watchOverflow
            centeredSlides
            breakpoints={{
              768: {
                slidesPerView: 3,
                centeredSlides: false,
              },
            }}
          >
            {item.children?.length > 0 &&
              item.children.map((x) => (
                <SwiperSlide key={x.pk}>
                  <GenericCard item={x} variant="white" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </WContainer>
    </div>
  ) : <></>
}

export default ImpegnoSection6
