import { Formik } from 'formik'
import { useTrans } from 'hooks'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useResetPasswordMutation } from 'gql/graphql'
import {ModalFeedback} from "@components/organisms";
import {useRouter} from "next/router";
import {
  AlertMessage,
  Button,
  FormField,
  FormInputPassword,
  FormLayout,
} from 'components/atoms'
import styles from './FormResetPassword.module.sass'

const validationSchema = (t) =>
  yup.object().shape({
    password: yup
      .string()
      .nullable()
      .required(t('Questo campo non può essere vuoto'))
      .min(8, t('Minimo 8 Caratteri'))
      .matches('^(?=.*?[A-Z])', 'Questo campo deve contenere almeno una lettera maiuscola.'),
  })

const FormResetPassword = ({ token }: { token: string }) => {
  const router = useRouter()
  const t = useTrans()

  const [passwordChanged, setPasswordChanged] = useState(false)
  const [error, setError] = useState(null)
  const [resetPassword, { loading }] = useResetPasswordMutation()

  return (
    <Formik
      validateOnChange
      isInitialValid={false}
      validationSchema={validationSchema(t)}
      onSubmit={(values) =>
        resetPassword({
          variables: {
            token,
            password: values?.password,
          },
        })
          .then((res) =>
            res?.data?.resetPassword?.status
              ? setPasswordChanged(true)
              : setError(t('Il link per il cambio password non è più valido.'))
          )
          .catch((err) => setError(t('Il link per il cambio password non è più valido.')))
      }
      initialValues={{
        password: null,
      }}
    >
      {(props) => (
        <>
          <FormLayout>
              <FormField
                layout="full"
                label={t('SCEGLI UNA NUOVA PASSWORD*')}
                status={props.touched?.password ? (props.errors?.password ? 'error' : 'success') : null}
                errorMessage={props?.errors?.password}
                className={styles.label}
              >
                <FormInputPassword
                  togglePassword
                  onBlur={props.handleBlur('password')}
                  placeholder={t('Nuova password')}
                  value={props.values?.password}
                  onChange={props.handleChange('password')}
                />
              </FormField>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <AlertMessage
                label={t('Minimo 8 caratteri, una lettera maiuscola e un carattere speciale.')}
              />


          </FormLayout>
          <Button
            disabled={!props.isValid}
            variant="primary"
            label={t('Imposta password')}
            onClick={() => props.handleSubmit()}
            type="submit"
            className={styles.submit}
            loading={loading}
          />

          <ModalFeedback
            open={passwordChanged}
            setOpen={setPasswordChanged}
            onClose={event => router.push("/")}
            title={t('La password è stata modificata')}
            ctaLabel={t('Effettua il login')}
          />
        </>
      )}
    </Formik>
  )
}

export default FormResetPassword
