import React from 'react'
import useTrans from '@hooks/useTrans'
import { Button, WImage } from '@components/atoms'
import styles from './SearchResultItem.module.sass'


interface Props {
  item?: any
  size?: 'sm' | 'md'
  className?: string
}

interface ItemData {
  titolo: string
  descrizione: string
  categoria?: string
}

const SearchResultItem = (props: Props) => {

  const { 
    item = {},
    size = 'md',
    className = '',
  } = props

  const t = useTrans()

  let item_data: ItemData = {
    titolo: '',
    descrizione: '',
    categoria: ''
  }
 
  item_data.titolo = item.object?.titolo
  item_data.descrizione = item.object?.descrizione
  if(item.object?.categoria)
    item_data.categoria = item.object?.categoria?.titolo
  
  if (item.modelName === 'articolo') {
    item_data.descrizione = item.object?.abstract
  }

  return item ? (
    <div className={`${styles.searchResultItem} ${size ? styles[`searchResultItem--${size}`] : ''} ${className}`}>
      {item.modelName === 'ProdottoConsigliato' && item.object?.mainImage ? (
        <div className={`${styles.imageBox} ${styles['imageBox--prodotto']}`}>
          <WImage 
            src={item.object?.mainImage}
            alt={item_data.titolo}
            maxWidth={85}
            maxHeight={93}
            layout="intrinsic"
            objectFit='contain'
          />
        </div>
      ) : (
        item.modelName === 'articolo' && item.object?.numeroSettimana ? (
          <div className={`${styles.imageBox} ${styles['imageBox--settimana']}`}>
            {item.object.numeroSettimana}°
          </div>
        ) : (
          item.modelName === 'articolo' && item.object?.mainImage ? (
            <div className={`${styles.imageBox} ${styles['imageBox--articolo']}`}>
              <WImage 
                src={item.object?.mainImage}
                alt={item.object?.titolo}
                maxWidth={120}
                maxHeight={100}
                layout="intrinsic"
                objectFit='cover'
              />
            </div>
          ) : <></>
        )
      )}
      <div className={styles.main}>
        <div className={styles.content}>
          {/*item.modelName === 'ProdottoConsigliato' && <p className={styles.type}>{t("Prodotti")}</p>*/}
          {item_data.categoria && <p className={styles.type}>{item_data.categoria}</p>}
          {item_data.titolo && <p className={styles.title}>{item_data.titolo}</p>}
          {item_data.descrizione && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item_data.descrizione }} />}
        </div>
        <Button 
          variant="ghost" 
          size="sm"
          label={t(item.modelName === 'ProdottoConsigliato' ? 'Scopri di più' : "Leggi tutto")} 
          href={item.url}
          className={styles.cta}
        />
      </div>
    </div>
  ) : <></>
}

export default SearchResultItem
