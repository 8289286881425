import { Formik } from 'formik'
import useTrans from 'hooks/useTrans'
import React from 'react'
import * as yup from 'yup'
import { Button, FormDate, FormField, FormInput, FormLayout, FormSelect } from 'components/atoms'
import { FormCard } from 'components/molecules'
import { useUpdateUserMutation } from 'gql/graphql'
import styles from './FormEditDatiPersonali.module.sass'
import { genderProps } from '../FormRegistrazione/FormRegistrazione'

interface Props {
  initialValuesFromProps: any
  setInitialValuesFromProps: (e: any) => void
  onCancel?: () => void
}

const validationSchema = (t) =>
  yup.object().shape({
    firstName: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    lastName: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    gender: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    birthday: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

const FormEditDatiPersonali = ({
  initialValuesFromProps,
  setInitialValuesFromProps,
  onCancel,
}: Props) => {
  const [updateUtente, { loading }] = useUpdateUserMutation()
  const t = useTrans()

  return (
    <Formik
      validationSchema={validationSchema(t)}
      initialValues={{
        firstName: initialValuesFromProps?.nome || null,
        lastName: initialValuesFromProps?.cognome || null,
        gender: initialValuesFromProps?.sesso || null,
        birthday: initialValuesFromProps?.dataNascita || null,
      }}
      onSubmit={(values) => {
        if (initialValuesFromProps) {
          updateUtente({
            variables: {
              input: {
                ...values,
                fullName: `${values?.firstName} ${values?.lastName}`,
              },
            },
          }).then((res) => {
            setInitialValuesFromProps({ ...res?.data?.updateUtente?.utente })
            onCancel()
          })
        }
      }}
    >
      {(props) => (
        <FormCard title={t('Modifica i tuoi dati account')} editMode>
          <FormCard.Content>
            <FormLayout>
              <FormField
                className={styles.label}
                label={t('Nome')}
                status={
                  props.touched?.firstName ? (props.errors?.firstName ? 'error' : 'success') : null
                }
                errorMessage={props?.errors?.firstName}
              >
                <FormInput
                  onBlur={props.handleBlur('firstName')}
                  placeholder={t('Nome')}
                  value={props.values?.firstName}
                  onChange={props.handleChange('firstName')}
                />
              </FormField>
              <FormField
                className={styles.label}
                label={t('Cognome')}
                status={
                  props.touched?.lastName ? (props.errors?.lastName ? 'error' : 'success') : null
                }
                errorMessage={props?.errors?.lastName}
              >
                <FormInput
                  onBlur={props.handleBlur('lastName')}
                  placeholder={t('Cognome')}
                  value={props.values?.lastName}
                  onChange={props.handleChange('lastName')}
                />
              </FormField>

              <FormField
                label={t('Sesso*')}
                errorMessage={props.errors.gender}
                status={props.errors.gender ? 'error' : null}
              >
                <FormSelect
                  isSearchable
                  {...genderProps}
                  onBlur={props.handleBlur('gender')}
                  placeholder={t("Scegli un'opzione")}
                  value={props.values.gender || ''}
                  onChange={(value) => props.setFieldValue('gender', value?.value)}
                />
              </FormField>

              <FormField
                label={t('Data di nascita (GG/mm/aaaa)*')}
                errorMessage={props.errors.birthday && t('Inserire una data valida')}
                status={
                  props.touched.birthday ? (props.errors.birthday ? 'error' : 'success') : null
                }
              >
                <FormDate
                  onBlur={props.handleBlur('dataNascita')}
                  status={props.errors.birthday ? 'error' : ''}
                  value={props.values.birthday}
                  onChange={(value) => props.setFieldValue('birthday', value)}
                />
              </FormField>
            </FormLayout>
          </FormCard.Content>
          <FormCard.Buttons className={styles.buttons}>
            <Button
              className={styles.buttonForm}
              label={t('Annulla')}
              onClick={onCancel}
              variant="ghost"
            />
            <Button
              className={styles.buttonForm}
              loading={loading}
              type="submit"
              disabled={!props.isValid}
              onClick={() => props.handleSubmit()}
              label={t('Salva modifiche')}
              variant="primary"
            />
          </FormCard.Buttons>
        </FormCard>
      )}
    </Formik>
  )
}

export default FormEditDatiPersonali
