import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { FormLayout, FormField, FormRadio, Modal, Button } from 'components/atoms'
import { FiglioNode, useUpdateFiglioMutation } from 'gql/graphql'
import { useRouter } from 'next/router'

interface Props {
  open: boolean
  setOpen?: (e: boolean) => void
  datiFiglio: FiglioNode
}

const validationSchema = (t) =>
  yup.object().shape({
    gender_type: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

const ModalAggiornaFiglio = ({ open, setOpen, datiFiglio }: Props) => {
  const t = useTrans()
  const router = useRouter()
  const [updateFiglio, { loading }] = useUpdateFiglioMutation()
  return (
    <Modal open={open} setOpen={setOpen}>
      <Formik
        validateOnChange
        initialValues={{
          gender_type: null,
        }}
        isInitialValid={false}
        validationSchema={validationSchema(t)}
        onSubmit={(values) => {
          updateFiglio({
            variables: {
              children: {
                id: datiFiglio?.pk,
                gender: values?.gender_type,
              },
            },
          }).then(() => router.reload())
        }}
      >
        {(props) => (
          <>
            <Modal.Content
              title={t('Ricordati di aggiornare i dati del bambino!')}
              description={t(
                'È necessario aggiornare regolarmente i dati del  bambino per visualizzare le sue curve di crescita.'
              )}
            >
              <FormLayout>
                <FormField
                  label={t('Il tuo bambino sarà')}
                  layout="full"
                  status={
                    props.touched.gender_type
                      ? props.errors.gender_type
                        ? 'error'
                        : 'success'
                      : null
                  }
                  errorMessage={props?.errors?.gender_type}
                >
                  <FormRadio
                    id="gender_type"
                    value={props.values?.gender_type}
                    onChange={props.handleChange('gender_type')}
                    options={[
                      { label: t('Maschio'), value: 'm', id: 'maschio' },
                      { label: t('Femmina'), value: 'f', id: 'femmina' },
                    ]}
                  />
                </FormField>
              </FormLayout>
            </Modal.Content>
            <Modal.Buttons>
              <Button label={t('Lo faccio dopo')} variant="ghost" onClick={() => setOpen(false)} />
              <Button
                loading={loading}
                label={t('Salva e procedi')}
                onClick={() => props.handleSubmit()}
              />
            </Modal.Buttons>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalAggiornaFiglio
