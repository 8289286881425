import React, { useState } from 'react'
import { getIcon } from 'utils/icons'
import { ElementoPaginaType } from 'utils/types'
import { Button, WImage } from '@components/atoms'
import styles from './HubCard.module.sass'
import { ArrowRight } from '@components/atoms/Icons'
import { GenericModal } from '@components/organisms'

interface Props {
  item: ElementoPaginaType
  reverse?: boolean
  className?: string
}

const HubCard = (props: Props) => {
  const { item = null, reverse = false, className = '' } = props

  const icon = item?.style ? getIcon(item.style) : null
  const modale = item.url? item.url.includes('patronato-acli') || item.url.includes('counseling') ? true : false: false
  const [open, setOpen] = useState(false)

  
  return item ? (
    <div className={`${styles.hubCard} ${reverse ? styles['hubCard--reverse'] : ''} ${className}`}>
      <div className={styles.content}>
        {!!item.titolo && <p className={styles.title}>{item.titolo}</p>}
        {!!item.descrizione && (
          <div
            className={styles.desctiption}
            dangerouslySetInnerHTML={{ __html: item.descrizione }}
          />
        )}
        {!!item.cta && !!item.url && !modale &&(
          <Button
            label={item.cta}
            variant="secondary"
            icon={<ArrowRight />}
            iconPos="right"
            href={item.url}
            newPage={item.blank}
            external={item.blank}
          />
        )}
          {!!item.cta && !!item.url && modale &&(
          <><Button
            label={item.cta}
            variant="secondary"
            icon={<ArrowRight />}
            iconPos="right"
            onClick={() => setOpen(true)}
          />
          <GenericModal open={open} setOpen={setOpen} url={item.url}/>
          </>
        )}
        
      </div>
      {icon ? (
        <div className={styles.iconBox}>{icon}</div>
      ) : (
        <div className={styles.imageBox}>
          <WImage
            src={item.mainImage}
            maxWidth={767}
            maxHeight={313}
            layout="intrinsic"
            objectFit="cover"
            quality={100}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default HubCard
