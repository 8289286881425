import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { useSelector } from 'react-redux'
import { Close, Logo, Menu, Profile } from 'components/atoms/Icons'
import { BrandButton, Button, WContainer } from 'components/atoms'
import { Nav, NavMobile, TopMenu, SearchBar, SearchResultsDropdown } from 'components/organisms'
import useUtente from '@hooks/useUtente'
import styles from './Header.module.sass'

interface Props {
  className?: string
}

const menuItems = [
  {
    title: 'Scrivici',
    href: '/contatti',
  },
  {
    title: 'Chiamaci',
    href: '/chiamaci',
  },
  {
    title: 'Concorsi e promozioni',
    href: 'https://www.concorsi.vividanone.it',
  },
]

const Header = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const [searchDesktopOpen, setSearchDesktopOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchActive, setSearchActive] = useState(false)
  const [navMobileOpen, setNavMobileOpen] = useState(false)

  const { menu } = useSelector((state) => state.menu)

  useEffect(() => {
    if (navMobileOpen) document.body.classList.add('w-noscrollnav')
    else document.body.classList.remove('w-noscrollnav')
  }, [navMobileOpen])

  useEffect(() => {
    if (searchActive) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [searchActive])

  useEffect(() => {
    if (!searchDesktopOpen) setSearchActive(false)
  }, [searchDesktopOpen])

  const { utente } = useUtente()

  return (
    <header className={`${styles.header} ${className}`}>
      <div className={styles.main}>
        <div className={styles.mainContent}>
          <div className={styles.leftContent}>
            <div className={styles.desktop}>
              <Link legacyBehavior href="/">
                <a className={styles.logoWrap}>
                  <div className={styles.logo}>
                    <Logo className={styles.logoDesktop} />
                  </div>
                </a>
              </Link>
            </div>

            <div className={styles.areaRiservataMobileButton}>
              <button
                type="button"
                className={styles.navMobileButton}
                onClick={() => setNavMobileOpen(true)}
              >
                <Menu />
              </button>
            </div>
          </div>

          <div className={styles.centerContent}>
            <div className={styles.menu}>
              {menuItems?.map((item) => (
                <Link legacyBehavior href={item.href} key={item.title}>
                  <a className={styles.menuItem}>{item.title}</a>
                </Link>
              ))}
            </div>

            <div className={styles.mobile}>
              <Link legacyBehavior href="/">
                <a className={styles.logoWrap}>
                  <div className={styles.logo}>
                    <Logo className={styles.logoMobile} width={148} height={48} />
                  </div>
                </a>
              </Link>
            </div>
          </div>

          <div className={styles.rightContent}>
            {utente ? (
              <Button
                label={t('Profilo')}
                icon={<Profile />}
                iconPos="right"
                size="sm"
                className={styles.profileButton}
                href="/dashboard/informazioni-personali"
              />
            ) : (
              <Button
                label={t('Accedi')}
                icon={<Profile />}
                iconPos="right"
                size="sm"
                className={styles.loginButton}
                href="/login"
              />
            )}
          </div>

          <div className={styles.areaRiservataMobileButton}>
            <Button
              className={styles.mobileButton}
              href={utente ? '/dashboard/informazioni-personali' : '/login'}
              variant="ghost"
              size="md"
              icon={<Profile />}
              iconPos="right"
            />
          </div>
        </div>
      </div>
      <SearchResultsDropdown query={searchQuery} open={searchActive} setOpen={setSearchActive} />
      <NavMobile
        className={`${styles.navMobile} ${navMobileOpen ? styles['navMobile--open'] : ''}`}
        onClick={() => setNavMobileOpen(false)}
      />
    </header>
  )
}

export default Header
