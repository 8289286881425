import React from 'react'
import useTrans from 'hooks/useTrans'
import { ArticoloType } from 'utils/types'
import { WImage, Label, Button } from 'components/atoms'
import styles from './ArticoloCard.module.sass'
import Link from 'next/link'

interface Props {
  articolo: ArticoloType
  size?: 'default' | 'big' | 'sm'
  preloadImage?: boolean
  variant?: 'default' | 'horizontal'
  className?: string
}

const ArticoloCard = (props: Props) => {
  const {
    articolo = undefined,
    size = 'default',
    preloadImage = false,
    className = '',
    variant = 'default',
  } = props

  const classNames = `
  ${styles.articoloCard} 
  ${variant ? styles[`articoloCard--${variant}`] : ''}
  ${size ? styles[`articoloCard--${size}`] : ''}
  ${className ? styles[`articoloCard--${className}`] : ''}`

  const t = useTrans()

  return !!articolo ? (
    <article className={classNames}>
      <Link legacyBehavior href={articolo.url}>
        <a className={styles.imageLink}>
          <div className={styles.imageBox}>
            {articolo.tipo === '2' ? (
              <div className={styles.containerNumeroSettimana}>
                <span className={styles.numeroSettimana}>{articolo.numeroSettimana}</span>
              </div>
            ) : (
              <WImage
                src={articolo.previewImage}
                maxWidth={size === 'big' ? 740 : size === 'sm' ? 272 : 357}
                maxHeight={size === 'big' ? 500 : size === 'sm' ? 190 : 240}
                layout="intrinsic"
                objectFit="cover"
                priority={preloadImage}
                loading={preloadImage ? 'eager' : 'lazy'}
                alt={articolo.titolo}
                title={articolo.titolo}
              />
            )}
            {!!articolo.temi?.length && (
              <div className={styles.tags}>
                {articolo.temi.map((tag) => (
                  <Label
                    key={tag.pk}
                    label={tag.nome}
                    color="white"
                    size={size === 'sm' ? size : 'md'}
                    responsive={size !== 'big'}
                  />
                ))}
              </div>
            )}
          </div>
        </a>
      </Link>
      <div className={styles.content}>
        {!!articolo.titolo && (
          <Link legacyBehavior href={articolo.url}>
            <a className={styles.title}>{articolo.titolo}</a>
          </Link>
        )}
        {!!articolo.abstract && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: articolo.abstract }}
          />
        )}
        {!!articolo.url && (
          <div className={styles.ctaBox}>
            <Button
              variant="secondary"
              label={t("Leggi l'articolo")}
              size="sm"
              className={styles.cta}
              href={articolo.url}
            />
          </div>
        )}
      </div>
    </article>
  ) : (
    <></>
  )
}

export default ArticoloCard
