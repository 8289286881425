import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MenuItemType } from 'utils/types'
import { WContainer, WImage } from '@components/atoms'
import styles from './ImpegnoSection1.module.sass'
import useWindowDimensions from '@hooks/useWindowDimensions'

interface Props {
  item: MenuItemType
  className?: string
}

const ImpegnoSection1 = (props: Props) => {
  const { item = undefined, className = '' } = props

  const { width } = useWindowDimensions()

  return item ? (
    <div className={`${styles.impegnoSection1} ${className}`}>
      <div className={`${styles.imageBox} ${styles['imageBox--desktop']}`}>
        <WImage
          src={item.image}
          maxWidth={1920}
          maxHeight={725}
          layout="intrinsic"
          objectFit="cover"
          objectPosition={'80% center'}
          className={`${styles.image} ${styles['image--desktop']}`}
          loading="eager"
          priority
        />
      </div>
      <div className={`${styles.imageBox} ${styles['imageBox--mobile']}`}>
        <WImage
          src={item.imageMobile || item.image}
          maxWidth={1920}
          maxHeight={725}
          layout="intrinsic"
          objectFit="cover"
          loading="eager"
          priority
        />
      </div>
      <WContainer>
        <div className={styles.content}>
          {!!item.name && (
            <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />
          )}
          {!!item.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
          {item.children?.length > 0 && (
            <div className={styles.slider}>
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                centeredSlides={true}
                pagination={{ clickable: true }}
                watchOverflow
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                    centeredSlides: false,
                  },
                }}
              >
                {item.children.map((x) => (
                  <SwiperSlide key={x.pk}>
                    <div className={styles.item}>
                      <div className={styles.itemContent}>
                        <p className={styles.itemTitle}>{x.name}</p>
                        <div
                          className={styles.itemDescription}
                          dangerouslySetInnerHTML={{ __html: x.description }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default ImpegnoSection1
