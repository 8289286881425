import React from 'react'
import { BreadcrumbType } from 'utils/types'
import { Breadcrumbs, WContainer, WImage } from '@components/atoms'
import styles from './SettimanaHead.module.sass'


interface Props {
  breadcrumbs?: BreadcrumbType[]
  numeroSettimana?: string
  title?: string
  description?: string
  children?: React.ReactNode
  className?: string
}

const SettimanaHead = (props: Props) => {

  const {
    breadcrumbs = [],
    numeroSettimana = '',
    title = '',
    description = '',
    children = undefined,
    className = '',
  } = props


  return (
    <div className={`${styles.settimanaHead} ${className}`}>
      {!!numeroSettimana && (
        <div className={styles.settimana}>
          <span className={styles.settimanaContent}>{numeroSettimana}°</span>
        </div>
      )}
      <WContainer>
        {breadcrumbs?.length > 0 && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        <div className={styles.content}>
          {!!title && <h1 className={styles.title}>{title}</h1>}
          {!!description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
          {children}
        </div>
      </WContainer>
    </div>
  )
}

export default SettimanaHead
