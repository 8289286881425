import React from 'react'
import { BoxCard } from 'components/atoms'
import {
  Chiamaci,
  ConcorsiEPromozioni,
  IscrivitiAllaNewsletter,
  Scrivici,
} from 'components/atoms/Icons'
import styles from './HomepageBoxCard.module.sass'
import GestisciPreferenze from '@components/atoms/Icons/GestisciPreferenze'

interface Props {
  type:
    | 'scrivici'
    | 'chiamaci'
    | 'iscrivitiAllaNewsletter'
    | 'concorsiEPromozioni'
    | 'gestisciPreferenze'
  minimal?: boolean
  classNameIcon?: string
  classNameInfo?: string
  classNameTitle?: string
}

const boxes = {
  scrivici: {
    icon: <Scrivici />,
    title: 'Scrivici',
    subTitle:
      'Puoi utilizzare le stesse credenziali che utilizzi per accedere ai brand del gruppo Danone.',
    ctaText: 'Compila il form',
    ctaLink: '/contatti',
  },
  chiamaci: {
    icon: <Chiamaci />,
    title: 'Chiamaci',
    subTitle:
      'Per ricevere informazioni sui nostri prodotti o assistenza sugli acquisti online, contattaci ai seguenti numeri di telefono dedicati',
    ctaText: 'Visualizza contatti telefonici',
    ctaLink: '/chiamaci',
  },
  iscrivitiAllaNewsletter: {
    icon: <IscrivitiAllaNewsletter />,
    title: 'Iscriviti alla newsletter',
    subTitle:
      'Crea il tuo account, personalizza le tue preferenze e scegli i brand da cui vuoi ricevere le nostre esclusive comunicazioni.',
    ctaText: 'Scegli tue preferenze',
    ctaLink: '/newsletter',
  },
  concorsiEPromozioni: {
    icon: <ConcorsiEPromozioni />,
    title: 'Concorsi e promozioni',
    subTitle: 'Qui trovi la lista di tutti i concorsi e le promozioni attive.',
    ctaText: 'Visualizza tutta la lista',
    ctaLink: 'https://www.concorsi.vividanone.it',
  },
  gestisciPreferenze: {
    icon: <GestisciPreferenze />,
    title: 'Gestisci le tue preferenze',
    subTitle:
      'Accedi con le credenziali che utilizzi per i brand del gruppo Danone. Gestisci le tue preferenze per ricevere comunicazioni o modifica i tuoi consensi Privacy.',
    ctaText: 'Gestisci preferenze',
    ctaLink: '/dashboard/preferenze',
    half: true,
  },
}

const HomepageBoxCard = (props: Props) => {
  const {
    type = '',
    minimal = false,
    classNameIcon = null,
    classNameInfo = null,
    classNameTitle = null,
  } = props

  const data = boxes[type]

  if (data) {
    return (
      <BoxCard
        data={data}
        minimal={minimal}
        classNameIcon={classNameIcon}
        classNameInfo={classNameInfo}
        classNameTitle={classNameTitle}
      />
    )
  }
  return null
}

export default HomepageBoxCard
