import { WContainer, WImage } from '@components/atoms'
import { LogoNoBordo } from '@components/atoms/Icons'
import useWindowDimensions from '@hooks/useWindowDimensions'
import React from 'react'
import { MenuItemType } from 'utils/types'
import styles from './ImpegnoSection2.module.sass'


interface Props {
  item: MenuItemType,
  className?: string
}

const ImpegnoSection2 = (props: Props) => {

  const { 
    item = undefined,
    className = '',
  } = props

  const { width } = useWindowDimensions()

  return item ? (
    <div className={`${styles.impegnoSection2} ${className}`}>
      <WContainer>
        <div className={styles.layout}>          
          <div className={styles.content}>
            {!!item.name && (
              <div className={styles.head}>
                <div className={styles.icon}>
                  <LogoNoBordo />
                </div>
                <p className={styles.title}>
                  {item.name}
                </p>
              </div>
            )}
            {item.children?.length && (
              <div className={styles.list}>
                {item.children.map(x => (
                  <div className={styles.item} key={x.pk}>
                    {!!x.name && <p className={styles.itemTitle}>{x.name}</p>}
                    {!!x.description && <div className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: x.description }} />}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={styles.imageBox}>
            <WImage 
              src={item.imageMobile && width < 768 ? item.imageMobile : item.image}
              maxWidth={767}
              maxHeight={558}
              layout="intrinsic"
              objectFit="cover"
            />
          </div>
        </div>
      </WContainer>
    </div>
  ) : <></>
}

export default ImpegnoSection2
