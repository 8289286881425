import React, { useState, useCallback } from 'react'
import useTrans from 'hooks/useTrans'
import { isBorn, getAge, getWillBeBorn, formatDate } from 'utils/safe'
import {
  CiuccioF,
  CiuccioM,
  Domanda,
  Hourglass,
  ArrowRight,
  Bin,
  Edit,
} from 'components/atoms/Icons'
import { Button, FormToggle } from 'components/atoms'
import { DeleteModal } from 'components/molecules'
import {Image} from "react-bootstrap";
import styles from './OptinCard.module.sass'

interface Props {
  name: string
  image: string
  value: boolean
  handleChange: () => void
  optins: object
}

const OptinCard = (props: Props) => {
  const {
    name = "",
    image = "",
    value = false,
    handleChange = () => {},
    optins = {}
  } = props

  return (
    <div className={styles.optinBrandCard}>
      <div>
        <Image
          alt={name}
          src={image}
          id={name}
        />
        <strong className={styles.name}>{name}</strong>
      </div>
      <FormToggle id={name} checked={value} onChange={handleChange}/>
    </div>
  )
}

export default OptinCard
