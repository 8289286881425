import { gql } from '@apollo/client'

export const CATEGORIE = gql`
  query Categorie {
    categorie {
      edges {
        node {
          slugTree
        }
      }
    }
  }
`

export const CATEGORIA = gql`
  query Categoria($slugTree: String!) {
    categoria(slugTree: $slugTree) {
      pk
      titolo
      descrizione
      mainImage
      tipo
      model
      articoli {
        pk
        titolo
        abstract
        url
        previewImage
        numeroSettimana
        temi {
          pk
          nome
        }
      }
      next {
        titolo
        url
      }
      prev {
        titolo
        url
      }
      paginaHub {
        titolo
        mainImage
      }
      linkBox {
        titolo
        descrizione
        cta
        link
        blank
      }
      pagina {
        seo {
          title
          description
          image
          noindex
        }
        breadcrumbs {
          label
          url
        }
      }
    }
  }
`

export const ARTICOLO = gql`
  query Articolo($slug: String!) {
    articolo(slug: $slug) {
      pk
      titolo
      slug
      url
      descrizione
      mainImage
      consigliatiVisibili
      tipo
      model
      ordinamento
      numeroSettimana
      autore {
        label
        titolo
      }
      temi {
        pk
        nome
      }
      categoria {
        slugTree
      }
      pagina {
        seo {
          title
          description
          image
          noindex
        }
        breadcrumbs {
          label
          url
        }
      }
    }
  }
`

export const ARTICOLI = gql`
  query Articoli($categorie_Id_In: String) {
    articoli(categorie_Id_In: $categorie_Id_In) {
      edges {
        node {
          titolo
          url
          previewImage
        }
      }
    }
  }
`

export const PAGINA = gql`
  query Pagina($chiave: String, $path: String) {
    pagina(chiave: $chiave, path: $path) {
      pk
      model
      titolo
      descrizione
      mainImage
      previewImage
      template
      breadcrumbs {
        label
        url
      }
      seo {
        title
        description
        image
        noindex
      }
      chiave
    }
  }
`

export const FAQS = gql`
  query AllFaqs {
    categorieFaq {
      pk
      slug
      titolo
      descrizione
      ordine
      faqs {
        pk
        slug
        domanda
        risposta
        ordine
      }
    }
  }
`

export const PRODOTTO_CONSIGLIATO = gql`
  query ProdottoConsigliato($pk: ID!) {
    prodottoConsigliato(pk: $pk) {
      pk
      titolo
      descrizione
      mainImage
      cta
      link
    }
  }
`

export const EVENTO = gql`
  query Evento($slug: String!) {
    evento(slug: $slug) {
      pk
      titolo
      descrizione
      noteAggiuntive
      mainImage
      pagina {
        seo {
          title
          description
          image
          noindex
        }
        breadcrumbs {
          label
          url
        }
      }
    }
  }
`

export const CONTESTS = gql`
    query Contests {
      contests {
        title
        description
        ctaLabel
        externalLink
        fileUrl
        image
        fromDate
        toDate
      }
    }
`

export const SEARCH = gql`
  query Search($query: String!, $first: Int, $last: Int, $after: String, $before: String) {
    search(query: $query, first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      count
      suggestion(query: $query)
      edges {
        node {
          object {
            ... on ArticoloNode {
              pk
              titolo
              descrizione
              abstract
              url
              mainImage
              numeroSettimana
              categoria {
                titolo
              }
            }
            ... on CategoriaNode {
              pk
              titolo
              descrizione
              url
              mainImage
            }
            ... on PaginaNode {
              pk
              titolo
              descrizione
              url
              mainImage
            }

          }
          modelName
          score
          url
        }
      }
    }
  }
`

export const AUTOCOMPLETE = gql`
  query Autocomplete($query: String!) {
    autocomplete(query: $query) {
      object {
        ... on ArticoloNode {
          pk
          titolo
          url
        }
        ... on CategoriaNode {
          pk
          titolo
          url
        }
        ... on PaginaNode {
          pk
          titolo
          url
        }

      }
    }
  }
`

export const PAGINA_HUB = gql`
  query PaginaHub($chiave: String!) {
    pagina(chiave: $chiave) {
      pk
      titolo
      descrizione
      mainImage
      breadcrumbs {
        label
        url
      }
      seo {
        title
        description
        image
        noindex
      }
      elementi {
        titolo
        descrizione
        mainImage
        url
        cta
        blank
      }
      chiave
    }
  }
`

export const AUTORI = gql`
  query Autori {
    autori {
      titolo
      abstract
      previewImage
    }
  }
`

export const CATEGORIA_PRODOTTO = gql`
  query CategoriaProdotto($slug: String!) {
    categoriaProdotto(slug: $slug) {
      template
      titolo
      slug
      descrizione
      mainImage
      video
      labelProdotti
      children {
        pk
        titolo
        descrizione
        prodotti {
          pk
          titolo
          descrizione
          mainImage
          cta
          blank
          link
          informazioniAggiuntive {
            pk
            descrizione
            image
          }
        }
      }
      siblings {
        pk
        titolo
        previewImage
        url
      }
    }
  }
`
