import Case1 from './Case1'
import Case2 from './Case2'
import Case3 from './Case3'
import DatiPersonali from './DatiPersonali'

export {
    Case1,
    Case2,
    Case3,
    DatiPersonali,
}
